import { useState, useCallback } from "react";
import {
  useCopyDriveFileMutation,
  useCopyDriveFolderMutation,
  useMoveDriveFileMutation,
  useMoveDriveFolderMutation,
} from "../redux/juriiDriveApis";
import { useDispatch } from "react-redux";
import { hideModal, showModal } from "../redux/ConditionalModelReducer";

function useFileFolderActions() {
  const dispatch = useDispatch();

  const [copyFile, { isLoading: isLoadingCopyFile }] = useCopyDriveFileMutation();
  const [copyFolder, { isLoading: isLoadingCopyFolder }] = useCopyDriveFolderMutation();
  const [moveFile, { isLoading: isLoadingMoveFile }] = useMoveDriveFileMutation();
  const [moveFolder, { isLoading: isLoadingMoveFolder }] = useMoveDriveFolderMutation();

  const [source, setSource] = useState("");
  const [destination, setDestination] = useState("");
  const [isSourceIsFile, setIsSourceIsFile] = useState(false);
  const [isDestinationIsFile, setIsDestinationIsFile] = useState(false);
  const [conditionalModalShow, setConditionalModalShow] = useState(false);

  console.log(source, "source");
  console.log(destination, "destination");

  const handleDragEnd = useCallback((result) => {
    if (
      !result?.destination?.droppableId ||
      result?.draggableId === result?.destination?.droppableId ||
      result?.destination?.droppableId?.includes(".")
    ) {
      return;
    }

    const newSource = result?.draggableId.replace("/drive/folders", "").replace(/^\//, "");
    const newDestination = result?.destination?.droppableId.replace("/drive/folders", "").replace(/^\//, "");
    const newIsSourceIsFile = result?.draggableId.includes(".");
    const newIsDestinationIsFile = result?.destination?.droppableId.includes(".");

    setSource(newSource);
    setDestination(newDestination);
    setIsSourceIsFile(newIsSourceIsFile);
    setIsDestinationIsFile(newIsDestinationIsFile);

    console.log(result?.draggableId, "result?.draggableId");
    console.log(result?.destination?.droppableId, "result?.destination?.droppableId");

    dispatch(
      showModal({
        isVisible: true,
        title: "Choose Action",
        message: "You can either copy or move the file/folder",
        okName: "Copy",
        cancelName: "Move",
        cancelCallback: () => {
          handleMove(newSource, newDestination, newIsSourceIsFile);
        },
        okCallback: () => {
          handleCopy(newSource, newDestination, newIsSourceIsFile);
        },
        isLoading: isLoadingCopyFile || isLoadingCopyFolder || isLoadingMoveFile || isLoadingMoveFolder,
      })
    );
  }, [dispatch, isLoadingCopyFile, isLoadingCopyFolder, isLoadingMoveFile, isLoadingMoveFolder]);

  const handleMove = (source, destination, isSourceIsFile) => {
    if (isSourceIsFile) {
      moveFile({ source_file: source, destination_file: destination });
    } else {
      moveFolder({ source_folder: source, destination_folder: destination });
    }
    dispatch(hideModal());
  };

  const handleCopy = (source, destination, isSourceIsFile) => {
    if (isSourceIsFile) {
      copyFile({ source_file: source, destination_file: destination });
    } else {
      copyFolder({ source_folder: source, destination_folder: destination });
    }
    dispatch(hideModal());
  };

  return {
    isLoadingCopyFile,
    isLoadingCopyFolder,
    isLoadingMoveFile,
    isLoadingMoveFolder,
    conditionalModalShow,
    handleDragEnd,
    handleMove,
    handleCopy,
    setConditionalModalShow,
  };
}

export default useFileFolderActions;
