import React from 'react'

const DriveIcons = ({ className }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" viewBox="0 0 18 15" fill="none" className={className}>
            <path d="M15.0124 13.0588L16.0677 11.1219C16.3583 10.5886 16.5036 10.3219 16.4999 10.0332C16.4963 9.74456 16.3442 9.48149 16.0402 8.95534L11.9586 1.89222C11.6366 1.33502 11.4757 1.05642 11.2073 0.90321C10.9389 0.75 10.6119 0.75 9.95795 0.75H8.04205C7.38806 0.75 7.06106 0.75 6.7927 0.90321C6.52435 1.05642 6.36335 1.33502 6.04136 1.89222L1.9598 8.95534C1.65575 9.48149 1.50373 9.74456 1.50007 10.0332C1.49641 10.3219 1.64171 10.5886 1.93232 11.1219L2.98763 13.0588C3.30384 13.6392 3.46194 13.9294 3.73491 14.0897C4.00788 14.25 4.34385 14.25 5.01579 14.25H12.9842C13.6561 14.25 13.9921 14.25 14.2651 14.0897C14.5381 13.9294 14.6962 13.6392 15.0124 13.0588Z" stroke="#112D4E" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M6.75 1.5L12 9.75" stroke="#112D4E" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M4.125 13.875L9 5.25" stroke="#112D4E" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M15.75 9.75H6.75" stroke="#112D4E" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default DriveIcons;