import React from "react";
import DZBtn from "../buttons/DZBtn";
import DZText from "../text/DZText";
const DeleteAccounts = ({
  no,
  yes,
  okTitle,
  cancelTitle,
  heading = "Are you want to sure to delete ?",
}) => {
  return (
    <div className="modal-shadow z-[10] bg-F9F7F7 flex flex-col min-w-[420px] items-center justify-center gap-[16px] p-[24px] fixed top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] rounded-[12px]">
      <DZText medium children={heading} className={"text-112D4E"} />
      <div className="flex items-center gap-[14px]">
        <DZBtn
          children={okTitle ? okTitle : "Yes"}
          className={"rounded-[10px] text-white bg-white shadow w-[100px]"}
          onClick={yes}
          solid={true}
        />
        <DZBtn
          children={cancelTitle ? cancelTitle : "Cancel"}
          onClick={no}
          className={"rounded-[10px] text-112D4E bg-white shadow w-[100px]"}
        />
      </div>
    </div>
  );
};
export default DeleteAccounts;