import { useEffect } from "react";
function useKeyPress(targetKey, handler) {
  useEffect(() => {
    const handleKeyPress = (event) => {
      event.stopPropagation();
      if (event.key === targetKey) {
        handler();
      }
    };
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [targetKey, handler]);
}
export default useKeyPress;
