import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "OperationalBarReducer",
  initialState: {
    selectedFilePath: [],
  },
  reducers: {
    setSelectedFilePath: (state, action) => {
      state.selectedFilePath = action.payload;
    },
  },
});
export const { setSelectedFilePath } = slice.actions;
export const selectSelectedFilePath = (state) =>
  state.OperationalBarReducer.selectedFilePath;

export default slice.reducer;
 