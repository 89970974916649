import React, { useEffect, useState } from "react";
import InputField from "../../components/shared/inputField/InputField";
import { useCreateUserAccountMutation } from "../../redux/storeApis";
import { useSnackBarManager } from "../../lib/customHooks/useSnackBarManager";
import { useDispatch } from "react-redux";
import useAllAccountData from "../../lib/useAllAccountData";
import { setSelectedUserAccounts } from "../../redux/UserAccountsReducer";
import PP2Service from "../../services";
import { getLocalStorage } from "../../components/localStorage";
import { Config } from "../../constants/Index";
import useGoBack from "../../lib/customHooks/useGoBack";
import useEnterKeySubmit from "../../lib/useEnterKeySubmit";
function CreateAccount() {
  const { fnShowSnackBar } = useSnackBarManager();
  const [createAccount, { isLoading }] = useCreateUserAccountMutation();
  const [showAdvanced, setShowAdvanced] = useState(false);
  const dispatch = useDispatch();
  const handleBack = useGoBack();
  const fetchUserToken = getLocalStorage(Config.userToken);
  const { handleSelectedEmail } = useAllAccountData();
  const [loader, setLoader] = useState(false);
  const [inputData, setInputData] = useState({
    name: "",
    server_type: "imap",
    server_name: "",
    port: "993",
    encryption: "tls",
    username: "",
    password: "",
  });
  const getDomainFromEmail = (email) => {
    const atIndex = email?.indexOf("@");
    if (atIndex !== -1) {
      return email?.slice(atIndex + 1);
    }
    return "";
  };
  useEffect(() => {
    setInputData((pre) => ({
      ...pre,
      server_name: getDomainFromEmail(inputData?.username),
    }));
  }, [inputData?.username]);
  useEffect(() => {
    if (!inputData?.port) {
      setInputData((pre) => ({ ...pre, port: "993" }));
    }
    if (!inputData?.server_type) {
      setInputData((pre) => ({ ...pre, server_type: "imap" }));
    }
  }, [inputData?.port, inputData?.server_type]);
  const fnOnChange = (event) => {
    const { name, value } = event.target;
    setInputData((pre) => ({ ...pre, [name]: value }));
  };
  const handleSubmit = async () => {
    setLoader(true);
    const data = {
      name: inputData?.name,
      email: inputData?.username,
      password: inputData?.password,
      server_name: inputData?.server_name,
      port: inputData?.port,
      encryption: inputData?.encryption,
      server_type: inputData?.server_type,
    };

    await createAccount(data)
      .then(async (res) => {
        if (res?.data?.success) {
          const fetchUserAccounts = await PP2Service?.fetch({
            url: "user-accounts",
            token: fetchUserToken,
          });
          const userAllAccounts = await fetchUserAccounts?.data?.accounts;
          if (userAllAccounts?.length > 0) {
            fnShowSnackBar(
              res?.data?.message || "Account created successfully"
            );
            dispatch(setSelectedUserAccounts(userAllAccounts));
            let userAccount = userAllAccounts?.[0];
            setTimeout(() => {
              handleSelectedEmail(userAccount?.id, userAccount?.email, true);
            }, 2000);
          }
        } else {
          console.log(
            res?.error?.data?.errors?.email?.[0],
            "res?.error?.error?.data?.errors?.email?.[0]"
          );
          fnShowSnackBar(
            res?.error?.data?.errors?.email?.[0] || "Authentication Failed",
            true
          );
        }
      })
      .finally(() => {
        setLoader(false);
      });
  };
  const {handleKeyDown} = useEnterKeySubmit(handleSubmit);
  return (
    <div className="flex items-center w-full h-full justify-center forms-back">
      <div className="flex flex-col items-center gap-[32px] rounded-[24px] p-[32px] bg-[#FFF] w-[800px] max-lg:w-[600px] max-sm:w-[375px] max-lg:gap-[24px] max-sm:gap-[16px] max-lg:rounded-[16px] max-lg:p-[24px] max-sm:p-[16px]">
        <div className="flex align-center gap-[18px] w-full">
          <i
            onClick={handleBack}
            className="ri-arrow-left-line text-[24px] cursor-pointer"
          ></i>
          <div className="flex flex-col gap-[4px] w-full">
            <p className="text-3F72AF text-[14px]">Let's get you started</p>
            <p className="text-112D4E">Create an Account</p>
          </div>
        </div>
        <div className="flex flex-col gap-[12px] w-full">
          <InputField
            name="name"
            value={inputData?.name}
            onChange={fnOnChange}
            placeholder={"Name"}
            className={"shadow placeholder:text-[#3F72AF]"}
            autoComplete="new-password"
            onKeyDown={handleKeyDown}
          />
          <InputField
            name="username"
            value={inputData?.username}
            onChange={fnOnChange}
            placeholder={"Email"}
            className={"shadow placeholder:text-[#3F72AF]"}
            autoComplete="new-password"
            onKeyDown={handleKeyDown}
          />
          <InputField
            value={inputData?.password}
            onChange={fnOnChange}
            placeholder={"Password"}
            type={"password"}
            className={"shadow placeholder:text-[#3F72AF]"}
            name="password"
            autoComplete="new-password"
            onKeyDown={handleKeyDown}
          />
          <div className={"w-full"}>
            <div
              onClick={() => setShowAdvanced(!showAdvanced)}
              className="flex items-center justify-end w-fit gap-[8px]   "
            >
              <i
                class={`ri-arrow-${
                  showAdvanced ? "down-s" : "up-s"
                }-line text-[24px] text-[#3F72AF] cursor-pointer `}
              ></i>
              <span className={styles.advanced}>Advanced Setting</span>
            </div>
          </div>
          {showAdvanced && (
            <div className={styles.advancedContainer}>
              <InputField
                value={inputData?.server_name}
                onChange={fnOnChange}
                name="server_name"
                placeholder={"Domain Name"}
                className={"shadow placeholder:text-[#3F72AF]"}
                onKeyDown={handleKeyDown}
              />
              <InputField
                placeholder={"*Port e.g. 443"}
                className={"shadow placeholder:text-[#3F72AF]"}
                name="port"
                onChange={fnOnChange}
                value={inputData?.port}
                onKeyDown={handleKeyDown}
              />
              <InputField
                placeholder={"Encryption SSL/TLS"}
                className={"shadow placeholder:text-[#3F72AF]"}
                name="encryption"
                onChange={fnOnChange}
                value={inputData?.encryption}
                onKeyDown={handleKeyDown}
              />
            </div>
          )}
        </div>
        <button
          className="w-full rounded-[10px] text-F9F7F7 bg-[#3F72AF] py-2"
          onClick={handleSubmit}
          disabled={isLoading}
        >
          {isLoading || loader ? "Loading..." : "Create account"}
        </button>
      </div>
    </div>
  );
}
const styles = {
  container:
    "modal-shadow z-[10] bg-F9F7F7 flex flex-col min-w-[420px] items-center justify-center gap-[12px] p-[24px] fixed top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] rounded-[12px]",
  advancedContainer: "flex flex-col gap-[12px] w-full",
  input: "w-full placeholder:text-3F72AF",
  addBtn: "text-white rounded-[10px] cursor-pointer min-w-[104px]",
  advanced: "text-3F72AF cursor-pointer decoration-[#3F72AF]",
  cancelBtn: "rounded-[10px] text-112D4E cursor-pointer bg-white shadow",
};
export default CreateAccount;
