import React from "react";
function ShowVideoPlayer({ url }) {
  return (
    <video
      src={url}
      controls
      width="100%"
      className="h-[500px]"
      onError={(error) => console.error("Error playing video:", error)}
    />
  );
}
export default ShowVideoPlayer;