import { createSlice } from "@reduxjs/toolkit";
export const slice = createSlice({
  name: "CreateFolderReducer",
  initialState: {
    createFolderSlug: null,
  },
  reducers: {
    setCreateFolderSlug: (state, action) => {
      state.createFolderSlug = action.payload;
    },
  },
});
export const { setCreateFolderSlug } = slice.actions;
export const selectCreateFolder = (state) => state.CreateFolderReducer;
export default slice.reducer;
