import React, { useState, useEffect } from "react";
import DZText from "../text/DZText";
import InputField from "../inputField/InputField";
import DZBtn from "../buttons/DZBtn";
import DZTextArea from "../inputField/DZTextArea";
import { useHandleModalManager } from "../../../lib/customHooks/useHandleModalManager";
import useEnterKeySubmit from "../../../lib/useEnterKeySubmit";
import { useCreateIdMutation, useUpdateIdMutation } from "../../../redux/passwordManagerApis";
import { useSnackBarManager } from "../../../lib/customHooks/useSnackBarManager";
import { ID_CARD_TYPES } from "../../../constants/Index";

const AddIDCardModal = ({  ok,  cancel,  title = "Add ID Card",  modal, setModal, isUpdate = false, idCardData, fnEmptyData, type : idCardType }) => {

  const { modalRef } = useHandleModalManager({ modal, setModal });
  const { fnShowSnackBar } = useSnackBarManager();

  const [inputData, setInputData] = useState({
    name: "",
    number: "",
    type: idCardType??"",
    issue_date: "",
    expiry_date: "",
    place_of_issue : "",
    country: "",
    note: "",
    file: null,
  });

  useEffect(() => {
    if (isUpdate && idCardData) {
      setInputData({ ...idCardData });
    }
  }, [isUpdate, idCardData]);

  const [createId, { isLoading: isLoadingCreate }] = useCreateIdMutation();
  const [updateId, { isLoading: isLoadingUpdate }] = useUpdateIdMutation();

  const { Driver_License, ID_Card, PassPort, Social_Security } = ID_CARD_TYPES;

  const inputFieldsData = [
    { name: "name", placeholder: "Name*", types : [ID_Card, Social_Security, Driver_License, PassPort ] },
    { name: "number", placeholder: "Number*", types : [ID_Card, Social_Security, Driver_License, PassPort] },
    { name: "issue_date", placeholder: "Issue Date*", type: "date", types : [ID_Card, Driver_License, PassPort ] },
    { name: "expiry_date", placeholder: "Expiration Date*", type: "date", types : [ID_Card, Driver_License, PassPort ] },
    { name: "country", placeholder: "Country*", types : [ID_Card, Social_Security, Driver_License, PassPort] },
    { name: "place_of_issue", placeholder: "Place of issue*", types : [PassPort] },
    { name: "file", type: "file" },
  ];

  const requiredFields = idCardType == Driver_License ? ["name", "number", "issue_date", "expiry_date", "country"] 
  : idCardType == PassPort ? ["name", "number", "issue_date", "expiry_date", "country", "place_of_issue"]
  : idCardType == Social_Security ? ["name", "number", "country"] 
  : ["name", "number", "issue_date", "expiry_date", "country"];

  const fnOnChange = (event) => {
    const { name, value, files } = event.target;
    setInputData((prev) => ({ ...prev, [name]: files ? files[0] : value }));
  };

  const handleSubmit = () => {
    isUpdate ? fnUpdateCard() : fnAddCard();
  };

  const fnAddCard = () => {
    const body = new FormData();
    requiredFields?.forEach((field) => body?.append(field, inputData[field]));
    body.append("note", inputData?.note);
    body.append("type", inputData?.type);
    if (inputData?.file) {
      body.append("file", inputData?.file);
    }

    if (requiredFields?.every((field) => inputData[field])) {
      createId(body)
        .unwrap()
        .then(({ success }) => {
          if (success) {
            setModal(false);
            fnShowSnackBar("ID Card Added!");
            setInputData({
              name: "",
              number: "",
              issue_date: "",
              expiry_date: "",
              country: "",
              note: "",
              type: "ID_Card",
              file: null,
            });
          } else {
            fnShowSnackBar("Failed to add ID card. Please try again.");
          }
        })
        .catch(() => {
          fnShowSnackBar("Something went wrong, please try again!");
        });
    } else {
      fnShowSnackBar("Please fill in all required fields.");
    }
  };

  const fnUpdateCard = async () => {

    try {

      const formData = new FormData();
      for (const [key, value] of Object.entries(inputData)) {
        formData.append(key, value);
      }
  
      const data = Object.fromEntries(formData.entries());
  
      const response = await updateId({ id: idCardData.id, data }).unwrap();
      const idCard = response.data.id;
      setModal(false);
      fnShowSnackBar("ID card updated successfully!");
      if (ok) ok(idCard);
    } catch (error) {
      fnShowSnackBar("Something went wrong, please try again!");
    }
  };

  const { handleKeyDown } = useEnterKeySubmit(handleSubmit);

  return (
    <div ref={modalRef} className={styles.container}>
      <DZText
        normal
        children={isUpdate ? "Update ID Card" : title}
        className={"text-112D4E"}
      />

      {inputFieldsData.map((data, i) => {
        const checkOnTypes = data?.types?.some((x) => x == idCardType);
        return (
          <>
            { checkOnTypes && <InputField
              key={i}
              onKeyDown={handleKeyDown}
              name={data.name}
              value={data.type != "file" ? inputData[data.name] : undefined}
              onChange={fnOnChange}
              placeholder={data.placeholder}
              type={data?.type || "text"}
              className={styles.input}
            /> }
          </>
        )
      })}

      <DZTextArea
        onKeyDown={handleKeyDown}
        name="note"
        value={inputData.note}
        onChange={fnOnChange}
        className={styles.input}
      />

      <div className="flex items-center gap-[14px]">
        <DZBtn onClick={handleSubmit} solid className={styles.addBtn}>
          {isUpdate
            ? isLoadingUpdate
              ? "Loading..."
              : "Update"
            : isLoadingCreate
            ? "Loading..."
            : "Add"}
        </DZBtn>
        <DZBtn onClick={cancel} className={styles.cancelBtn}>
          Cancel
        </DZBtn>
      </div>
    </div>
  );
};

const styles = {
  container:
    "modal-shadow z-[10] bg-F9F7F7 flex flex-col min-w-[420px] items-center justify-center gap-[16px] p-[24px] fixed top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] rounded-[12px]",
  input: "w-full placeholder:text-3F72AF",
  addBtn: "text-white rounded-[10px] cursor-pointer min-w-[104px]",
  cancelBtn: "rounded-[10px] text-112D4E cursor-pointer bg-white shadow",
};

export default AddIDCardModal;
