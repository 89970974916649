import React, { useEffect, useState } from 'react';
import PasswordManagerBar from '../passwordManagerBar/PasswordManagerBar';
import SelectDropDown from '../../components/shared/dropDowns/SelectDropDown';
import ViewDropDownItem from '../../components/views/ViewDropDownItem';
import { BankIcon, CreaditCardIcon, CrossIcon, DeleteIcon, EditIcon, UserIcon } from '../../icons/generalIcons/Icons';
import { useHandleModalManager } from '../../lib/customHooks/useHandleModalManager';
import AddIDCardModal from '../../components/shared/modals/AddIDCardModal';
// import AddSocialSecurityNumber from '../../components/shared/modals/AddSocialSecurityNumber';
// import AddDriverLicense from '../../components/shared/modals/AddDriverLicense';
// import AddPassport from '../../components/shared/modals/AddPassport';
// import AddTaxNumber from '../../components/shared/modals/AddTaxNumber';
import DZText from '../../components/shared/text/DZText';
import { useDeleteMultipleIdsMutation, useGetIdsQuery } from '../../redux/passwordManagerApis';
import CheckInput from '../../components/shared/checkInput/CheckInput';
import { Tooltip } from '@mui/material';
import DeleteModal from '../../components/shared/modals/DeleteModal';
import { useSnackBarManager } from '../../lib/customHooks/useSnackBarManager';
import { Config, ID_CARD_TYPES } from '../../constants/Index';
import { EmptyLoader, SimpleLoader } from '../../components/loader/Loaders';

const PasswordManagerIDs = () => {
  const { data: idCardsData, isLoading: isLoadingIdCards } = useGetIdsQuery();
  const [deleteMultipleIdCards, { isLoading: isLoadingDeleteIdCards }] = useDeleteMultipleIdsMutation();
  const [idModal, setIdModal] = useState(false);
  const [idCardModal, setIdCardModal] = useState({ add: null, update: null, type : null, });
  const [deleteModal, setDeleteModal] = useState(false);
  // const [socialSecurityNumber, setSocialSecurityNumber] = useState(false);
  // const [driverLicense, setDriverLicense] = useState(false);
  // const [passport, setPassport] = useState(false);
  // const [taxNumber, setTaxNumber] = useState(false);
  const [selectedCardDetail, setSelectedCardDetail] = useState(false);
  const [selectedIdCards, setSelectedIdCards] = useState([]);
  const isExistSelectedCards = selectedIdCards?.length > 0;
  const { modalRef } = useHandleModalManager({ modal: idModal, setModal: setIdModal });
  const { fnShowSnackBar } = useSnackBarManager();

  const {ID_Card, Driver_License, Social_Security, PassPort} = ID_CARD_TYPES;
  
  const dropDownItemsData = [
    { title: 'ID Card', onClick: () => { setIdModal(false); setIdCardModal({...idCardModal, add: true, type : ID_Card }) }, icon: <CreaditCardIcon iconColor='#3F72AF' /> },
    { title: 'Social Security number', onClick: () => { setIdModal(false); setIdCardModal({...idCardModal, add: true, type : Social_Security }) }, icon: <BankIcon iconColor='#3F72AF' /> },
    { title: "Driver's license", onClick: () => { setIdModal(false); setIdCardModal({...idCardModal, add: true, type : Driver_License }) }, icon: <BankIcon iconColor='#3F72AF' /> },
    { title: 'Passport', onClick: () => { setIdModal(false); setIdCardModal({...idCardModal, add: true, type : PassPort }) }, icon: <BankIcon iconColor='#3F72AF' /> },
  ];

  const isExistIdCards = idCardsData?.length > 0;

  useEffect(() => {
    console.log("setSelectedIdCards: ", selectedIdCards);
    console.log("is exist: ", isExistSelectedCards);
  }, [selectedIdCards]);

  const fnDeleteCard = () => {
    const ids = selectedIdCards?.map((idCard) => idCard?.id);

    deleteMultipleIdCards({ ids }).unwrap().then((payload) => {
      if (payload?.success) {
        setSelectedIdCards([]);
        fnShowSnackBar('Deleted selected Items successfully!');
        setDeleteModal(false);

      }
    }).catch((error) => {
      fnShowSnackBar('Something went wrong, please try again!');
    });
  };

  const fnOnClickCheckInput = (e, detail) => {
    if (selectedIdCards.some((idCard) => idCard?.id === detail?.id)) {
      setSelectedIdCards(selectedIdCards.filter((idCard) => idCard?.id !== detail?.id));
    } else {
      setSelectedIdCards([...selectedIdCards, detail]);
    }
  };

  const fnClickOnEditIcon = (card) => {
    console.log(card)
    setSelectedCardDetail(card);    
    setIdCardModal({ ...idCardModal, update: true, type : card?.type });
  };

  const fnEmptyData = () => {
    setSelectedCardDetail(false);
  };


  return (
    <div className="flex flex-wrap gap-[24px] h-full overflow-y-scroll last:pb-[56px]">
      <DZText className={'text-[#112D4E] w-full'} children={"ID's"} normal />
      {isExistSelectedCards && (
        <div className={`flex items-center justify-between w-full h-[46px]`}>
          <div className="flex items-center gap-[12px]">
            <span onClick={() => setSelectedIdCards([])} className="bg-[#112D4E] p-[6px] rounded-full cursor-pointer">
              <CrossIcon iconColor="#F9F7F7" className={"w-[20px] h-[20px] cursor-pointer"} />
            </span>
          </div>
          <div className="flex items-center gap-[20px]">
            <Tooltip arrow title="Delete">
              <span onClick={() => setDeleteModal(true)} className="bg-[#F9F7F7] p-[10px] rounded-full cursor-pointer">
                <DeleteIcon className={"w-[16px] h-[16px] cursor-pointer"} />
              </span>
            </Tooltip>
          </div>
        </div>
      )}

      <PasswordManagerBar modalRef={modalRef} title={'Add ID'} onClick={() => setIdModal(true)}>
        {idModal && (
          <SelectDropDown className={'absolute top-[206px] shadow left-[16px] min-w-[302px] z-[2] max-lg:top-[80px] max-lg:left-[84px]'}>
            {dropDownItemsData?.map((data, i) => (
              <ViewDropDownItem key={i} title={data?.title} onClick={data.onClick} icon={data.icon} />
            ))}
          </SelectDropDown>
        )}
      </PasswordManagerBar>

        { isLoadingIdCards ? <SimpleLoader/> :
          isExistIdCards ? <div className="w-full grid grid-cols-6 max-2xl:grid-cols-5 max-xl:grid-cols-4 max-lg:grid-cols-3 max-md:grid-cols-2 max-[450px]:grid-cols-1 gap-[24px]">
              { idCardsData?.map((card) => (
                <div key={card.id} className="relative group flex flex-col items-center gap-[24px] bg-[#F7F9F9] p-[12px] pt-[24px] max-w-full">
                {/* <img src={`${Config.baseFileUrl}${card?.file}`} height={"200px"} width={'400px'} /> */}
                  <span onClick={(e) => fnOnClickCheckInput(e, card)}>
                    <CheckInput active={selectedIdCards?.some((idCard) => idCard?.id === card?.id)} className={'!absolute top-[10px] left-[10px]'} />
                  </span>
                  <UserIcon />
                  <div className="flex flex-col gap-[10px] w-full">
                    <span className="truncate w-[80%] text-[#3F72AF]">{card?.name}</span>
                    <span className="truncate w-full text-[#3F72AF]">{card?.number}</span>
                  </div>
                  <div className="absolute flex z-1 flex-col items-center gap-[10px] right-[10px] bottom-[15px] opacity-0 group-hover:opacity-100 transition">
                    {/* <DeleteIcon onClick={() => { setSelectedIdCards([card]); setDeleteModal(true); }} iconColor='#3F72AF' className={'cursor-pointer'} /> */}
                    <EditIcon onClick={() => { fnClickOnEditIcon(card) }} iconColor='#3F72AF' className={'cursor-pointer'} />
                  </div>
                </div>
              ))}
          </div>
          : <EmptyLoader/> 
        }


      {deleteModal && (<DeleteModal onClickYes={() => fnDeleteCard()} onClickNo={() => setDeleteModal(false)} okTitle={isLoadingDeleteIdCards ? 'Loading' : 'Yes'} />)}

      {(idCardModal.add || idCardModal.update) && <AddIDCardModal 
        modal={idCardModal} 
        setModal={setIdCardModal}
        type={idCardModal.type} 
        cancel={() => setIdCardModal(false)} 
        ok={() => setIdCardModal(false)} 
        isUpdate={idCardModal.update} 
        idCardData={idCardModal.update && selectedCardDetail}
        fnEmptyData={fnEmptyData}
      />}

      {/* {socialSecurityNumber && <AddSocialSecurityNumber modal={socialSecurityNumber} setModal={setSocialSecurityNumber} cancel={() => setSocialSecurityNumber(false)} ok={() => setSocialSecurityNumber(false)} />}
      {driverLicense && <AddDriverLicense modal={driverLicense} setModal={setDriverLicense} cancel={() => setDriverLicense(false)} ok={() => setDriverLicense(false)} />}
      {passport && <AddPassport modal={passport} setModal={setPassport} cancel={() => setPassport(false)} ok={() => setPassport(false)} />}
      {taxNumber && <AddTaxNumber modal={taxNumber} setModal={setTaxNumber} cancel={() => setTaxNumber(false)} ok={() => setTaxNumber(false)} />} */}
    </div>
  );
};

export default PasswordManagerIDs;
