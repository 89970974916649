import { Outlet } from "react-router-dom";
import Sidebar from "../components/shared/sidebar/Sidebar";
import Navbar from "../components/shared/navbar/Navbar";
import SideBarLinks from "../constants/SideBarLinks";
function JuriiSettings() {
  return (
    <div className="flex h-full overflow-hidden">
      <Sidebar
        sideBarLinks={SideBarLinks.juriiSettingsLinksArray}
        sideBarType={"settings"}
      />
      <div className="flex flex-col max-h-full  w-full">
        <Navbar />
        <div className="flex flex-col gap-[20px] min-h-[100%] p-[16px] max-h-full">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
export default JuriiSettings;
