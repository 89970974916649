import React, { useState } from 'react'
import DZText from '../text/DZText'
import InputField from '../inputField/InputField'
import DZBtn from '../buttons/DZBtn'
import DZTextArea from '../inputField/DZTextArea'
import { useHandleModalManager } from '../../../lib/customHooks/useHandleModalManager'
import { useSnackBarManager } from '../../../lib/customHooks/useSnackBarManager'
import { useCreatePaymentMutation, useUpdatePaymentMutation } from '../../../redux/passwordManagerApis'
import useEnterKeySubmit from '../../../lib/useEnterKeySubmit'

const AddCreditCardModal = ({ cancel, title = "Add a credit/debit card", modal, setModal, isUpdate = false, cardData, fnEmptyData }) => {
  const { modalRef } = useHandleModalManager({modal, setModal});
  const { fnShowSnackBar } = useSnackBarManager();
  const [ createPayment, { isLoading : isLoadingCreatePayment } ] = useCreatePaymentMutation();
  const [ updatePayment, { isLoading : isLoadingUpdatePayment } ] = useUpdatePaymentMutation();
  const [inputData, setInputData] = useState({ name: cardData?.name??'', card_number: cardData?.card_number??'', cvc: cardData?.cvc??'', date: cardData?.date??'', note:cardData?.note??'' });
  const inputFieldsData = [
    {name: 'name', value : inputData?.name, placeholder : 'Cardholder name*', },
    {name: 'card_number', value : inputData?.card_number, placeholder : 'Card number*', },
    {name: 'cvc', value : inputData?.cvc, placeholder : 'CVC*', maxLength : 3 },
    {name: 'date', value : inputData?.date, placeholder : 'Expiration date*', type : 'date' },
  ];
  const fnOnChange = (event) => {
    const { name, value } = event.target;
    if (name == 'card_number' && value) {
      const strippedValue = value?.replace(/\s/g, '');
      const formattedValue = strippedValue?.replace(/(\d{4})/g, '$1 ')?.trim();
      setInputData(prev => ({ ...prev, [name]: formattedValue }));
    } else {
      setInputData(prev => ({ ...prev, [name]: value }));
    }
  };
  const fnAddCard =()=> {
    const body = { name : inputData?.name, card_number : inputData?.card_number, cvc: inputData?.cvc, date: inputData?.date, note: inputData?.note, type :'card'  };
    if(inputData.name && inputData.card_number && inputData.cvc && inputData.date){
      createPayment(body).unwrap().then((payload) => {
        if (payload.success) {
            setModal(false);
            fnShowSnackBar('Card Added!');
            setInputData(null);
        }
      }
      ).catch((error) => {
          fnShowSnackBar('Something went wrong, please try agian!')
      });
    } else {
      fnShowSnackBar('Please fill the required fields!');
    };
  };
  const fnUpdateCard =()=>{
    const body = { id: cardData?.id, name : inputData?.name, card_number : inputData?.card_number, cvc: inputData?.cvc, date: inputData?.date, note: inputData?.note, type :'card'  };
    if(inputData.name && inputData.card_number && inputData.cvc && inputData.date){
      updatePayment(body).unwrap().then((payload) => {
        if (payload.success) {
            setModal(false);
            fnShowSnackBar('Card Updated!');
            setInputData(null);
            if(fnEmptyData) { fnEmptyData();}
        }
      }
      ).catch((error) => {
          fnShowSnackBar('Something went wrong, please try agian!')
      });
    } else {
      fnShowSnackBar('Please fill the required fields!');
    };
  };
  const { handleKeyDown } = useEnterKeySubmit(isUpdate ? fnUpdateCard : fnAddCard);
  return (
    <div ref={modalRef} className={styles.container}>
      <DZText normal children={isUpdate ? 'Update a credit/debit card' : title} className={"text-112D4E"} />
      {inputFieldsData?.map((input, i)=> 
        <InputField key={i} onKeyDown={handleKeyDown} name={input?.name} value={input?.value} placeholder={input?.placeholder} className={styles.input} onChange={fnOnChange} maxLength={input?.maxLength} type={input?.type} />
      )}
      <DZTextArea name={'note'} onKeyDown={handleKeyDown} value={inputData?.note} onChange={fnOnChange} className={styles.input} />
      <div className="flex items-center gap-[14px]">
        <DZBtn onClick={()=> { isUpdate ? fnUpdateCard() : fnAddCard()}} solid className={styles.addBtn}>
          { isUpdate ? ( isLoadingUpdatePayment ? 'Loading...' : 'Update') : (isLoadingCreatePayment ? 'Loading...' : 'Add')}
        </DZBtn>
        <DZBtn onClick={cancel} className={styles.cancelBtn}>
          {'Cancel'}
        </DZBtn>
      </div>
    </div>
  )
}
const styles = {
  container: 'modal-shadow z-[10] bg-F9F7F7 flex flex-col min-w-[420px] items-center justify-center gap-[16px] p-[24px] fixed top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] rounded-[12px]',
  input: 'w-full placeholder:text-3F72AF',
  addBtn: 'text-white rounded-[10px] cursor-pointer min-w-[104px]',
  cancelBtn: 'rounded-[10px] text-112D4E cursor-pointer bg-white shadow'
}

export default AddCreditCardModal;