import React from 'react'
import DZBtn from '../../components/shared/buttons/DZBtn'

const PasswordManagerBar = ({ title, onClick, children, modalRef, style }) => {
  return (
    <div ref={modalRef} style={style} className='flex w-full justify-end items-end h-[0]'>

      {title && <DZBtn onClick={onClick} children={title} className={'opacity-0 rounded-[10px] bg-F9F7F7 fixed left-[16px] top-[156px] max-lg:top-[80px] max-lg:h-[42px] max-lg:rounded-full max-lg:overflow-hidden max-lg:w-[39px] w-[302px] z-10'} textStyle={'text-[#3F72AF] text-[14px]'} />}

      {children}

    </div>
  )
}

export default PasswordManagerBar;