import React from "react";
import DZText from "../text/DZText";
import { useSnackBarManager } from "../../../lib/customHooks/useSnackBarManager";

const SnackBar = () => {
  const { isShowSnackBar, fnHideSnackBar, isErrorTrue } = useSnackBarManager();

  return (
    <>
      <div
        style={{ backgroundColor: `${isErrorTrue ? "#ff3333" : ""}` }}
        className={` ${
          !isShowSnackBar ? "translate-x-[100%]" : " translate-x-0"
        } min-h-[50px] transition duration-300 fixed right-0 bottom-[70px] z-10 px-5 py-3 bg-112D4E shadow-lg rounded-lg min-w-[344px]`}
      >
        <DZText
          children={isShowSnackBar}
          style={{ color: `${isErrorTrue ? "white" : ""}` }}
          className={"text-F9F7F7 cursor-text capitalize"}
        />
        <span className="absolute h-full top-0 right-0 w-1 bg-DBE2EF">
          <div className="h-full w-full bg-112D4E"></div>
        </span>
      </div>
    </>
  );
};

export default SnackBar;
