import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import noImage from "./noFile.jpeg";
function Image({
  src,
  className = "object-cover w-[100%] h-[80px] rounded-[10px] cursor-pointer",
  alt = "",
  width = "100%",
}) {
  return (
    <LazyLoadImage
      src={src}
      effect={"blur"}
      className={`${className} `}
      alt={alt}
      onError={(e) => (e.target.src = noImage)}
      width={width}
    />
  );
}
export default Image;