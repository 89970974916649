import React from "react";
import DZBtn from "../buttons/DZBtn";
import DZText from "../text/DZText";
import { useSelector } from "react-redux";
import { selectIsLoading } from "../../../redux/LoadingReducer";

const DeleteModal = ({ onClickYes, onClickNo, okTitle, cancelTitle }) => {
  const isLoading = useSelector(selectIsLoading);
  return (
    <div className="modal-shadow z-[10] bg-F9F7F7 flex flex-col min-w-[420px] items-center justify-center gap-[16px] p-[24px] fixed top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] rounded-[12px]">
      <DZText
        medium
        children={"Are you want to sure to delete ?"}
        className={"text-112D4E"}
      />

      <div className="flex items-center gap-[14px]">
        <DZBtn
          children={isLoading? "Loading..." : okTitle ? okTitle : "yes"}
          className={"rounded-[10px] text-white bg-white shadow w-[100px]"}
          onClick={onClickYes}
          solid={true}
        />
        <DZBtn
          children={cancelTitle ? cancelTitle : "Cancel"}
          onClick={onClickNo}
          className={"rounded-[10px] text-112D4E bg-white shadow w-[100px]"}
        />
      </div>
    </div>
  );
};

export default DeleteModal;
