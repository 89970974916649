import React from "react";
import DZText from "../../shared/text/DZText";
const UploadBox = ({ close, showFiles }) => {
  console.log(showFiles, "files");
  return (
    <div className="p-[24px] inset-0 w-full h-full bg-[#112D4E30] z-[2] flex items-center justify-center   fixed">
      <div className="w-full h-full modal-shadow z-[2] bg-F9F7F7 flex gap-[24px] items-center border-dashed border-[#112D4E] border-[2px] rounded-[20px] flex-col relative">
        <div className="grid  grid-cols-6 max-lg:grid-cols-5 max-md:grid-cols-4 max-sm:grid-cols-2 rounded-xl m-[24px] px-[24px] w-full gap-[24px] max-h-[400px] overflow-y-scroll">
          {showFiles.map((file) => {
            return (
              <div className="h-[140px] w-full relative rounded-[10px] overflow-hidden">
                <img
                  src={file?.preview}
                  className="object-cover h-full w-full"
                  alt=""
                />
                {file?.loading && (
                  <div className="absolute inset-0 bg-[#00000080] flex items-center justify-center">
                    <img
                      className="rounded-full w-[48px] animate-spin"
                      src={"/circle.png"}
                    />
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <div className="flex gap-[16px] flex-col items-center">
          <video
            src="uploadVideoAnimation.mp4"
            alt=""
            autoPlay
            muted
            loop
            className="max-md:max-w-[200px]  max-w-[260px]"
          />
          <span className="max-w-[300px] text-center">
            <DZText small className={"text-112D4E"} children={"Drag & Drop"} />
            &nbsp;
            <DZText
              small
              className={"text-3F72AF"}
              children={"files anywhere"}
            />
            <DZText
              small
              className={"text-3F72AF"}
              children={" to upload them or click to"}
            />
            &nbsp;
            <DZText
              small
              className={"text-112D4E underline cursor-pointer"}
              children={"Upload"}
            />
          </span>
        </div>
        <div
          onClick={(e) => {
            close();
            e.stopPropagation();
          }}
          className="absolute bottom-[20px] right-[20px] cursor-pointer text-[16px] text-[#ffffff] rounded-[10px] py-[10px] px-[16px] bg-[#112D4E] "
        >
          Cancel
        </div>
      </div>
    </div>
  );
};
export default UploadBox;