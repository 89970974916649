import { useState } from "react";
import { Config } from "../../constants/Index";
import useGetPathUrl from "../useGetPathUrl";

export function useDownloadManager() {
  const { pathForFile } = useGetPathUrl();
  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownload = (folder) => {
    setIsDownloading(true);
    const folderName = encodeURIComponent(folder?.name);
    const fileUrl = `${Config.baseCorsProductUrl}${pathForFile}/${folderName}`;
    const updateUrl = fileUrl.replace(/%2F/g, "/");
    const fileName = folder?.name;

    fetch(updateUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => console.error("Error downloading file:", error))
      .finally(() => setIsDownloading(false));
  };

  return { handleDownload, isDownloading };
}
