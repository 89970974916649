import React, { useEffect, useRef, useState } from "react";
import ArrowDown from "../../../icons/arrowIcons/ArrowDown";
import DZText from "../text/DZText";
import useAllAccountData from "../../../lib/useAllAccountData";
import useOutsideClick from "../../../lib/useOutsideClick";
import { AddIcon } from "../../../icons/generalIcons/Icons";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../reactRoute/RouteConstants";

function NavbarEmailSwitcher() {

  const navbarEmailRef = useRef();
  const navigate = useNavigate();

  const [openEmailDrop, setOpenEmailDrop] = useState(false);
  const { accountsData, selectedEmail, handleSelectedEmail, isLoadingAllAccounts, } = useAllAccountData();

  useOutsideClick(navbarEmailRef, () => setOpenEmailDrop(false));
  return (
    <div
      onClick={() => {
        setOpenEmailDrop(!openEmailDrop);
      }}
      ref={navbarEmailRef}
      className={` ${openEmailDrop ? "animation-closeOpen" : "animation-openClose"
        } flex flex-col bg-F9F7F7 p-[12px] gap-[12px] rounded-[10px] overflow-hidden`}
    >
      <div className="flex items-center justify-between cursor-pointer">

        <div className="flex items-center gap-2">
          <DZText
            className="text-[14px] text-[#112D4E] cursor-pointer"
            children={"Accounts"}
          />
          <AddIcon onClick={()=> navigate(ROUTES.createAccount) } className={'cursor-pointer'} />
        </div>

        <ArrowDown className={"cursor-pointer"} />
      </div>
      {isLoadingAllAccounts
        ? "loading..."
        : accountsData?.map((account, index) => (
          <div
            key={index}
            onClick={() => handleSelectedEmail(account?.id, account?.email)}
            className={`flex items-center gap-[12px] p-[6px] rounded-[10px] w-full cursor-pointer ${selectedEmail == account?.id ? "bg-DBE2EF" : ""
              } `}
          >
            <img
              src="https://picsum.photos/40"
              className="rounded-full cursor-pointer"
              alt=""
            />
            <div className="flex gap-[2px] flex-col cursor-pointer">
              <DZText
                className="text-[14px] fw-500 text-[#112D4E] cursor-pointer"
                children={account?.name}
              />
              <DZText
                className="text-[12px] text-[#3F72AF] cursor-pointer"
                children={account?.email}
              />
            </div>
          </div>
        ))}
    </div>
  );
}

export default NavbarEmailSwitcher;
