import React, { useEffect, useRef, useState } from "react";
import DZText from "../text/DZText";
import { DotsIcon, EditIcon, DeleteIcon, BrokenIcon, DownloadIcon, StarIcon, RestoreIcon,} from "../../../icons/generalIcons/Icons.jsx";
import MoveModal from "../../juriDrive/moveModal/MoveModal.jsx";
import { useDispatch } from "react-redux";
import { setSourceFile } from "../../../redux/MoveReducer.js";
import { hideModal,  showModal,} from "../../../redux/ConditionalModelReducer.js";
import useDeleteFileFolder from "../../../lib/useDeleteFileFolder.js";
import useDownloadFileFolder from "../../../lib/useDownloadFileFolder.js";
import RenameModal from "../modals/RenameModal.jsx";
import useKeyPress from "../../../lib/useKeyPress.js";
import useOutsideClick from "../../../lib/useOutsideClick.js";
import useHandleDriveStar from "../../../lib/useHandleDriveStar.js";
import { setIsLoading } from "../../../redux/LoadingReducer.js";
import { Config } from "../../../constants/Index.js";
import { useDownloadManager } from "../../../lib/customHooks/useDownloadManager.js";

const DriveCardDropDown = ({ 
  setShowDropDown,
  showDropDown,
  cardType,
  pathName,
  trash,
  folder,
  xPos,
  yPos,
  isSelected,
}) => {
  const cleanedPathName = pathName.replace(/\/{2,}/g, "/");
  const clearedPathName = cleanedPathName
    .replace("/drive/folders", "")
    .replace(/^\//, "");
  const { handleDelete, handleRestoreFile, isDeleteLoading } =
    useDeleteFileFolder();
  const { downloadFile, isLoadingDownload } = useDownloadFileFolder();
  const [showMoveModal, setShowMoveModal] = useState(false);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const dropdownRef = useRef(null);
  const dropDownPopupRef = useRef(null);
  const dispatch = useDispatch();
  const { handleFavorites, isFavLoading, isAlreadyFav } = useHandleDriveStar(
    clearedPathName,
    cardType
  );
  const { handleDownload, isDownloading } = useDownloadManager();
  useEffect(() => {
    dispatch(setIsLoading(isDeleteLoading || isLoadingDownload));
  }, [isDeleteLoading, dispatch, isLoadingDownload]);
  useEffect(() => {
    dispatch(setSourceFile(cleanedPathName));
  }, [dispatch, cleanedPathName]);
  useKeyPress("Escape", () => setShowMoveModal(false));
  useOutsideClick(dropdownRef, () => setShowDropDown(false));
  useEffect(() => {
    if (dropDownPopupRef.current) {
      const dropdownHeight = dropDownPopupRef.current.offsetHeight;
      const dropdownTop = dropDownPopupRef.current.getBoundingClientRect().top;
      const viewportHeight = window.innerHeight - dropdownHeight - 60;
      dropDownPopupRef.current.style.top =
        viewportHeight - dropdownTop >= dropdownHeight ? "90%" : "-220%";
    }
  }, [showDropDown]);
  const handleShowDropDown = (e) => {
    e.stopPropagation();
    setShowDropDown((prevState) => !prevState);
  };
  const onClickDelete = () => {
    dispatch(
      showModal({
        isVisible: true,
        title: "Move to trash!",
        message: "Are you sure you want to move this in Trash?",
        okCallback: () => handleDelete(cleanedPathName),
        cancelCallback: () => {
          setShowDropDown(false);
          dispatch(hideModal());
        },
        isLoading: isDeleteLoading,
      })
    );
  };

  return (
    <>
      <span ref={dropdownRef}>
        <div className="p-[10px] cursor-pointer" onClick={handleShowDropDown}>
          <DotsIcon className="cursor-pointer" />
        </div>
        {showDropDown && (
          <div
            style={{ top: yPos, left: xPos }}
            ref={dropDownPopupRef}
            className={`${
              showDropDown
                ? "animation-DriveCardDropDownOpen"
                : "animation-DriveCardDropDownClose"
            } p-[12px] flex flex-col gap-[10px] rounded-[10px] bg-DBE2EF absolute ${
              !(yPos || xPos) && "right-0"
            } z-[1] shadow w-[160px]`}
          >
            <div
              className="flex gap-[4px] items-center cursor-pointer group"
              onClick={(e) => {
                e.stopPropagation();
                onClickDelete();
              }}
            >
              <DeleteIcon className="cursor-pointer" />
              <DZText
                children={trash ? "Delete" : "Move to Trash"}
                className="cursor-pointer text-112D4E transition group-hover:translate-x-2"
              />
            </div>
            {trash && (
              <div
                onClick={() => handleRestoreFile(folder?.id)}
                className="flex gap-[4px] items-center cursor-pointer group"
              >
                <RestoreIcon className="cursor-pointer" />
                <DZText
                  children="Restore"
                  className="cursor-pointer text-112D4E transition group-hover:translate-x-2"
                />
              </div>
            )}
            {!trash && (
              <div
                className="flex gap-[4px] items-center cursor-pointer group"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowMoveModal(true);
                }}
              >
                <BrokenIcon className="cursor-pointer" />
                <DZText
                  children="Move"
                  className="cursor-pointer text-112D4E transition group-hover:translate-x-2"
                />
              </div>
            )}
             {!trash && <div
              className="flex gap-[4px] items-center cursor-pointer group"
              onClick={() => handleDownload(folder)}
            >
              <DownloadIcon className="cursor-pointer" />
              <DZText
                children="Download"
                className="cursor-pointer text-112D4E transition group-hover:translate-x-2"
              />
            </div>}

            {/* <div
              className="flex gap-[4px] items-center cursor-pointer group"
              onClick={(e) => {
                e.stopPropagation();
                downloadFile(clearedPathName);
              }}
            >
              <DownloadIcon className="cursor-pointer" />
              <DZText
                children="Download"
                className="cursor-pointer text-112D4E transition group-hover:translate-x-2"
              />
            </div> */}

            {!trash && (
              <div
                className={`flex gap-[4px] items-center cursor-pointer group transition ${
                  isFavLoading ? "" : ""
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleFavorites();
                }}
              >
                <StarIcon
                  className={`cursor-pointer ${
                    isFavLoading ? "vibrate-anime" : ""
                  }`}
                  iconColor="#112D4E"
                  bgColor={isAlreadyFav ? "#112D4E" : ""}
                />
                <DZText
                  children="Favorites"
                  className="cursor-pointer text-112D4E transition group-hover:translate-x-2"
                />
              </div>
            )}
            {/* {!trash && (
              <div className="flex gap-[4px] items-center cursor-pointer group">
                <BrokenIcon className="cursor-pointer" />
                <DZText
                  children="Share"
                  className="cursor-pointer text-112D4E transition group-hover:translate-x-2"
                />
              </div>
            )} */}
            {(cardType == "file" && !trash) && (
              <div
                className="flex gap-[4px] items-center cursor-pointer group"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowRenameModal(true);
                }}
              >
                <EditIcon className="cursor-pointer" />
                <DZText
                  children="Rename"
                  className="cursor-pointer text-112D4E transition group-hover:translate-x-2"
                />
              </div>
            )}
          </div>
        )}
      </span>
      {showMoveModal && <MoveModal setShowMoveModal={setShowMoveModal} />}
      {showRenameModal && (
        <RenameModal
          cancel={() => setShowRenameModal(false)}
          pathName={cleanedPathName}
          setShowRenameModal={setShowRenameModal}
        />
      )}
    </>
  );
};
export default DriveCardDropDown;
