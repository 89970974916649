import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setSelectedFilePath } from '../redux/OperationalBarReducer';
const useSelectAllItems = (items) => {
  const dispatch = useDispatch();
console.log(items, "items");
  useEffect(() => {
        const handleKeyDown = (e) => {
      if (e.ctrlKey && e.key === 'a') {
        e.preventDefault();
        const allPaths = items.map(item => item.name);
        console.log(allPaths, "allPaths"); 
        dispatch(setSelectedFilePath(allPaths));
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [items, dispatch]);
};
export default useSelectAllItems;