import ReactRoute from "./reactRoute/ReactRoute";
import "./globals.css";
import { RouterProvider } from "react-router-dom";
import SnackBar from "./components/shared/snackBar/SnackBar";
import ConditionalModel from "./components/shared/modals/ConditionalModal"
import LoadingBar from "./components/shared/loadingBar/LoadingBar";

function App() {

  return (
    <>
      <RouterProvider router={ReactRoute} />
      <SnackBar />
      <ConditionalModel />
      <LoadingBar/>
    </>
  );
}

export default App;
