import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import DZText from "../text/DZText";
import DZBtn from "../buttons/DZBtn";
import {
  hideModal,
  selectConditionalModal,
} from "../../../redux/ConditionalModelReducer";
import useOutsideClick from "../../../lib/useOutsideClick";
import { selectIsLoading } from "../../../redux/LoadingReducer";
import useKeyPress from "../../../lib/useKeyPress";

const ConditionalModal = ({ solid }) => {
  const dispatch = useDispatch();
  const {
    isVisible,
    title,
    message,
    okCallback,
    cancelCallback,
    moveCallback,
    okName,
    cancelName,
  } = useSelector(selectConditionalModal);
  const isLoading = useSelector(selectIsLoading);
  const modalRef = useRef(null);

  useOutsideClick(modalRef, () => {
    if (isVisible) {
      dispatch(hideModal());
    }
  });

  const handleOkClick = () => {
    if (okCallback) {
      okCallback();
    }
  };

  const handleCancelClick = () => {
    if (cancelCallback) {
      cancelCallback();
    }
  };
  useKeyPress("Escape", () => {
    if (isVisible) {
      dispatch(hideModal());
    }
  });
  useKeyPress("Enter", () => {
    if (isVisible) {
      handleOkClick();
    }
  });
  return (
    <>
      {isVisible && (
        <div
          ref={modalRef}
          className="modal-shadow z-[10] bg-F9F7F7 flex flex-col min-w-[420px] items-center justify-center gap-[16px] p-[24px] fixed top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] rounded-[12px] md:scale-125 max-sm:min-w-[360px]"
        >
          <DZText normal children={title} className={"text-112D4E"} />
          {message && (
            <DZText
              small
              children={message}
              className={"!text-[14px] text-112D4E text-center max-w-[380px]"}
            />
          )}
          <div className="flex items-center gap-[14px]">
            <DZBtn
              children={isLoading ? "Loading..." : okName}
              className={" rounded-[10px] text-112D4E bg-white shadow"}
              textStyle={"cursor-pointer"}
              onClick={handleOkClick}
              solid={solid ? true : false}
            />
            <DZBtn
              children={cancelName}
              onClick={handleCancelClick}
              className={"rounded-[10px] text-112D4E bg-white shadow"}
              textStyle={"cursor-pointer"}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ConditionalModal;
