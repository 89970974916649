import { Outlet } from "react-router-dom";
import Sidebar from "../components/shared/sidebar/Sidebar";
import Navbar from "../components/shared/navbar/Navbar";
import SideBarLinks from "../constants/SideBarLinks";
import OperationBar from "../components/opreationBar/OperationBar";

function PasswordManagerAppLayout() {
    return (
        <div className="flex h-full overflow-hidden">
            <Sidebar 
                sideBarLinks={SideBarLinks.juriiPasswordManagerLinksArray}
                sideBarType={"passwordManager"}
            />
            <div className="flex flex-col max-h-full w-full">
                <Navbar />
                <div className="flex flex-col gap-[20px] p-[16px] max-h-full">
                    <OperationBar type={'passwordManager'} />
                    <Outlet />
                </div>
            </div>
        </div>
    );
}

export default PasswordManagerAppLayout;
