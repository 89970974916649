import React from "react";

function DZInput({id,  name,  type,  placeholder,  value,  onChange,  onKeyDown,  className = null,}) {
   
  // Default class string
  let defaultClass ="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6";

  // Conditionally append className if it's not null
  let finalClassName = className ? `${defaultClass} ${className}` : defaultClass;
  return (
    <>
      <input
        id={id}
        name={name}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        className={finalClassName} 
      />
    </>
  );
}

export default DZInput;
