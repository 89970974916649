import React, { useEffect, useRef, useState } from "react";
import DZText from "../text/DZText";
import InputField from "../inputField/InputField";
import DZBtn from "../buttons/DZBtn";
import useOutsideClick from "../../../lib/useOutsideClick";
import { useRenameFileMutation } from "../../../redux/juriiDriveApis";
import { useSnackBarManager } from "../../../lib/customHooks/useSnackBarManager";
import useEnterKeySubmit from "../../../lib/useEnterKeySubmit";

const RenameModal = ({ setShowRenameModal, cancel, pathName }) => {
  const [renameFile, {isLoading}] = useRenameFileMutation();
  const {fnShowSnackBar} = useSnackBarManager()
  const [renameValue, setRenameValue] = useState("");
  const reNameRef = useRef(null);
  useOutsideClick(reNameRef, () => setShowRenameModal(false));
  const handleRename = () => {
    let name = renameValue;
    if (!name.includes(".")) {
      name = `${name}.${pathName.split(".").pop()}`;
    }
    const destinationFilePath = `${pathName.split("/").slice(0, -1).join("/")}/${name}`;
    if(pathName.includes(".")){
      renameFile({source_file: pathName, destination_file: destinationFilePath})
    }else{
      fnShowSnackBar("Folder Rename Is not available")
    }
  };
  useEffect(() => {
    setRenameValue(
      pathName.split("/").pop(pathName.split("/").pop().split(".").shift())
    );
  }, [pathName]);

  const { handleKeyDown } = useEnterKeySubmit(handleRename);
  return (
    <div
      onClick={(e) => e.stopPropagation()}
      ref={reNameRef}
      className="modal-shadow z-[10] bg-F9F7F7 flex flex-col min-w-[420px] items-center justify-center gap-[16px] p-[24px] fixed top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] rounded-[12px]"
    >
      <DZText normal children={"Rename"} className={"text-112D4E"} />
      <InputField
        placeholder="Rename Your File"
        className={"w-full placeholder:text-3F72AF"}
        value={renameValue}
        onChange={(e) => setRenameValue(e.target.value)}
        onKeyDown={handleKeyDown}
      />
      <div className="flex items-center gap-[14px]">
        <DZBtn
          children={isLoading? "Loading...":"Rename"}
          onClick={handleRename}
          solid
          className={"text-white rounded-[10px] cursor-pointer"}
        />
        <DZBtn
          children={"Cancel"}
          onClick={cancel}
          className={
            " rounded-[10px] text-112D4E cursor-pointer bg-white shadow"
          }
        />
      </div>
    </div>
  );
};

export default RenameModal;
