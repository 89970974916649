import { createBrowserRouter } from "react-router-dom";
import { ROUTES } from "./RouteConstants";
import Login from "../pages/login/Login";
import Signup from "../pages/signup/Signup.jsx";
import ForgotPassword from "../pages/forget-password/forgetPassword/ForgetPassword.jsx";
import AppLayout from "./AppLayout.jsx";
import DriveAppLayout from "./DriveAppLayout.jsx";
import Error from "../components/ui/error/Error.jsx";
import ProtectedRoutes from "./ProtectedRoutes.jsx";
import Inbox from "../pages/inbox/Inbox";
import Starred from "../pages/starred/Starred";
import Sent from "../pages/sent/Sent";
import Draft from "../pages/draft/Draft";
import Settings from "../pages/settings/Settings";
import DriveHomes from "../juriDrive/home/DriveHomes.jsx";
import Folder from "../juriDrive/folder/Folder.js";
import MyFiles from "../juriDrive/myfiles/MyFiles.jsx";
import Trash from "../juriDrive/trash/Trash.jsx";
import AllAccounts from "../pages/allAccounts/AllAccounts.jsx";
import CreateAccount from "../pages/createAccount/CreateAccount.jsx";
import PasswordManagerAppLayout from "./PasswordManagerAppLayout.jsx";
import PasswordManagerLogins from "../jurriPasswordManager/passwordManagerLogins/PasswordManagerLogins.jsx";
import PasswordManagerPersonalInfo from "../jurriPasswordManager/passwordManagerPersonalInfo/PasswordManagerPersonalInfo.jsx";
import PasswordManagerPayments from "../jurriPasswordManager/passwordManagerPayments/PasswordManagerPayments.jsx";
import PasswordManagerIDs from "../jurriPasswordManager/passwordManagerIDs/PasswordManagerIDs.jsx";
import Favourites from "../juriDrive/favourite/Favourites.jsx";
import VerificationCode from "../pages/verificationCode/VerificationCode.jsx";
import HomePage from "../pages/home/HomePage.jsx";
import JuriiSettings from "./JuriiSettings.jsx";
import Accounts from "../juriiSettingPages/accoutns/Accounts.jsx";
import Products from "../juriiSettingPages/products/Products.jsx";
import EmailSettings from "../juriiSettingPages/emailSettings/EmailSettings.jsx";

const ReactRoute = createBrowserRouter([
  {
    path: `${ROUTES.home}`,
    element: <HomePage />,
  },
  {
    path: `${ROUTES.login}`,
    element: <Login />,
  },
  {
    path: `${ROUTES.signup}`,
    element: <Signup />,
  },
  {
    path: `${ROUTES.confirmCode}`,
    element: <VerificationCode />,
  },
  {
    path: `${ROUTES.forgetPassword}`,
    element: <ForgotPassword />,
  },
  {
    errorElement: <Error />,
    children: [
      {
        element: <ProtectedRoutes />,
        children: [
          {
            path: `${ROUTES.allAccounts}`,
            element: <AllAccounts />,
          },
          {
            path: `${ROUTES.createAccount}`,
            element: <CreateAccount />,
          },
        ],
      },
    ],
  },
  {
    element: <AppLayout />,
    errorElement: <Error />,
    children: [
      {
        element: <ProtectedRoutes />,
        children: [
          {
            path: `${ROUTES.inbox}`,
            element: <Inbox />,
          },
          {
            path: `${ROUTES.starred}`,
            element: <Starred />,
          },
          {
            path: `${ROUTES.sent}`,
            element: <Sent />,
          },
          {
            path: `${ROUTES.draft}`,
            element: <Draft />,
          },
          {
            path: `${ROUTES.settings}`,
            element: <Settings />,
          },
        ],
      },
    ],
  },
  {
    element: <DriveAppLayout />,
    errorElement: <Error />,
    children: [
      {
        element: <ProtectedRoutes />,
        children: [
          {
            path: `${ROUTES.juriiDrive.drive}`,
            element: <DriveHomes />,
          },
          {
            path: `${ROUTES.juriiDrive.myFiles}`,
            element: <DriveHomes />,
          },
          {
            path: `${ROUTES.juriiDrive.sharedFiles}`,
            element: <DriveHomes />,
          },
          {
            path: `${ROUTES.juriiDrive.favourites}`,
            element: <Favourites />,
          },
          {
            path: `${ROUTES.juriiDrive.trash}`,
            element: <Trash />,
          },
          {
            path: `${ROUTES.juriiDrive.drive}/folders/:folderPath*`,
            element: <Folder />,
          },
        ],
      },
    ],
  },
  {
    element: <PasswordManagerAppLayout />,
    errorElement: <Error />,
    children: [
      {
        element: <ProtectedRoutes />,
        children: [
          {
            path: `${ROUTES.juriiPasswordManager.passwordManager}`,
            element: <PasswordManagerLogins />,
          },
          {
            path: `${ROUTES.juriiPasswordManager.logins}`,
            element: <PasswordManagerLogins />,
          },
          {
            path: `${ROUTES.juriiPasswordManager.ids}`,
            element: <PasswordManagerIDs />,
          },
          {
            path: `${ROUTES.juriiPasswordManager.payments}`,
            element: <PasswordManagerPayments />,
          },
          // {
          //   path: `${ROUTES.juriiPasswordManager.personalInfo}`,
          //   element: <PasswordManagerPersonalInfo />,
          // },
        ],
      },
    ],
  },
  {
    element: <JuriiSettings />,
    children: [
      {
        path: `${ROUTES.juriiSettings.accounts}`,
        element: <Accounts />,
      },
      {
        path: `${ROUTES.juriiSettings.products}`,
        element: <Products />,
      },
      {
        path: `${ROUTES.juriiSettings.emailSettings}`,
        element: <EmailSettings />,
      },
    ],
  },
]);

export default ReactRoute;
