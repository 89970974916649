import React, { useState } from "react";
import { useGetStarDataQuery } from "../../redux/juriiDriveApis";
import useHandleDriveStar from "../../lib/useHandleDriveStar";
import DriveCard from "../../components/shared/cards/DriveCard";
import { selectIsCardSquare } from "../../redux/CardGridReducer";
import { useSelector } from "react-redux";
import { Droppable } from "react-beautiful-dnd";
import UploadData from "../../components/shared/upload-data/UploadData";
import { useDispatch } from "react-redux";
import ShowFile from "../../components/shared/modals/ShowFile";
import { BreadCrumb } from "../../components/common/BreadCrumb";
import { Config } from "../../constants/Index";
import { selectItemTypeModel } from "../../redux/DriveFilterReducer";
import { EmptyLoader, SimpleLoader } from "../../components/loader/Loaders";
import useGetPathUrl from "../../lib/useGetPathUrl";

function Favourites({ setDragEnter }) {
  const {pathForFile} = useGetPathUrl()
  const pathForFolder = `/drive/folders/${pathForFile}`;
  const { favoritesData, isFavLoading } = useHandleDriveStar();
  const favouritesFiles = favoritesData?.data?.files;
  const { isDragEnter } = useSelector((state) => state.DropZoneReducer);
  const dispatch = useDispatch();
  const favouritesFolders = favoritesData?.data?.folders;
  const isCardSquare = useSelector(selectIsCardSquare);
  const itemTypeModel = useSelector(selectItemTypeModel);

  const [src, setSrc] = useState("");
  const [showFile, setShowFile] = useState(false);
  const [fileName, setFileName] = useState();

  const isFoldersExist = favouritesFolders?.length > 0;
  const isFilesExist = favouritesFiles?.length > 0;

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(setDragEnter());
  };

  const ViewFolders =()=>{
    return (
      <>
        {(itemTypeModel == "All" || itemTypeModel == "Folders") && isFoldersExist && (
        <div className="flex flex-col gap-[14px]">
          <div className="text-112D4E font-[500] text-[22px]">
           Favourites
          </div>
          <div className="text-112D4E font-[500] text-[20px]">
           Folders
          </div>
          <div
            className={`${
              isCardSquare
                ? "grid max-xl:grid-cols-4 max-lg:grid-cols-3 max-md:grid-cols-2 max-[420px]:grid-cols-1 grid-cols-6 max-2xl:grid-cols-5 "
                : " flex flex-col "
            } gap-[24px]`}
          >
            { favouritesFolders?.map((folder, index) => (
                  <Droppable droppableId={`${folder?.name}`} key={folder?.id}>
                    {(provided) => (
                      <span
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        <DriveCard
                          cardType="folder"
                          pathName={pathForFile}
                          folder={folder}
                          index={index}
                        />
                        {provided.placeholder}
                      </span>
                    )}
                  </Droppable>
                ))}
          </div>
        </div>
        )}
      </>
    )
  };

  const ViewFiles =()=>{
    return (
      <>
        {(itemTypeModel == "All" || itemTypeModel == "Files") && isFilesExist && (
          <div className="flex flex-col gap-[14px]">
            <div className="text-112D4E font-[500] text-[20px]">Files</div>
            <div
              className={`${
                isCardSquare
                  ? "grid max-xl:grid-cols-4 max-lg:grid-cols-3 max-md:grid-cols-2 max-[420px]:grid-cols-1 grid-cols-6 max-2xl:grid-cols-5 "
                  : " flex flex-col "
              } gap-[24px]`}
            >
              { favouritesFiles?.map((file, index) => (
                    <Droppable droppableId={`${file?.name}`} key={file?.id}>
                      {(provided) => (
                        <span
                          onDoubleClick={() => {
                            setSrc(`${Config.baseProductUrl}${file?.name}`);
                            setShowFile(true);
                            setFileName(file?.name);
                          }}
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          <DriveCard
                            cardType="file"
                            folder={file}
                            pathName={pathForFile}
                            index={index}
                          />
                          {provided.placeholder}
                        </span>
                      )}
                    </Droppable>
                  ))
                }
            </div>
          </div>
        )}
      </>
    )
  };

  return (
    <div onDragEnter={handleDragEnter} className="flex flex-col w-full gap-[20px] h-[84dvh] min-h-[100vh] overflow-y-scroll">

      {(src&& showFile)&&<ShowFile showFile={showFile}  pathName={pathForFile} url={src} fileName={fileName} setShowFile={setShowFile} />}

      { isFavLoading ? <SimpleLoader/>
        : (isFilesExist || isFoldersExist) ? <>
          <ViewFolders/>
          <ViewFiles/>
        </> : 

        <>
        <div className="flex flex-col gap-[14px]">
          <div className="text-112D4E font-[500] text-[22px]">
           Favourites
          </div></div>
        <EmptyLoader/>
        </>  
      }

      {isDragEnter && <UploadData id="uploadData" />}{" "}

    </div>
  );
}

export default Favourites;
