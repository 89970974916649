import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectComposeModel,
  selectDefaultToMail,
  selectDraftData,
  selectFullComposeModel,
  selectOpenComposeModel,
  setComposeModel,
  setDefaultToMail,
  setDraftData,
  setFullComposeModel,
  setOpenComposeModel,
} from "../../redux/ComposeModelReducer";
import { useState } from "react";
import {
  useCreateDraftMutation,
  useSendEmailMutation,
} from "../../redux/storeApis";
import { useEffect } from "react";
import { useSnackBarManager } from "./useSnackBarManager";
function useComposeModel() {
  const [data, setData] = useState({ receiver: [], subject: "", body: "" });
  const [addDraft] = useCreateDraftMutation();
  const [sendMail, { isLoading : isLoadingSendMail }] = useSendEmailMutation();
  const dispatch = useDispatch();
  const composeModel = useSelector(selectComposeModel);
  const openComposeModel = useSelector(selectOpenComposeModel);
  const fullComposeModel = useSelector(selectFullComposeModel);
  const draftData = useSelector(selectDraftData);
  const defaultToMail = useSelector(selectDefaultToMail);
  const { fnShowSnackBar } = useSnackBarManager();
  useEffect(() => {
    console.log(defaultToMail, "defaultToMail");
    console.log(draftData, "draftData");
    if (draftData?.receiver || draftData?.subject || draftData?.body) {
      setData(draftData);
    } else {
      setData({
        ...data,
        receiver: defaultToMail || [],
      });
    }
  }, [draftData, defaultToMail]);
  const handleOpenComposeModel = (mail) => {
    if (mail?.length > 0) {
      handleSetMail(mail);
    } else {
      handleSetMail([]);
    }
    dispatch(setComposeModel(false));
    dispatch(setOpenComposeModel(false));
  };
  const handleSetMail = (data) => {
    dispatch(setDefaultToMail(data));
  };
  const handleCLose = () => {
    dispatch(setComposeModel(true));
    dispatch(setOpenComposeModel(false));
  };
  const handleDraftOpenComposeModel = (data) => {
    dispatch(setComposeModel(!composeModel));
    dispatch(setDraftData(data));
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleCancel = () => {
    handleDraft();
    setData({
      receiver: [],
      subject: "",
      body: "",
    });
    handleCLose();
  };
  const handleSubmit = async () => {
    await sendMail({
      subject: data?.subject,
      body: data?.body,
      to: data?.receiver,
      attachments: "",
    })
      .unwrap()
      .then((response) => {
        if (response.success) {
          setData({
            receiver: [],
            subject: "",
            body: "",
          });
          fnShowSnackBar("Email has been send successfully");
          handleCLose();
        } else {
          fnShowSnackBar("Some thing is wrong!", true);
        }
      })
      .catch((error) => {
        fnShowSnackBar(error?.error || "Some thing is wrong!", true);
        console.log(error);
      });
  };
  const handleDraft = async () => {
    const { receiver, subject, body } = data;
    const modifyData = {
      ...data,
      receiver: receiver?.[0],
    };
    if (body || subject || receiver.length > 0) {
      try {
        addDraft(modifyData)
          .unwrap()
          .then((response) => {
            console.log(response, "response");
            if (response?.success) {
              fnShowSnackBar(response?.message || "Email saved in Draft");
            } else {
              fnShowSnackBar("Some thing is wrong!", true);
            }
          });
      } catch (error) {
        fnShowSnackBar("Some thing is wrong!", true);
        console.error("An error occurred while saving:", error);
      }
    }
  };

  return {

    defaultToMail,
    isLoadingSendMail,
    composeModel,
    openComposeModel,
    fullComposeModel,
    setComposeModel,
    setFullComposeModel,
    setOpenComposeModel,
    data,

    handleSetMail,
    handleOpenComposeModel,
    handleDraftOpenComposeModel,
    handleInputChange,
    handleCancel,
    handleSubmit,
    setData,
    handleDraft,
    handleCLose,

  };
}

export default useComposeModel;
