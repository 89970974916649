import Lottie from 'react-lottie';
import SimpleLoaderAnimation from '../../animations/simple_loader.json'
import EmptyLoaderAnimation from '../../animations/empty_loader.json'
import UploadFileLoaderAnimation from '../../animations/upload_file_folder.json'

const Loader =()=> {
    return (
      <div className="absolute inset-0 flex items-center justify-center bg-slate-200/20 backdrop-blur-sm">
        <div className="loader"></div>
      </div>
    );
}

const SimpleLoader = ({ loaderStyle, containerClassName }) => {
  const mergeStyles = { height: "200px", width: "200px", ...loaderStyle }
  return (
    <div className={`flex w-full justify-center items-center h-[70dvh] ${containerClassName}`}>
        <Lottie options={{ animationData: SimpleLoaderAnimation }} style={mergeStyles} />
    </div>
  )
}
const EmptyLoader = ({ loaderStyle, containerClassName }) => {
  const mergeStyles = { height: "300px", width: "300px", ...loaderStyle, }
  return (
    <div className={`flex w-full justify-center items-center h-[70dvh] ${containerClassName}`}>
        <Lottie options={{ animationData: EmptyLoaderAnimation }} style={mergeStyles} />
    </div>
  )
}

const UploadFileFolderLoader = ({ loaderStyle, containerClassName }) => {
  const mergeStyles = { height: "300px", width: "300px", ...loaderStyle, }
  return (
    <div className={`flex w-full justify-center items-center h-[70dvh] ${containerClassName}`}>
        <Lottie options={{ animationData: UploadFileLoaderAnimation }} style={mergeStyles} />
    </div>
  )
}
  
export {Loader, SimpleLoader, EmptyLoader, UploadFileFolderLoader};
  