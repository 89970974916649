import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "UserAccountsReducer",
  initialState: {
    userAccounts: [],
  },
  reducers: {
    setSelectedUserAccounts: (state, action) => {
      state.userAccounts = action.payload;
    },
  },
});

export const { setSelectedUserAccounts } = slice.actions;

export const selectedUserAccounts = (state) => state.UserAccountsReducer.userAccounts;

export default slice.reducer;
