import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "CardGridReducer",
  initialState: {
    isCardVertical: true,
  },
  reducers: {
    setIsCardVertical: (state, action) => {
      state.isCardVertical = action.payload;
    },
  },
});
export const { setIsCardVertical } = slice.actions;

export const selectIsCardSquare = (state) =>
  state.CardGridReducer.isCardVertical;

export default slice.reducer;
