import React, { useEffect, useState } from "react";
import {
  useEmptyTrashMutation,
  useGetTrashDataQuery,
} from "../../redux/juriiDriveApis";
import DriveCard from "../../components/shared/cards/DriveCard";
import { EmptyLoader, SimpleLoader } from "../../components/loader/Loaders";
import useGetPathUrl from "../../lib/useGetPathUrl";
import DZBtn from "../../components/shared/buttons/DZBtn";
import { useDispatch } from "react-redux";
import { hideModal, showModal } from "../../redux/ConditionalModelReducer";
import { setIsLoading } from "../../redux/LoadingReducer";
function Trash() {
  const { pathForFile } = useGetPathUrl();
  const [cardGrid, setCardGrid] = useState("square");
  const dispatch = useDispatch();
  const { data: trash, isLoading: isLoadingTrash } = useGetTrashDataQuery();
  const [emptyTrashData, { isLoading: isLoadingEmptyTrash }] = useEmptyTrashMutation();
  const pathForFolder = `/drive/folders/${pathForFile}`;
  const trashFiles = trash?.data?.files;
  const trashFolders = trash?.data?.folders;
  const isFilesExist = trashFiles?.length > 0;
  const isFoldersExist = trashFolders?.length > 0;
  
  const emptyTrash = async () => {
    try {
      await emptyTrashData({ data: "" });
      console.log("Trash emptied successfully.");
      dispatch(hideModal());
    } catch (error) {
      console.error("An error occurred while emptying the trash:", error);
    }
  };

  useEffect(() => {
    dispatch(setIsLoading(isLoadingEmptyTrash));
  }, [isLoadingEmptyTrash]);

  const handleDeleteClick = () => {
    dispatch(
      showModal({
        solid: true,
        isVisible: true,
        title: "Empty Trash",
        message: "Are you sure to delete all items?",
        okCallback: () => {
          emptyTrash();
        },
        cancelCallback: () => {
          dispatch(hideModal());
        },
      })
    );
  };
  const ViewFolders = () => {
    return (
      isFoldersExist && (
        <>
          <div className="text-112D4E font-[500] text-[20px]">Folders</div>
          <div
            className={`${
              cardGrid === "square"
                ? "grid max-xl:grid-cols-4 max-lg:grid-cols-3 max-md:grid-cols-2 max-[420px]:grid-cols-1 grid-cols-6 max-2xl:grid-cols-5 "
                : " flex flex-col "
            } gap-[24px]`}
          >
            {isFoldersExist &&
              trashFolders?.map((folder, index) => (
                <DriveCard
                  cardGrid={cardGrid}
                  cardType="folder"
                  name={folder?.name?.split("/")?.pop()}
                  pathName={pathForFolder}
                  folder={folder}
                  index={index}
                  offDragDrop={true}
                  trash={true}
                  key={index}
                />
              ))}
          </div>
        </>
      )
    );
  };
  const TrashButton = () => {
    return (
      <>
        <div className="flex flex-row items-center justify-between w-full gap-[14px]">
          <div className="text-112D4E font-[500] text-[22px]">Trash</div>
          <DZBtn
            solid={true}
            children={"Empty Trash"}
            className={"!rounded-full"}
            onClick={() => handleDeleteClick()}
          />
        </div>
      </>
    );
  };

  const ViewFiles = () => {
    return (
      isFilesExist && (
        <>
          <div className="text-112D4E font-[500] text-[20px]">Files</div>
          <div
            className={`${
              cardGrid === "square"
                ? "grid max-xl:grid-cols-4 max-lg:grid-cols-3 max-md:grid-cols-2 max-[420px]:grid-cols-1 grid-cols-6 max-2xl:grid-cols-5 "
                : " flex flex-col "
            } gap-[24px]`}
          >
            {isFilesExist &&
              trashFiles?.map((folder, index) => (
                <DriveCard
                  cardGrid={cardGrid}
                  cardType="file"
                  folder={folder}
                  pathName={pathForFile}
                  name={folder?.name?.split("/")?.pop()}
                  index={index}
                  offDragDrop={true}
                  trash={true}
                />
              ))}
          </div>
        </>
      )
    );
  };

  return (
    <>
      {isLoadingTrash ? (
        <SimpleLoader />
      ) : isFilesExist || isFoldersExist ? (
        <div className="flex flex-col gap-[24px] w-full h-[100%] min-h-[100%] overflow-y-scroll">
          <TrashButton />
          <ViewFolders />
          <ViewFiles />
        </div>
      ) : (
        <>
          <div className="flex flex-row gap-[14px]">
            <div className="text-112D4E font-[500] text-[22px]">Trash</div>
          </div>
          <EmptyLoader />
        </>
      )}
    </>
  );
}

export default Trash;
