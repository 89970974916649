import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sourceFile: null,
  destinationSlug: null
};

const MoveReducer = createSlice({
  name: 'MoveReducer',
  initialState,
  reducers: {
    setSourceFile: (state, action) => {
      state.sourceFile = action.payload;
    },
    setDestinationSlug: (state, action) => {
      state.destinationSlug = action.payload;
    }
  }
});

export const { setSourceFile, setDestinationSlug } = MoveReducer.actions;
export const sourceFileSelector = (state) => state.MoveReducer.sourceFile;
export const destinationSlugSelector = (state) => state.MoveReducer.destinationSlug;
export default MoveReducer.reducer;
