import React from 'react'

const Google = () => {
    return (

        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={18}
            height={18}
            viewBox="0 0 18 18"
            fill="none"
        >
            <mask
                id="mask0_1831_294"
                style={{ maskType: "luminance" }}
                maskUnits="userSpaceOnUse"
                x={0}
                y={0}
                width={18}
                height={18}
            >
                <rect width={18} height={18} fill="white" />
            </mask>
            <g mask="url(#mask0_1831_294)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.3541 7.53113H15.75V7.5H9V10.5H13.2386C12.6203 12.2464 10.9586 13.5 9 13.5C6.51488 13.5 4.5 11.4851 4.5 9C4.5 6.51488 6.51488 4.5 9 4.5C10.1471 4.5 11.1907 4.93275 11.9854 5.63963L14.1067 3.51825C12.7673 2.26987 10.9755 1.5 9 1.5C4.85813 1.5 1.5 4.85813 1.5 9C1.5 13.1419 4.85813 16.5 9 16.5C13.1419 16.5 16.5 13.1419 16.5 9C16.5 8.49713 16.4483 8.00625 16.3541 7.53113V7.53113Z"
                    fill="#FFC107"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.36475 5.50912L4.82887 7.31625C5.49562 5.6655 7.11037 4.5 9 4.5C10.1471 4.5 11.1907 4.93275 11.9854 5.63962L14.1067 3.51825C12.7672 2.26987 10.9755 1.5 9 1.5C6.11925 1.5 3.621 3.12637 2.36475 5.50912V5.50912Z"
                    fill="#FF3D00"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.00012 16.5C10.9374 16.5 12.6976 15.7586 14.0285 14.553L11.7072 12.5888C10.9542 13.1591 10.0182 13.5 9.00012 13.5C7.04937 13.5 5.39299 12.2561 4.76899 10.5203L2.32324 12.4046C3.56449 14.8335 6.08524 16.5 9.00012 16.5V16.5Z"
                    fill="#4CAF50"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.3541 7.53113H15.75V7.5H9V10.5H13.2386C12.9416 11.3389 12.402 12.0623 11.706 12.5891C11.7064 12.5888 11.7068 12.5888 11.7071 12.5884L14.0284 14.5526C13.8641 14.7019 16.5 12.75 16.5 9C16.5 8.49713 16.4483 8.00625 16.3541 7.53113V7.53113Z"
                    fill="#1976D2"
                />
            </g>
        </svg>

    )
}

export default Google;