import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook

const Modal = ({ isOpen, onClose, title, children }) => {
  const navigate = useNavigate(); // Initialize navigate

  useEffect(() => {
    const handleEscape = (event) => {
      if (event.keyCode === 27) {
        onClose();
      }
    };

    window.addEventListener("keydown", handleEscape);

    return () => {
      window.removeEventListener("keydown", handleEscape);
    };
  }, [onClose]);

  const handleClose = () => {
    navigate("/"); // Navigate to home page when modal is closed
    onClose();
  };

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="fixed inset-0 flex items-center justify-center z-[9]">
      <div className="bg-white rounded-lg shadow-lg p-6 max-w-md z-[10]">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-bold">{title}</h2>
          <button
            type="button"
            className="text-gray-500 hover:text-gray-700"
            onClick={handleClose} // Use handleClose instead of onClose directly
          >
            <svg
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div>{children}</div>
      </div>
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 backdrop-blur-sm	"></div>
    </div>,
    document.body
  );
};

export default Modal;
