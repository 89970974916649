import React from 'react'
import DZText from '../../components/shared/text/DZText'
import DZBtn from '../../components/shared/buttons/DZBtn'
import { useUserManager } from '../../lib/customHooks/useUserManager';
import { Navigate, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../reactRoute/RouteConstants';

const HomePage = () => {

  const navigate = useNavigate();
  const { isLoggedInUser } = useUserManager();

  if (isLoggedInUser) {
    return <Navigate to={ROUTES.inbox} replace />
  };

  return (
    <div className="flex items-center justify-between gap-[32px] max-lg:flex-col min-h-[100dvh] px-[8vw] py-[64px]" style={{ background: 'url(backPat.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }}>
      <div className="flex flex-col gap-[16px] items-start max-lg:items-center">
        <DZText big className='text-[#112D4E] max-w-[500px] text-[46px] max-lg:text-center'>
          Streamline your workday with Jurri.
        </DZText>
        <DZText small className='text-[#3F72AF] max-w-[650px] text-[18px] max-lg:text-center'>
          You can ensure that you stay organized during the day by utilizing Jurri's All-In-One e-management platform which will enable you to stay more efficient and stay organized throughout the day, utilizing Jurri's All-In-One e-management platform will enable you to stay more efficient in your workday and can help you to stay organized through-out your workday.
        </DZText>
        <div className="flex items-center gap-[24px]">
          <DZBtn onClick={() => navigate(ROUTES.login)} solid className='rounded-[10px] text-[var(--F9F7F7)] hover:scale-[.85] transition mt-4'>
            Login
          </DZBtn>
          <DZBtn onClick={() => navigate(ROUTES.signup)} className='rounded-[10px] text-[var(--112D4E)] hover:scale-[1.15] transition mt-4'>
            Sign Up
          </DZBtn>
        </div>
      </div>
      <img className='max-w-[650px] max-sm:max-w-[360px]' src="hero.png" alt="" />
    </div>
  )
}

export default HomePage;