import React, { useRef, useState } from "react";
import DZText from "../text/DZText";
import Tooltip from "@mui/material/Tooltip";
import DZBtn from "../buttons/DZBtn";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DriveIcon from "../../../icons/productIcons/DriveIcons";
import PasswordIcon from "../../../icons/productIcons/PasswordIcon";
import MailIcon from "../../../icons/productIcons/MailIcon";
import MobileSideBar from "../../mobileSideBar/MobileSideBar";
import useComposeModel from "../../../lib/customHooks/useComposeModel";
import SidebarMainButton from "./SidebarMainButton";
import SidebarEmailSwitcher from "./SidebarEmailSwitcher";
import "./Sidebar.css";
import { ROUTES } from "../../../reactRoute/RouteConstants";
import { ArrowIcon, RefreshIcon } from "../../../icons/generalIcons/Icons";
import useOutsideClick from "../../../lib/useOutsideClick";
import { useRefechApisManager } from "../../../lib/customHooks/useRefechApisManager";
import { useGetDriveFoldersQuery } from "../../../redux/juriiDriveApis";
import FolderIcon from "../../../icons/cardIcons/FolderIcon";

const Sidebar = ({ sideBarType, sideBarLinks }) => {
  const { data: driveFoldersData, isLoading: isLoadingDriveFoldersData } =
    useGetDriveFoldersQuery();
  const driveFolders = driveFoldersData?.data?.folders || [];
  const createFolderRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { isLoadingEmails, fnOnRefetch } = useRefechApisManager();

  const { handleOpenComposeModel } = useComposeModel();

  const [showCreatingFolder, setShowCreatingFolder] = useState(false);

  useOutsideClick(createFolderRef, () => setShowCreatingFolder(false));

  const clickOnButton = () => {
    if (sideBarType == "drive") {
      setShowCreatingFolder(!showCreatingFolder);
    } else if (sideBarType == "email") {
      handleOpenComposeModel();
    }
    if (sideBarType == "settings") {
      navigate(ROUTES.createAccount);
    }
  };

  const goToIndex = () => {
    navigate(ROUTES.inbox);
  };

  const fnIsActivePath = (path) => location.pathname === path;

  return (
    <div ref={createFolderRef}>
      <div className="flex flex-col p-[16px] py-[10px] pb-[16px] max-lg:w-fit max-lg:min-w-fit min-w-[334px] bg-F9F7F7 h-full gap-[24px] relative max-lg:items-center max-lg:px-[12px] max-sm:hidden">
        <div className="flex items-center justify-between w-full z-[2]">
          <div className="flex items-center gap-[14px]">
            <div
              className="cursor-pointer w-[58px] h-[58px] bg-112D4E rounded-full flex items-center justify-center  max-lg:w-[48px] max-lg:h-[48px]"
              onClick={goToIndex}
            >
              {sideBarType == "email" ? (
                <MailIcon
                  className={
                    "w-[32px] h-[32px] cursor-pointer logo__icon max-lg:w-[24px] max-lg:h-[24px]"
                  }
                />
              ) : sideBarType == "drive" ? (
                <DriveIcon
                  className={
                    "w-[32px] h-[32px] cursor-pointer logo__icon max-lg:w-[24px] max-lg:h-[24px]"
                  }
                />
              ) : sideBarType == "passwordManager" ? (
                <PasswordIcon className={"w-[32px] h-[32px] logo__icon"} />
              ) : (
                ""
              )}
            </div>
            <DZText
              children={`${sideBarType == "email"
                ? "Email"
                : sideBarType == "drive"
                  ? "Drive"
                  : "Locker"
                }`}
              className={"text-112D4E font-[500] text-[22px] max-lg:hidden"}
              onClick={goToIndex}
            />
          </div>
          <SidebarEmailSwitcher />
        </div>
        {/* {sideBarType !== "settings" && ( */}
          <div className="flex items-center gap-[4px] z-[1] max-lg:hidden">
            <Tooltip
              title="Jurii Mail"
              arrow
              disableInteractive
              placement="bottom"
            >
              <Link
                to={ROUTES.inbox}
                className={` ${sideBarType == "email" && "active"
                  } product__container w-[100%] h-[38px] bg-DBE2EF cursor-pointer transition rounded-tl-[20px] p-5  rounded-bl-[20px]  shadow flex items-center justify-center `}
              >
                <MailIcon className={"drive-icon cursor-pointer"} />
              </Link>
            </Tooltip>
            <Tooltip
              title="Jurii Drive"
              arrow
              disableInteractive
              placement="bottom"
            >
              <Link
                to={"drive"}
                className={` ${sideBarType == "drive" && "active"
                  } product__container w-[100%] h-[38px] bg-DBE2EF p-5  cursor-pointer transition rounded-[1px] shadow flex items-center justify-center `}
              >
                <DriveIcon className={"drive-icon cursor-pointer"} />
              </Link>
            </Tooltip>
            <Tooltip
              title="Jurii Password Manager"
              arrow
              disableInteractive
              placement="right"
            >
              <Link
                to={"logins"}
                className={` ${sideBarType == "passwordManager" && "active"
                  } product__container w-[100%] h-[38px] bg-DBE2EF p-5  cursor-pointer transition rounded-[1px] rounded-tr-[20px] rounded-br-[20px] shadow flex items-center justify-center `}
              >
                <PasswordIcon className={"drive-icon cursor-pointer"} />
              </Link>
            </Tooltip>
          </div>
        {/* )} */}
        <div className="flex flex-col items-end relative">
          <SidebarMainButton
            sideBarType={sideBarType}
            x
            clickOnButton={clickOnButton}
            showCreatingFolder={showCreatingFolder}
            setShowCreatingFolder={setShowCreatingFolder}
          />
          {sideBarType == "email" && (
            <div
              onClick={() => fnOnRefetch()}
              className="absolute bottom-[-50px] z-2 w-fit p-[8px] bg-[#112D4E10] cursor-pointer transition hover:scale-[.70] scale-75 hover:rotate-[360deg] rounded-full max-lg:hidden"
            >
              <RefreshIcon className={"w-[20px] h-[]"} />
            </div>
          )}
        </div>
        <div className="flex flex-col max-lg:items-center gap-[10px]  max-lg:gap-[24px]">
          {sideBarLinks?.map((link) => (
            <Link to={link.path} key={link.path}>
              {link.icon(fnIsActivePath(link.path) ? "#112D4E" : "#3F72AF")}
              <DZText
                children={link.name}
                small={true}
                link={link.path}
                sideLink={true}
                sideLinkActive={fnIsActivePath(link.path)}
                className={link.className}
              />
            </Link>
          ))}
          {sideBarType == "email" && (
            <div
              onClick={() => fnOnRefetch()}
              className="w-fit p-[8px] bg-[#112D4E10] cursor-pointer transition hover:scale-[.70] scale-75 hover:rotate-[360deg] rounded-full lg:hidden"
            >
              <RefreshIcon className={"w-[20px] h-[]"} />
            </div>
          )}
        </div>
        <img
          src="images/circle.png"
          alt=""
          className="absolute bottom-0 left-[-24px] blur-[3px]"
        />
        {sideBarType === "drive" && (
          <div className="mt-[50%] flex flex-col gap-[16px] max-lg:hidden">
            <DZText normal={true} className={"text-112D4E"} children={"Folders"} />
            <div className="flex flex-col gap-[10px] max-lg:hidden">
              {driveFolders?.map((folder) => (
                <div className="flex items-center gap-[10px]" key={folder?.id}>
                  <FolderIcon className="!w-[40px]" />
                  <Link>
                    {folder?.name} {console.log(folder, "folder")}
                  </Link>
                </div>
              ))}
            </div>
          </div>
        )}

      </div>
      <MobileSideBar
        fnIsActivePath={fnIsActivePath}
        sideBarLinks={sideBarLinks}
        sideBarType={sideBarType}
        clickOnButton={clickOnButton}
        showCreatingFolder={showCreatingFolder}
        setShowCreatingFolder={setShowCreatingFolder}
      />
    </div>
  );
};

export default Sidebar;
