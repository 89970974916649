import React, { useState } from "react";
import DZInput from "../../../components/shared/inputField/DZInput";
import DZBtn from "../../../components/shared/buttons/DZBtn";
import { Config } from "../../../constants/Index";
import { useNavigate } from "react-router";
import useEnterKeySubmit from "../../../lib/useEnterKeySubmit";

const NewPassword = ({ email, verificationCode }) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setMessage("Passwords do not match.");
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(`${Config.serverUrl}api/new-password`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email,
          verification_code: verificationCode,
          password,
        }),
      });

      if (response.ok) {
        setMessage("Password updated successfully.");
        navigate("/");
      } else {
        setMessage("Failed to update password. Please try again.");
      }
    } catch (error) {
      setMessage("Something went wrong. Please try again later.");
    }
    setLoading(false);
  };
  const {handleKeyDown} = useEnterKeySubmit(handleSubmit);
  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Create New Password
        </h2>
        <form className="mt-8" onSubmit={handleSubmit}>
          <div>
            <label
              htmlFor="password"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              New Password
            </label>
            <div className="mt-2">
              <DZInput
                id="password"
                name="password"
                type="password"
                placeholder="Enter new password"
                value={password}
                onChange={handlePasswordChange}
                onKeyDown={handleKeyDown}
              />
            </div>
          </div>
          <div className="mt-6">
            <label
              htmlFor="confirmPassword"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Confirm Password
            </label>
            <div className="mt-2">
              <DZInput
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                placeholder="Confirm new password"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                onKeyDown={handleKeyDown}
              />
            </div>
          </div>
          <div className="mt-6">
            <DZBtn
              toolTipText={"Update Password"}
              textStyle={"text-F9F7F7 cursor-pointer"}
              solid={true}
              toolTipPlacement={"bottom"}
              onClick={handleSubmit}
              className={"cursor-pointer rounded-[10px] py-[12px] px-[24px] "}
              children={loading ? "Loading..." : "Update Password"}
              disabled={loading}
            />
          </div>
          {message && (
            <p className="mt-4 text-center text-red-500">{message}</p>
          )}
        </form>
      </div>
    </div>
  );
};

export default NewPassword;
