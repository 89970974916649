import React from 'react'

const PhotoIcon = ({ className }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={78}
            height={81}
            viewBox="0 0 78 81"
            fill="none"
            className={className}
        >
            <g filter="url(#filter0_d_378_1993)">
                 <g clipPath="url(#clip0_378_1993)">
                    <rect
                        x="12.0876"
                        y={10}
                        width="53.8245"
                        height="56.6875"
                        rx="14.315"
                        fill="#86BCFC"
                    />
                    <circle cx="31.2697" cy="26.3193" r="6.58491" fill="#112D4E" />
                    <path
                        d="M29.2656 48.0705L14.6713 58.8427C13.035 60.0504 12.5626 62.2921 13.5722 64.0575L15.2278 66.9522C15.9416 68.2003 17.2693 68.9704 18.7071 68.9704H65.9573C68.0372 68.9704 69.7715 67.3796 69.9506 65.3074L71.4693 47.7397C71.5758 46.5077 71.1076 45.2954 70.2006 44.4548L63.908 38.6226C61.6176 36.5612 58.9454 37.7637 57.8957 38.6226L44.6986 49.7081C43.9765 50.3147 43.0636 50.6472 42.1206 50.6472H41.1108C40.2955 50.6472 39.4996 50.3986 38.8293 49.9345L36.434 48.2762C36.2363 48.1393 36.0538 47.9807 35.8711 47.8243C33.602 45.8813 30.5508 47.1525 29.2656 48.0705Z"
                        fill="#112D4E"
                    />
                </g>
            </g>
            <defs>
                <filter
                    id="filter0_d_378_1993"
                    x="0.177542"
                    y="0.3803"
                    width="77.6447"
                    height="80.5077"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="2.29041" />
                    <feGaussianBlur stdDeviation="5.95505" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_378_1993"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_378_1993"
                        result="shape"
                    />
                </filter>
                <clipPath id="clip0_378_1993">
                    <rect
                        x="12.0876"
                        y={10}
                        width="53.8245"
                        height="56.6875"
                        rx="14.315"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </svg>

    )
}

export default PhotoIcon