// DropZoneReducer.js
import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "DropZoneReducer",
  initialState: {
    isDragEnter: false,
    isDragLeave: true,
    isDragOver: false,
    isDragDrop: false,
  },
  reducers: {
    setDragEnter: (state) => {
      state.isDragEnter = true;
      state.isDragLeave = false;
    },
    setDragLeave: (state) => {
      state.isDragLeave = true;
      state.isDragEnter = false;
    },
    setDragOver: (state) => { 
      state.isDragOver = true;
    },
    setDragDrop: (state) => {
      state.isDragDrop = true;
      state.isDragEnter = false;
    },
    resetDragState: (state) => {
      state.isDragEnter = false;
      state.isDragLeave = true;
      state.isDragOver = false;
      state.isDragDrop = false;
    },
  },
});

export const {
  setDragEnter,
  setDragLeave,
  setDragOver,
  setDragDrop,
  resetDragState,
} = slice.actions;

export default slice.reducer;
