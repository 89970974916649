import { Tooltip, Zoom } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import DZText from "../text/DZText";

import PhotoIcon from "../../../icons/cardIcons/PhotoIcon";

import {
  CrossIcon,
  DeleteIcon,
  StarIcon,
  DownloadIcon,
  MaximizeIcon,
  MinusIcon,
} from "../../../icons/generalIcons/Icons";

import useFindFileType from "../../../lib/customHooks/useFIndFileType";
import ShowPdf from "../../juriDrive/showPdf/ShowPdf";
import ShowVideoPlayer from "../../juriDrive/showVideoPlayer/ShowVideoPlayer";
import Image from "../image/Image";
import useOutsideClick from "../../../lib/useOutsideClick";
import useFullScreen from "../../../lib/useFullScreen";
import useDownloadFileFolder from "../../../lib/useDownloadFileFolder";
import { Config } from "../../../constants/Index";
import useHandleDriveStar from "../../../lib/useHandleDriveStar";
import { hideModal, showModal } from "../../../redux/ConditionalModelReducer";
import { useDispatch } from "react-redux";
import useDeleteFileFolder from "../../../lib/useDeleteFileFolder";

const ShowFile = ({ showFile, setShowFile, url, fileName, pathName }) => {
  const { handleDelete, isDeleteLoading } = useDeleteFileFolder();
  const dispatch = useDispatch();
  const { handleFavorites, isFavLoading, isAlreadyFav } = useHandleDriveStar(
    `${fileName}`,
    "file"
  );
  const onClickDelete = () => {
    dispatch(
      showModal({
        isVisible: true,
        title: "Move to trash!",
        message: "Are you sure you want to move this in Trash?",
        okCallback: () => handleDelete(cleanedPathName),
        cancelCallback: () => {
          setShowFile(false);
          dispatch(hideModal());
        },
        isLoading: isDeleteLoading,
      })
    );
  };
  const { handleDownload } = useDownloadFileFolder();
  const { isFullScreen, toggleFullScreen, exitFullScreen } = useFullScreen();
  const modalRef = useRef(null);
  const { findFileType, isImg, isPdf, isVideo, isAudio, isDoc } =
    useFindFileType();
  useEffect(() => {
    findFileType(url);
  }, [url]);
  useOutsideClick(modalRef, () => handleClose());
  const handleFullScreen = () => {
    toggleFullScreen();
  };
  const handleClose = () => {
    // exitFullScreen()
    setShowFile(false);
  };
  return (
    <>
      <div
        ref={modalRef}
        className={`
          modal-shadow 
          z-[10] 
          bg-F9F7F7 
          flex flex-col 
          overflow-hidden 
          ${
            isFullScreen
              ? "rounded-[0px] min-h-[100dvh] w-full max-h-[100dvh]"
              : "rounded-[12px] min-w-[900px] min-h-[600px] max-h-[670px] max-lg:min-w-[90%]"
          }
          items-center justify-start gap-[24px] 
          p-[24px] 
          fixed top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] 
          
          `}
      >
        <div className="flex items-center w-full justify-between gap-[14px] max-sm:flex-col">
          <div className="flex items-center w-full">
            <PhotoIcon className={"w-[32px] min-w-[32px] h-[36px]"} />
            <DZText children={fileName} className={"text-112D4E"} small />
          </div>
          <div className="flex items-center gap-[20px]">
            {/* <Tooltip title="Move to trash" arrow>
                <span onClick={onClickDelete} className={"cursor-pointer"}>
                  <DeleteIcon className={"w-[20px] h-[20px] cursor-pointer"} />
                </span>
              </Tooltip> */}

            {isFullScreen ? (
              <Tooltip
                title={isFullScreen ? "Restore" : " Maximize"}
                arrow
                placement="top"
              >
                <span
                  onClick={handleFullScreen}
                  className={
                    isFullScreen
                      ? "transition rotate-180 max-sm:hidden"
                      : "transition rotate-0 max-sm:hidden"
                  }
                >
                  <MaximizeIcon
                    iconColor="#112D4E"
                    className="cursor-pointer"
                  />
                </span>
              </Tooltip>
            ) : (
              <Tooltip title="Click to maximize" arrow>
                <span onClick={handleFullScreen}>
                  <MaximizeIcon
                    iconColor="#112D4E"
                    className={"w-[20px] h-[20px] cursor-pointer"}
                  />
                </span>
              </Tooltip>
            )}
            <Tooltip
              title="Click to make it starred (later you can see it in your starred files)"
              arrow
            >
              <span
                className={`cursor-pointer ${
                  isFavLoading ? "vibrate-anime" : ""
                }`}
                onClick={() => handleFavorites()}
              >
                <StarIcon
                  className={"w-[20px] h-[20px] cursor-pointer"}
                  iconColor="#112D4E"
                  bgColor={isAlreadyFav ? "#112D4E" : ""}
                />
              </span>
            </Tooltip>
            {/* <Tooltip title="Click to download" arrow>
                <a href={"https://images.unsplash.com/photo-1520209759809-a9bcb6cb3241?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8aW1nfGVufDB8fDB8fHww"} download>
                  <DownloadIcon
                    className={"w-[20px] h-[20px] cursor-pointer"}
                  />
                </a>
              </Tooltip> */}
            {/* <Tooltip title="Click to download" arrow>
                <span onClick={() => handleDownload(`${url}`, "file")}>
                  <DownloadIcon
                    className={"w-[20px] h-[20px] cursor-pointer"}
                  />
                </span>
              </Tooltip> */}
            <Tooltip placement="right" title="Click to close" arrow>
              <span onClick={() => handleClose()}>
                <CrossIcon iconColor="#112D4E" className={"cursor-pointer"} />
              </span>
            </Tooltip>
          </div>
        </div>
        <div
          className={`flex items-center justify-center max-lg:max-w-full w-[840px] max-h-[520px] rounded-[10px]   
            ${isPdf ? "overflow-scroll" : "overflow-hidden"}
            ${
              isFullScreen
                ? " min-w-[100%] min-h-[90dvh] max-h-[100%]"
                : " w-auto max-h-[500px] max-sm:min-h-[100%] max-sm:max-h-[100%] max-sm:flex-1"
            }`}
        >
          {isImg ? (
            <img
              alt="That Wanaka Tree, New Zealand by Laura Smetsers"
              src={url}
              className={`object-cover rounded-[10px] 
                ${
                  isFullScreen
                    ? "max-w-full max-h-[90dvh]"
                    : " w-[100%] max-h-[500px]"
                } `}
            />
          ) : isVideo ? (
            <ShowVideoPlayer url={url} />
          ) : isPdf ? (
            <ShowPdf
              className={
                isFullScreen
                  ? "min-w-[100%] min-h-[90dvh] max-h-[100%]"
                  : " w-[100%] h-[500px]"
              }
              pdfUrl={url}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};
export default ShowFile;
