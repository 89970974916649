import { Navigate, Outlet, useNavigate } from "react-router-dom";

import Sidebar from "../components/shared/sidebar/Sidebar";
import Navbar from "../components/shared/navbar/Navbar";
import ComposeModel from "../components/shared/composeModel/ComposeModel";
import SideBarLinks from "../constants/SideBarLinks";
import OperationBar from "../components/opreationBar/OperationBar";
import Gdpr from "../components/shared/GDPR/Gdpr";
import { getLocalStorage } from "../components/localStorage";
import { ROUTES } from "./RouteConstants";
import useAllAccountData from "../lib/useAllAccountData";
import { selectedUserAccounts } from "../redux/UserAccountsReducer";
import { useSelector } from "react-redux";

function AppLayout() {
  const accountsData = useSelector(selectedUserAccounts);

    if (accountsData?.length <= 0 || !accountsData) {
      return <Navigate to={ROUTES.createAccount} replace />
    }

  return (
    <div className="flex h-full overflow-hidden">
      <Gdpr />
      <Sidebar
        sideBarLinks={SideBarLinks.juriiEmailLinksArray}
        sideBarType={"email"}
      />
      <ComposeModel />
      <div className="flex flex-col max-h-full w-full">
        <Navbar />
        <div className="flex flex-col gap-[20px] p-[16px] max-h-full">
          <OperationBar type={"email"} />
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default AppLayout;
