import React from 'react'
import DZText from '../shared/text/DZText'

const ViewDropDownItem = ({title, icon, onClick}) => {
    return (
        <div className="flex items-center gap-[8px] cursor-pointer group" onClick={onClick}>

            {icon}

            <DZText children={title} small className={'group-hover:translate-x-[4px] transition text-[#3F72AF] cursor-pointer'} />

        </div>
    )
}

export default ViewDropDownItem;