// Config file

import { getLocalStorage } from "../components/localStorage";
import { Config } from "../constants/Index";




export default class PP2Service {

	/**
	 * 
	 * @param {string} url 
	 * @param {string} http_verb 
	 */

	static async fetch({url = '', http_verb = 'get', data = null, contentType = 'application/json', token = false}) {

		const storedToken = token ?? getLocalStorage(Config.apiTokenName);

		var requestOptions = {
			method: http_verb,
			headers: {
				Authorization: 'Bearer ' + storedToken,
				Accept: 'application/json',
				"Content-Type": contentType,
			},
		};

		if (http_verb.toLowerCase() !== 'get') requestOptions['body'] = JSON.stringify(data)

		try {
			// console.log('Config.serverApiUrl + url', Config.serverApiUrl + url)
			const response = await fetch(Config.serverApiUrl + url, requestOptions);
			const responseJson = await response.json();
			return responseJson;
		} catch (err) {
			// console.log('PP2Service :: ', err);
			return false;
		}

	}

	static async fetch2({url = '', http_verb = 'get', data = null, contentType = 'application/json', token = true}) {


		const storedToken = token ?? getLocalStorage(Config.apiTokenName);
		var requestOptions = {
			method: http_verb,
			headers: {
				Authorization: 'Bearer ' + storedToken,
				Accept: 'application/json',
				"Content-Type": contentType,
			},
		};

		if (http_verb.toLowerCase() !== 'get') requestOptions['body'] = JSON.stringify(data)

		try {
			// console.log('Config.serverApiUrl + url', Config.serverApiUrl + url)
			const response = await fetch(url, requestOptions);
			const responseJson = await response.json();
			return responseJson;
		} catch (err) {
			// console.log('PP2Service :: ', err);
			return false;
		}

	}

}
