export const ROUTES = {
    home: '/',
    inbox: '/inbox',
    starred: '/starred',
    sent: '/sent',
    draft: '/draft',
    settings: '/settings',
    drive: '/drive',
    login: '/login',
    signup: '/signup',
    confirmCode: '/confirm-code',
    forgetPassword: '/forget-password',
    allAccounts: '/all-accounts',
    createAccount: '/create-account',
    passwordManager: '/passwordManager',
    juriiDrive: {
        drive: '/drive',
        folders: '/drive/folders',
        myFiles: '/drive/my-files',
        sharedFiles: '/drive/shared-files',
        favourites: '/drive/favourites',
        trash: '/drive/trash',
    },
    juriiPasswordManager: {
        passwordManager: '/passwordManager',
        logins: '/logins',
        payments: '/passwordManager/payments',
        personalInfo: '/passwordManager/personalInfo',
        ids: '/passwordManager/ids',
    },
    juriiSettings: {
        accounts: '/accounts',
        products: '/products',
        emailSettings: '/email-settings',
    }
}