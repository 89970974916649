import React, { useState } from "react";
import { Tooltip } from "@mui/material";

import { StarIcon, ClockIcon } from "../../../icons/generalIcons/Icons";

import CheckInput from "../checkInput/CheckInput";
import DZText from "../text/DZText";
import MobileMailCard from "../mobileMailCard/MobileMailCard";
import TimeDisplay from "../../ui/error/TimeDisplay";

const DraftCard = ({ email, className }) => {
  const [active, setActive] = useState(false);
  const toggleActive = () => {
    setActive(!active);
  };
  const [selected, setSelected] = useState(false);
  const toggleSelected = () => {
    setSelected(!selected);
  };
  const [isStarred, setIsStarred] = useState(false);
  const toggleStarred = () => {
    setIsStarred(!isStarred);
  };
  return (
    <>
      <div
        className={` ${className} ${
          selected && "!bg-[#86BCFC30]"
        } flex items-center px-[24px] py-[12px] bg-F9F7F7 gap-[24px] w-full rounded-[10px] max-sm:hidden cursor-pointer `}
      >
        {/* Star Icon */}

        <Tooltip
          arrow
          title="Click to star it (you can see it in the starred page)"
        >
          <span className=" cursor-pointer">
            <StarIcon
              iconColor="#3F72AF"
              pointer
              bgColor={`${isStarred ? "#3F72AF" : ""}`}
              onClick={toggleStarred}
            />
          </span>
        </Tooltip>

        {/* Check Input */}

        <Tooltip arrow title="Click to select it">
          <span onClick={toggleActive} className=" cursor-pointer">
            <CheckInput active={active} />
          </span>
        </Tooltip>

        {/* Email Information */}

        <div
          className="flex flex-col gap-[4px] max-w-full w-full cursor-pointer"
          onClick={toggleSelected}
        >
          <DZText
            children={email?.receiver ?? email?.subject ?? email?.body ?? '' }
            small
            className={
              "text-112D4E w-fit max-w-[160px] truncate  cursor-pointer"
            }
          />

          {/* <DZText
            children={email?.subject || ""}
            small
            className={
              "text-112D4E w-fit max-w-[160px] truncate  cursor-pointer"
            }
          /> */}
        </div>

        {/* Created At Time */}

        <div className="flex items-center gap-[8px]" onClick={toggleSelected}>
          <ClockIcon iconColor="#112D4E" className={" cursor-pointer"} />
            <TimeDisplay timestamp={email?.created_at}/>
        </div>
      </div>

      {/* Mobile Version of Mail Card */}

      <MobileMailCard
        isStarred={isStarred}
        active={active}
        toggleStarred={toggleStarred}
        toggleActive={toggleActive}
        toggleSelected={toggleSelected}
        selected={selected}
      />
    </>
  );
};

export default DraftCard;
