import { Breadcrumbs } from "@material-tailwind/react";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "../../reactRoute/RouteConstants";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useEffect, useState } from "react";

export function BreadCrumb() {
  const navigate = useNavigate();
  const folderPath = useParams();
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  
  useEffect(() => {
    if (!folderPath) return; // Ensure folderPath is available before processing
    const pathForFile = concatenateValues(folderPath);
    if (!pathForFile) return; // Ensure pathForFile is available before processing
    const crumbs = pathForFile?.split("/")?.filter((crumb) => crumb?.trim() !== ""); // Split the pathForFile into breadcrumbs
    setBreadcrumbs(crumbs); // Set the breadcrumbs state
  }, [folderPath]);

  function concatenateValues(obj) {
    const folderPathValue = obj.folderPath || "";
    const dynamicValue = obj["*"] || "";
    const concatenatedValue = `${folderPathValue}${
      folderPathValue === "" || dynamicValue === "" ? "" : "/"
    }${dynamicValue}`;
    return concatenatedValue;
  }

  const handleNavigate = () => {
    navigate(ROUTES.juriiDrive.drive);
  };

  const handleNavigateBack = (index) => {
    const breadcrumbPath = breadcrumbs?.slice(0, index + 1)?.join("/");
    navigate(`${ROUTES.juriiDrive.drive}/folders/${breadcrumbPath}`);
  };
  

  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
    >
      <a
        className="capitalize cursor-pointer text-[#112D4E] text-[20px] font-[500] opacity-90"
        onClick={handleNavigate}
      >
        Drive
      </a>
      {breadcrumbs?.map((crumb, index) => (
        <a
          key={index}
          href="#"
          className={` capitalize cursor-pointer text-[#112D4E] text-[20px] font-[500]${
            index !== breadcrumbs?.length - 1 ? " opacity-90 " : ""
          }`}
          onClick={() => handleNavigateBack(index)}
        >
          {crumb}
        </a>
      ))}
    </Breadcrumbs>
  );
}
