import React, { useState } from 'react'

const CheckInput = ({ className, onClick, active }) => {

    return (
        <div
            className={` ${className} ${active ? '' : ''}  z-0 relative w-[20px] h-[20px] border-[2px] border-[#3F72AF] rounded-[4px] cursor-pointer `}
        >
            <div className={`" ${active ? 'scale-100' : 'scale-0'} transition-all absolute inset-[3px] bg-3F72AF rounded-[2px] opacity-90 scale-0 cursor-pointer "`}></div>
        </div>
    )
}

export default CheckInput;