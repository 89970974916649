import React, { useEffect } from "react";
import DZText from "../../components/shared/text/DZText";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from "../../reactRoute/RouteConstants";
import useAllAccountData from "../../lib/useAllAccountData";
const AllAccounts = () => {
  const {
    accountsData,
    selectedEmail,
    handleSelectedEmail,
    isLoadingAllAccounts,
  } = useAllAccountData();
  const navigateToInbox = true;
  return (
    <div className="flex items-center w-full h-full justify-center forms-back">
      <div className=" w-[564px] max-lg:w-[450px] max-sm:w-[350px] max-lg:rounded-[16px]  flex flex-col gap-[32px] rounded-[24px] bg-[#FFF] p-[32px] max-lg:gap-[24px] max-lg:p-[24px] max-sm:gap-[16px] max-sm:p-[16px]">
        <div className="flex flex-col gap-[20px]">
          {isLoadingAllAccounts ? (
            <>Loading...</>
          ) : (
            accountsData?.map((account, index) => (
              <div
                key={index}
                onClick={() =>
                  handleSelectedEmail(
                    account?.id,
                    account?.email,
                    navigateToInbox
                  )
                }
                className={`flex items-center gap-[10px] rounded-[10px] bg-[#F7F9F9] p-[10px] shadow-sm cursor-pointer ${
                  selectedEmail == account?.id ? "bg-DBE2EF" : ""
                }`}
              >
                <img
                  src={"https://picsum.photos/200"}
                  className="max-h-[50px] max-w-[50px] rounded-full  cursor-pointer max-lg:max-h-[40px] max-lg:max-w-[40px]"
                  alt=""
                />
                <div className="flex flex-col gap-[0px]  cursor-pointer">
                  <DZText
                    children={account?.name}
                    className="text-[14px] text-[#3F72AF] cursor-pointer"
                  />
                  <DZText
                    children={account?.email}
                    className="text-[14px] text-[#112D4E] cursor-pointer"
                  />
                </div>
              </div>
            ))
          )}
        </div>
        <Link
          to={ROUTES.createAccount}
          className="text-[#3F72AF] cursor-pointer"
        >
          {" "}
          Click to create a new{" "}
          <span className="font-[500] text-[#112D4E] underline cursor-pointer">
            Account
          </span>{" "}
        </Link>
        <div className="flex items-center gap-[10px]">
          <input type="checkbox" />
          <span className="text-[#3F72AF]"> Don't show again </span>
        </div>
      </div>
    </div>
  );
};
export default AllAccounts;
