import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

function useGetPathUrl() {

  const folderPath = useParams();

  const [pathForFile, setPathForFile] = useState("");

  function concatenateValues(obj) {
    const folderPathValue = obj.folderPath || "";
    const dynamicValue = obj["*"] || "";
    const concatenatedValue = `${folderPathValue}${
      folderPathValue == "" || dynamicValue == "" ? "" : "/"
    }${dynamicValue}`;
    return concatenatedValue;
  }
  
  useEffect(() => {
    setPathForFile(concatenateValues(folderPath));
  }, [folderPath]);

  return {
    pathForFile
  };
}

export default useGetPathUrl;
