import React from "react";

import MailCard from "../../components/shared/mailCard/MailCard";
import { useGetDraftListQuery } from "../../redux/storeApis";

import ComposeModel from "../../components/shared/composeModel/ComposeModel";
import DraftCard from "../../components/shared/mailCard/DraftCard";
import useComposeModel from "../../lib/customHooks/useComposeModel";
import OperationBar from "../../components/opreationBar/OperationBar";
import { EmptyLoader, SimpleLoader } from "../../components/loader/Loaders";
import { useSearchManager } from "../../lib/customHooks/useSearchManager";
import DZBtn from "../../components/shared/buttons/DZBtn";
import { SearchIcon } from "../../icons/generalIcons/Icons";

const Draft = () => {
  const { data : allDrafts, isLoading : isLoadingAllDrafts } = useGetDraftListQuery();
  const { handleDraftOpenComposeModel } = useComposeModel();

  const { searchLoader, fnOnSearchInputChange, fnOnSearch, fnOnEnter, fnFilterDrafts } = useSearchManager();

  const draftEmails = allDrafts?.data?.drafts;

  const filteredDrafts = fnFilterDrafts(draftEmails);
  const isExistDrafts = filteredDrafts?.length > 0;


  return (
      <div className="flex flex-col items-center w-full gap-[16px] overflow-y-scroll  h-[84svh] max-sm:h-[76vh]">

        <div className="w-full">
          <div className="flex items-center justify-between bg-[white] rounded-[25px] drop-shadow mx-1 px-[8px] w-full max-w-[600px]">
            <input
              type="text"
              placeholder="Search"
              className="text-[16px] w-[full] cursor-text p-[12px] rounded-[25px] ml-[5px] outline-none placeholder:text-[#112D4E] text-112D4E"
              onChange={(event) => fnOnSearchInputChange(event)}
              onKeyDown={fnOnEnter}
            />
            <DZBtn onClick={fnOnSearch} solid className={'rounded-full !px-[12px]'} textStyle={'hidden'} btnIcon={searchLoader ? <img className="rounded-full w-[16.5px] animate-spin" src={'/circle.png'} /> : <SearchIcon className="cursor-pointer" iconColor="#f9F7F7" />} />
          </div>
        </div>
        {isLoadingAllDrafts ? <SimpleLoader/> :
          isExistDrafts ? filteredDrafts?.map((draft) => {
            console.log(draft)
            return (
              <span className="w-full" onClick={() => handleDraftOpenComposeModel({ receiver: draft?.receiver, subject: draft?.subject,  body: draft?.body, }) }  >
                <DraftCard key={draft?.id} email={draft} />
              </span>
            )
          }) : <EmptyLoader/>
        }
    </div>
  );
};
export default Draft;