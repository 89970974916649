import React from "react";

const CreaditCardIcon = ({ className, iconColor = "#112D4E", onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="18"
      viewBox="0 0 22 18"
      fill="none"
      className={className}
      onClick={onClick}
    >
      <path
        d="M1 9C1 5.46252 1 3.69377 2.0528 2.5129C2.22119 2.32403 2.40678 2.14935 2.60746 1.99087C3.86213 1 5.74142 1 9.5 1H12.5C16.2586 1 18.1379 1 19.3925 1.99087C19.5932 2.14935 19.7788 2.32403 19.9472 2.5129C21 3.69377 21 5.46252 21 9C21 12.5375 21 14.3062 19.9472 15.4871C19.7788 15.676 19.5932 15.8506 19.3925 16.0091C18.1379 17 16.2586 17 12.5 17H9.5C5.74142 17 3.86213 17 2.60746 16.0091C2.40678 15.8506 2.22119 15.676 2.0528 15.4871C1 14.3062 1 12.5375 1 9Z"
        stroke={iconColor}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9 13H10.5"
        stroke={iconColor}
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.5 13L17 13"
        stroke={iconColor}
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1 6H21"
        stroke={iconColor}
        stroke-width="1.5"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const LockIcon = ({ className, iconColor = "#112D4E", onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="22"
      viewBox="0 0 18 22"
      fill="none"
      className={className}
      onClick={onClick}
    >
      <path
        d="M9 15.5V13.5"
        stroke={iconColor}
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M1.26781 17.8447C1.49269 19.515 2.87613 20.8235 4.55966 20.9009C5.97627 20.966 7.4153 21 9 21C10.5847 21 12.0237 20.966 13.4403 20.9009C15.1239 20.8235 16.5073 19.515 16.7322 17.8447C16.879 16.7547 17 15.6376 17 14.5C17 13.3624 16.879 12.2453 16.7322 11.1553C16.5073 9.48502 15.1239 8.17649 13.4403 8.09909C12.0237 8.03397 10.5847 8 9 8C7.4153 8 5.97627 8.03397 4.55966 8.09909C2.87613 8.17649 1.49269 9.48502 1.26781 11.1553C1.12104 12.2453 0.999998 13.3624 0.999998 14.5C0.999998 15.6376 1.12104 16.7547 1.26781 17.8447Z"
        stroke={iconColor}
        stroke-width="1.5"
      />
      <path
        d="M4.5 8V5.5C4.5 3.01472 6.51472 1 9 1C11.4853 1 13.5 3.01472 13.5 5.5V8"
        stroke={iconColor}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const AddIcon = ({ className, iconColor = "#112D4E", onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
    >
      <path
        d="M1.16663 9.00033C1.16663 13.6027 4.89759 17.3337 9.49996 17.3337C14.1023 17.3337 17.8333 13.6027 17.8333 9.00032C17.8333 4.39795 14.1023 0.666991 9.49996 0.666992"
        stroke={iconColor}
        stroke-width="1.2"
        stroke-linecap="round"
      />
      <path
        d="M3.37371 3.23295C3.48901 3.11075 3.60782 2.9919 3.73 2.87657M6.1227 1.27514C6.27395 1.20715 6.42752 1.14338 6.58325 1.08398M1.58325 6.08399C1.64321 5.92671 1.70763 5.77164 1.77635 5.61892"
        stroke={iconColor}
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.49996 5.66699V12.3337M12.8333 9.00033L6.16663 9.00033"
        stroke={iconColor}
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const AddImageIcon = ({ className, iconColor = "#112D4E", onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={22}
      height={23}
      viewBox="0 0 22 23"
      fill="none"
      className={className}
      onClick={onClick}
    >
      <path
        d="M21 6.25C21.4142 6.25 21.75 5.91421 21.75 5.5C21.75 5.08579 21.4142 4.75 21 4.75V6.25ZM13 4.75C12.5858 4.75 12.25 5.08579 12.25 5.5C12.25 5.91421 12.5858 6.25 13 6.25V4.75ZM17.75 1.5C17.75 1.08579 17.4142 0.75 17 0.75C16.5858 0.75 16.25 1.08579 16.25 1.5H17.75ZM16.25 9.5C16.25 9.91421 16.5858 10.25 17 10.25C17.4142 10.25 17.75 9.91421 17.75 9.5H16.25ZM21 4.75H17V6.25H21V4.75ZM17 4.75H13V6.25H17V4.75ZM16.25 1.5V5.5H17.75V1.5H16.25ZM16.25 5.5V9.5H17.75V5.5H16.25Z"
        fill={iconColor}
      />
      <path
        d="M10.5 2.5C6.02166 2.5 3.78249 2.5 2.39124 3.89124C1 5.28249 1 7.52166 1 12C1 16.4783 1 18.7175 2.39124 20.1088C3.78249 21.5 6.02166 21.5 10.5 21.5C14.9783 21.5 17.2175 21.5 18.6088 20.1088C20 18.7175 20 16.4783 20 12V11.5"
        stroke={iconColor}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M1 13.6354C1.61902 13.5455 2.24484 13.5011 2.87171 13.5027C5.52365 13.4466 8.11064 14.2729 10.1711 15.8342C12.082 17.2821 13.4247 19.2749 14 21.5"
        stroke={iconColor}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M20 16.3962C18.8246 15.8009 17.6088 15.4988 16.3862 15.5001C14.5345 15.4928 12.7015 16.1733 11 17.5"
        stroke={iconColor}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const BrokenIcon = ({ className, iconColor = "#112D4E", onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      className={className}
      onClick={onClick}
    >
      <path
        d="M7.33334 10.0238C7.45137 10.2171 7.59141 10.4 7.75348 10.5687C8.75974 11.616 10.2935 11.7797 11.4647 11.0598C11.6817 10.9264 11.8862 10.7627 12.0727 10.5687L14.7721 7.75919C15.9649 6.51788 15.9649 4.50531 14.7721 3.26399C13.5794 2.02268 11.6457 2.02268 10.453 3.26399L9.85836 3.88283"
        stroke={iconColor}
        stroke-width="1.2"
        stroke-linecap="round"
      />
      <path
        d="M8.14193 14.1166L7.54711 14.7357C6.35439 15.977 4.42061 15.977 3.22788 14.7357C2.03516 13.4944 2.03516 11.4818 3.22788 10.2405L5.9274 7.43099C7.12012 6.18967 9.05391 6.18967 10.2466 7.43098C10.4087 7.59961 10.5487 7.78247 10.6667 7.9757"
        stroke={iconColor}
        stroke-width="1.2"
        stroke-linecap="round"
      />
      <path
        d="M16.5 12.333H14.7676M12.3333 16.4997L12.3333 14.7673"
        stroke={iconColor}
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1.5 5.66667H3.23241M5.66667 1.5L5.66667 3.23241"
        stroke={iconColor}
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const ClockIcon = ({ className, iconColor = "#3F72AF", onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      className={className}
      onClick={onClick}
    >
      <path
        d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C6.52232 1 2.77426 3.94289 1.5 8H4"
        stroke={iconColor}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11 7V11L13 13"
        stroke={iconColor}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1 11C1 11.3373 1.0152 11.6709 1.04494 12M8 21C7.6584 20.8876 7.32471 20.7564 7 20.6078M2.20939 16C2.01655 15.6284 1.84453 15.2433 1.69497 14.8462M3.83122 18.3065C4.1369 18.6358 4.46306 18.9441 4.80755 19.2292"
        stroke={iconColor}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const CloudIcon = ({ className, iconColor = "#112D4E", onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={22}
      height={20}
      viewBox="0 0 22 20"
      fill="none"
      className={className}
      onClick={onClick}
    >
      <path
        d="M16.4776 6.69856C16.485 6.69852 16.4925 6.69851 16.5 6.69851C18.9853 6.69851 21 8.71692 21 11.2068C21 13.5273 19.25 15.4383 17 15.6875M16.4776 6.69856C16.4924 6.53356 16.5 6.36646 16.5 6.19759C16.5 3.15445 14.0376 0.6875 11 0.6875C8.12324 0.6875 5.76233 2.90017 5.52042 5.71942M16.4776 6.69856C16.3753 7.83509 15.9286 8.8721 15.2428 9.70401M5.52042 5.71942C2.98398 5.96123 1 8.10142 1 10.7058C1 13.1292 2.71776 15.1507 5 15.6148M5.52042 5.71942C5.67826 5.70437 5.83823 5.69667 6 5.69667C7.12582 5.69667 8.16474 6.06944 9.00049 6.69851"
        stroke={iconColor}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 10.6875L11 18.6875M11 10.6875C10.2998 10.6875 8.99153 12.6818 8.5 13.1875M11 10.6875C11.7002 10.6875 13.0085 12.6818 13.5 13.1875"
        stroke={iconColor}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const InboxIcon = ({ className, iconColor = "#112D4E", onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      className={className}
      onClick={onClick}
    >
      <path
        d="M1.5 11C1.5 6.52166 1.5 4.28249 2.89124 2.89124C4.28249 1.5 6.52166 1.5 11 1.5C15.4783 1.5 17.7175 1.5 19.1088 2.89124C20.5 4.28249 20.5 6.52166 20.5 11C20.5 15.4783 20.5 17.7175 19.1088 19.1088C17.7175 20.5 15.4783 20.5 11 20.5C6.52166 20.5 4.28249 20.5 2.89124 19.1088C1.5 17.7175 1.5 15.4783 1.5 11Z"
        stroke={iconColor}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.5 12.5H15.5743C14.7322 12.5 14.0706 13.2036 13.6995 13.9472C13.2963 14.7551 12.4889 15.5 11 15.5C9.51113 15.5 8.70373 14.7551 8.30054 13.9472C7.92942 13.2036 7.26777 12.5 6.42566 12.5H1.5"
        stroke={iconColor}
        stroke-width="1.5"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const CrossIcon = ({ className, iconColor = "#3F72AF", onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
      onClick={onClick}
    >
      <path
        d="M14.3995 14.4L9.59998 9.6M9.60049 14.4L14.4 9.6"
        stroke={iconColor}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12Z"
        stroke={iconColor}
      />
    </svg>
  );
};

const DashboardIcon = ({ iconColor = "#112D4E", onClick, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      className={className}
      onClick={onClick}
    >
      <path
        d="M0.999969 12.2004C0.999969 11.1221 0.999969 10.5829 1.24268 10.1869C1.37849 9.96524 1.56482 9.77891 1.78644 9.6431C2.18251 9.40039 2.72167 9.40039 3.79998 9.40039C4.87829 9.40039 5.41744 9.40039 5.81351 9.6431C6.03513 9.77891 6.22146 9.96524 6.35727 10.1869C6.59999 10.5829 6.59999 11.1221 6.59999 12.2004C6.59999 13.2787 6.59999 13.8179 6.35727 14.2139C6.22146 14.4356 6.03513 14.6219 5.81351 14.7577C5.41744 15.0004 4.87829 15.0004 3.79998 15.0004C2.72167 15.0004 2.18251 15.0004 1.78644 14.7577C1.56482 14.6219 1.37849 14.4356 1.24268 14.2139C0.999969 13.8179 0.999969 13.2787 0.999969 12.2004Z"
        stroke={iconColor}
        strokeWidth="1.12493"
      />
      <path
        d="M9.39975 12.2004C9.39975 11.1221 9.39975 10.5829 9.64246 10.1869C9.77827 9.96524 9.9646 9.77891 10.1862 9.6431C10.5823 9.40039 11.1214 9.40039 12.1998 9.40039C13.2781 9.40039 13.8172 9.40039 14.2133 9.6431C14.4349 9.77891 14.6212 9.96524 14.7571 10.1869C14.9998 10.5829 14.9998 11.1221 14.9998 12.2004C14.9998 13.2787 14.9998 13.8179 14.7571 14.2139C14.6212 14.4356 14.4349 14.6219 14.2133 14.7577C13.8172 15.0004 13.2781 15.0004 12.1998 15.0004C11.1214 15.0004 10.5823 15.0004 10.1862 14.7577C9.9646 14.6219 9.77827 14.4356 9.64246 14.2139C9.39975 13.8179 9.39975 13.2787 9.39975 12.2004Z"
        stroke={iconColor}
        strokeWidth="1.12493"
      />
      <path
        d="M1 3.80001C1 2.7217 1 2.18254 1.24271 1.78648C1.37852 1.56485 1.56485 1.37852 1.78648 1.24271C2.18254 1 2.7217 1 3.80001 1C4.87832 1 5.41747 1 5.81354 1.24271C6.03516 1.37852 6.2215 1.56485 6.3573 1.78648C6.60002 2.18254 6.60002 2.7217 6.60002 3.80001C6.60002 4.87832 6.60002 5.41747 6.3573 5.81354C6.2215 6.03516 6.03516 6.2215 5.81354 6.3573C5.41747 6.60002 4.87832 6.60002 3.80001 6.60002C2.7217 6.60002 2.18254 6.60002 1.78648 6.3573C1.56485 6.2215 1.37852 6.03516 1.24271 5.81354C1 5.41747 1 4.87832 1 3.80001Z"
        stroke={iconColor}
        strokeWidth="1.12493"
      />
      <path
        d="M9.39975 3.80001C9.39975 2.7217 9.39975 2.18254 9.64246 1.78648C9.77827 1.56485 9.9646 1.37852 10.1862 1.24271C10.5823 1 11.1214 1 12.1998 1C13.2781 1 13.8172 1 14.2133 1.24271C14.4349 1.37852 14.6212 1.56485 14.7571 1.78648C14.9998 2.18254 14.9998 2.7217 14.9998 3.80001C14.9998 4.87832 14.9998 5.41747 14.7571 5.81354C14.6212 6.03516 14.4349 6.2215 14.2133 6.3573C13.8172 6.60002 13.2781 6.60002 12.1998 6.60002C11.1214 6.60002 10.5823 6.60002 10.1862 6.3573C9.9646 6.2215 9.77827 6.03516 9.64246 5.81354C9.39975 5.41747 9.39975 4.87832 9.39975 3.80001Z"
        stroke={iconColor}
        strokeWidth="1.12493"
      />
    </svg>
  );
};

const DeleteIcon = ({ className, iconColor = "#112D4E", onClick }) => {
  return (
    <svg
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      className={className}
    >
      <path
        d="M15.25 3.58398L14.7336 11.9382C14.6016 14.0727 14.5356 15.1399 14.0006 15.9072C13.7361 16.2866 13.3955 16.6067 13.0006 16.8473C12.2018 17.334 11.1325 17.334 8.99395 17.334C6.8526 17.334 5.78192 17.334 4.98254 16.8464C4.58733 16.6054 4.24666 16.2847 3.98224 15.9047C3.4474 15.1361 3.38288 14.0674 3.25384 11.9299L2.75 3.58398"
        stroke={iconColor}
        stroke-width="1.2"
        stroke-linecap="round"
      />
      <path
        d="M1.5 3.58366H16.5M12.3798 3.58366L11.8109 2.4101C11.433 1.63054 11.244 1.24076 10.9181 0.997664C10.8458 0.943742 10.7692 0.895778 10.6892 0.854243C10.3283 0.666992 9.8951 0.666992 9.02877 0.666992C8.14069 0.666992 7.69665 0.666992 7.32974 0.862092C7.24842 0.905332 7.17082 0.955239 7.09774 1.0113C6.76803 1.26424 6.58386 1.66829 6.2155 2.47637L5.71077 3.58366"
        stroke={iconColor}
        stroke-width="1.2"
        stroke-linecap="round"
      />
      <path
        d="M6.91748 12.75L6.91748 7.75"
        stroke={iconColor}
        stroke-width="1.2"
        stroke-linecap="round"
      />
      <path
        d="M11.0825 12.75L11.0825 7.75"
        stroke={iconColor}
        stroke-width="1.2"
        stroke-linecap="round"
      />
    </svg>
  );
};

const DotsIcon = ({
  className,
  classNameSpan,
  iconColor = "#112D4E",
  onClick,
  width = "6",
  height = "20",
}) => {
  return (
    <span className={classNameSpan}>
      <svg
        onClick={onClick}
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 6 20"
        fill="none"
        className={className}
      >
        <path
          d="M4.5 2.5C4.5 1.67157 3.82843 1 3 1C2.17157 1 1.5 1.67157 1.5 2.5C1.5 3.32843 2.17157 4 3 4C3.82843 4 4.5 3.32843 4.5 2.5Z"
          stroke={iconColor}
          stroke-width="1.5"
        />
        <path
          d="M4.5 10C4.5 9.17157 3.82843 8.5 3 8.5C2.17157 8.5 1.5 9.17157 1.5 10C1.5 10.8284 2.17157 11.5 3 11.5C3.82843 11.5 4.5 10.8284 4.5 10Z"
          stroke={iconColor}
          stroke-width="1.5"
        />
        <path
          d="M4.5 17.5C4.5 16.6716 3.82843 16 3 16C2.17157 16 1.5 16.6716 1.5 17.5C1.5 18.3284 2.17157 19 3 19C3.82843 19 4.5 18.3284 4.5 17.5Z"
          stroke={iconColor}
          stroke-width="1.5"
        />
      </svg>
    </span>
  );
};

const DownloadIcon = ({ className, iconColor = "#112D4E", onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={16}
      viewBox="0 0 18 16"
      fill="none"
      className={className}
      onClick={onClick}
    >
      <path
        d="M14 5.59961C15.6462 5.65849 17.0439 6.89313 16.9989 8.5471C16.9885 8.93189 16.8394 9.40751 16.5413 10.3587C15.8239 12.6479 14.6181 14.6352 11.9169 15.112C11.4204 15.1996 10.8617 15.1996 9.74423 15.1996H8.25577C7.13832 15.1996 6.5796 15.1996 6.08307 15.112C3.38191 14.6352 2.17614 12.6479 1.45869 10.3587C1.16057 9.4075 1.01151 8.93189 1.00106 8.5471C0.956097 6.89314 2.35384 5.65849 4.00001 5.59961"
        stroke={iconColor}
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M9 9.5998L9 0.799805M9 9.5998C8.43982 9.5998 7.39322 8.00436 7 7.59981M9 9.5998C9.56018 9.5998 10.6068 8.00436 11 7.5998"
        stroke={iconColor}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const DraftIcon = ({ className, iconColor = "#112D4E", onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      className={className}
      onClick={onClick}
    >
      <path
        d="M14 16L7 16"
        stroke={iconColor}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14 12L11 12"
        stroke={iconColor}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.5 13C18.5 16.7712 18.5 18.6569 17.2552 19.8284C16.0104 21 14.0069 21 10 21H9.22727C5.96607 21 4.33546 21 3.20307 20.2022C2.87862 19.9736 2.59058 19.7025 2.3477 19.3971C1.5 18.3313 1.5 16.7966 1.5 13.7273V11.1818C1.5 8.21865 1.5 6.73706 1.96894 5.55375C2.72281 3.65142 4.31714 2.15088 6.33836 1.44135C7.59563 1 9.16981 1 12.3182 1C14.1173 1 15.0168 1 15.7352 1.2522C16.8902 1.65765 17.8012 2.5151 18.232 3.60214C18.5 4.27832 18.5 5.12494 18.5 6.81818V13Z"
        stroke={iconColor}
        stroke-width="1.5"
        stroke-linejoin="round"
      />
      <path
        d="M1.5 11C1.5 9.15905 2.99238 7.66667 4.83333 7.66667C5.49912 7.66667 6.28404 7.78333 6.93137 7.60988C7.50652 7.45576 7.95576 7.00652 8.10988 6.43136C8.28333 5.78404 8.16667 4.99912 8.16667 4.33333C8.16667 2.49238 9.65905 1 11.5 1"
        stroke={iconColor}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const EditIcon = ({ className, iconColor = "#112D4E", onClick }) => {
  return (
    <svg
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="none"
      className={className}
      onClick={onClick}
    >
      <path
        d="M12.8333 2.15911C13.2884 1.70398 13.516 1.47641 13.7681 1.36759C14.1313 1.2108 14.5431 1.2108 14.9063 1.36759C15.1584 1.47641 15.3859 1.70398 15.8411 2.15911C16.2962 2.61425 16.5238 2.84181 16.6326 3.09392C16.7894 3.45712 16.7894 3.86891 16.6326 4.23212C16.5238 4.48422 16.2962 4.71179 15.8411 5.16692L11.9493 9.05865C10.9905 10.0175 10.5111 10.4969 9.91064 10.7809C9.31019 11.065 8.63548 11.1316 7.28605 11.2647L6.67517 11.325L6.73545 10.7141C6.8686 9.36469 6.93517 8.68998 7.21923 8.08953C7.50329 7.48908 7.9827 7.00967 8.94152 6.05085L12.8333 2.15911Z"
        stroke={iconColor}
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M4.34985 11.3252H2.6061C1.85706 11.3252 1.24985 11.9324 1.24985 12.6814C1.24985 13.4305 1.85706 14.0377 2.6061 14.0377H9.9686C10.7176 14.0377 11.3248 14.6449 11.3248 15.3939C11.3248 16.143 10.7176 16.7502 9.9686 16.7502H8.22485"
        stroke={iconColor}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const ForwardIcon = ({ className, iconColor = "#112D4E", onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      className={className}
      onClick={onClick}
    >
      <path
        d="M1.33329 6.33329C1.33329 6.0057 1.33679 5.67811 1.3438 5.34956C1.38738 3.30587 1.40917 2.28402 2.16326 1.52707C2.91734 0.770113 3.96684 0.743744 6.06584 0.691005C7.3595 0.658501 8.64042 0.658499 9.93408 0.691001C12.0331 0.743735 13.0826 0.770102 13.8367 1.52706C14.5907 2.28401 14.6125 3.30586 14.6561 5.34955C14.6701 6.00667 14.6701 6.65992 14.6561 7.31704C14.6125 9.36073 14.5907 10.3826 13.8367 11.1395C13.0826 11.8965 12.0331 11.9229 9.93407 11.9756C9.28725 11.9918 8.6436 12 7.99996 12"
        stroke={iconColor}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.6666 2.33331L10.0579 4.94973C8.37419 5.90562 7.62573 5.90562 5.94198 4.94973L1.33329 2.33331"
        stroke={iconColor}
        stroke-linejoin="round"
      />
      <path
        d="M1.83333 13.3333C1.83333 13.6095 1.60948 13.8333 1.33333 13.8333C1.05719 13.8333 0.833334 13.6095 0.833334 13.3333H1.83333ZM4.68183 11.6919C4.48381 11.8843 4.16726 11.8798 3.97479 11.6818C3.78233 11.4838 3.78682 11.1673 3.98484 10.9748L4.68183 11.6919ZM3.98484 8.35854C3.78682 8.16607 3.78232 7.84952 3.97479 7.65151C4.16726 7.45349 4.48381 7.44899 4.68183 7.64146L3.98484 8.35854ZM6 10.1667H4V9.16667H6V10.1667ZM1.83333 12.3333V13.3333H0.833334V12.3333H1.83333ZM4 10.1667C2.80338 10.1667 1.83333 11.1367 1.83333 12.3333H0.833334C0.833334 10.5844 2.2511 9.16667 4 9.16667V10.1667ZM6.5 9.66667C6.5 9.83886 6.44065 9.98716 6.38306 10.0948C6.32334 10.2064 6.24603 10.3101 6.16764 10.4021C6.01123 10.5858 5.81003 10.7695 5.61633 10.9336C5.42044 11.0996 5.21442 11.2603 5.04483 11.3935C4.86388 11.5357 4.74267 11.6327 4.68183 11.6919L3.98484 10.9748C4.09257 10.8701 4.26397 10.7353 4.42713 10.6071C4.60164 10.47 4.79122 10.3221 4.96985 10.1707C5.15068 10.0175 5.30293 9.87516 5.40634 9.75374C5.45787 9.69324 5.48706 9.64969 5.50137 9.62295C5.5178 9.59225 5.5 9.61118 5.5 9.66667L6.5 9.66667ZM4.68183 7.64146C4.74267 7.70059 4.86388 7.79768 5.04483 7.9398C5.21442 8.07302 5.42044 8.23371 5.61633 8.39969C5.81003 8.56383 6.01123 8.74754 6.16764 8.93119C6.24603 9.02322 6.32334 9.12695 6.38306 9.23855C6.44065 9.34618 6.5 9.49448 6.5 9.66667L5.5 9.66667C5.5 9.72215 5.5178 9.74108 5.50137 9.71038C5.48706 9.68365 5.45787 9.64009 5.40634 9.57959C5.30293 9.45818 5.15068 9.31585 4.96985 9.16263C4.79122 9.01126 4.60164 8.86329 4.42713 8.72622C4.26397 8.59807 4.09257 8.46325 3.98484 8.35854L4.68183 7.64146Z"
        fill={iconColor}
      />
    </svg>
  );
};

const HomeIcon = ({ className, iconColor = "#112D4E", onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      className={className}
      onClick={onClick}
    >
      <path
        d="M7.99932 21L7.74868 17.4911C7.61394 15.6046 9.10803 14 10.9993 14C12.8906 14 14.3847 15.6046 14.25 17.4911L13.9993 21"
        stroke={iconColor}
        stroke-width="1.5"
      />
      <path
        d="M1.35139 12.2135C0.998371 9.91624 0.821861 8.76763 1.25617 7.74938C1.69047 6.73112 2.65403 6.03443 4.58114 4.64106L6.02099 3.6C8.41829 1.86667 9.61694 1 11 1C12.3831 1 13.5817 1.86667 15.979 3.6L17.4189 4.64106C19.346 6.03443 20.3095 6.73112 20.7438 7.74938C21.1781 8.76763 21.0016 9.91624 20.6486 12.2135L20.3476 14.1724C19.8471 17.4289 19.5969 19.0572 18.429 20.0286C17.2611 21 15.5537 21 12.1388 21H9.86119C6.44633 21 4.73891 21 3.571 20.0286C2.40309 19.0572 2.15287 17.4289 1.65243 14.1724L1.35139 12.2135Z"
        stroke={iconColor}
        stroke-width="1.5"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const ListIcon = ({ className, iconColor = "#112D4E", onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={15}
      viewBox="0 0 16 15"
      fill="none"
      className={className}
      onClick={onClick}
    >
      <path
        d="M4.58362 1.59375L14.8334 1.59375"
        stroke={iconColor}
        strokeWidth="1.3125"
        strokeLinecap="round"
      />
      <path
        d="M1.1666 1.59375H1.17475"
        stroke={iconColor}
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.1666 7.57324H1.17475"
        stroke={iconColor}
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.1666 13.5518H1.17475"
        stroke={iconColor}
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.58362 7.57324L14.8334 7.57324"
        stroke={iconColor}
        strokeWidth="1.3125"
        strokeLinecap="round"
      />
      <path
        d="M4.58362 13.5518L14.8334 13.5518"
        stroke={iconColor}
        strokeWidth="1.3125"
        strokeLinecap="round"
      />
    </svg>
  );
};

const LogoutIcon = ({ className, iconColor = "#112D4E", onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={className}
      onClick={onClick}
    >
      <path
        d="M13 15.625C12.9264 17.4769 11.3831 19.0494 9.31564 18.9988C8.83465 18.987 8.24013 18.8194 7.05112 18.484C4.18961 17.6768 1.70555 16.3203 1.10956 13.2815C1 12.723 1 12.0944 1 10.8373L1 9.16274C1 7.90561 1 7.27705 1.10956 6.71846C1.70555 3.67965 4.18961 2.32316 7.05112 1.51603C8.24014 1.18064 8.83465 1.01295 9.31564 1.00119C11.3831 0.95061 12.9264 2.52307 13 4.37501"
        stroke={iconColor}
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M19 10H8M19 10C19 9.29977 17.0057 7.99153 16.5 7.5M19 10C19 10.7002 17.0057 12.0085 16.5 12.5"
        stroke={iconColor}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const MaximizeIcon = ({ className, iconColor = "#3F72AF", onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
      onClick={onClick}
    >
      <path
        d="M8.95977 8.95976L8.60622 8.6062L8.60622 8.6062L8.95977 8.95976ZM11.3455 8.3C11.6216 8.3 11.8455 8.52385 11.8455 8.8C11.8455 9.07614 11.6216 9.3 11.3455 9.3V8.3ZM9.30001 11.3454C9.30001 11.6216 9.07615 11.8454 8.80001 11.8454C8.52387 11.8454 8.30001 11.6216 8.30001 11.3454H9.30001ZM13.5536 12.8464C13.7488 13.0417 13.7488 13.3583 13.5536 13.5535C13.3583 13.7488 13.0417 13.7488 12.8465 13.5535L13.5536 12.8464ZM11.3455 9.3H9.89092V8.3H11.3455V9.3ZM9.30001 9.8909V11.3454H8.30001V9.8909H9.30001ZM9.89092 9.3C9.61966 9.3 9.46651 9.30106 9.35903 9.31551C9.26594 9.32802 9.28482 9.34181 9.31333 9.31331L8.60622 8.6062C8.79448 8.41794 9.02181 8.35185 9.22579 8.32442C9.41538 8.29893 9.64793 8.3 9.89092 8.3V9.3ZM8.30001 9.8909C8.30001 9.64791 8.29895 9.41536 8.32444 9.22577C8.35187 9.02179 8.41795 8.79447 8.60622 8.6062L9.31333 9.31331C9.34183 9.2848 9.32804 9.26593 9.31552 9.35902C9.30107 9.46649 9.30001 9.61964 9.30001 9.8909H8.30001ZM9.31333 8.6062L13.5536 12.8464L12.8465 13.5535L8.60622 9.31331L9.31333 8.6062Z"
        fill={iconColor}
      />
      <path
        d="M20 16C20 14.4915 20 13.7373 19.5314 13.2686C19.0627 12.8 18.3085 12.8 16.8 12.8H16C14.4915 12.8 13.7373 12.8 13.2686 13.2686C12.8 13.7373 12.8 14.4915 12.8 16V16.8C12.8 18.3085 12.8 19.0627 13.2686 19.5314C13.7373 20 14.4915 20 16 20H16.8C18.3085 20 19.0627 20 19.5314 19.5314C20 19.0627 20 18.3085 20 16.8V16Z"
        stroke={iconColor}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20 9.2V10.8M10.4 4H13.6M4 13.6L4 10.4M10.8 20H9.2M19.9521 6.8C19.8289 5.94753 19.5892 5.34323 19.123 4.877C18.6568 4.41077 18.0525 4.1711 17.2 4.04788M6.8 4.04788C5.94753 4.1711 5.34323 4.41077 4.877 4.877C4.41077 5.34323 4.1711 5.94753 4.04788 6.8M4.04788 17.2C4.1711 18.0525 4.41077 18.6568 4.877 19.123C5.34323 19.5892 5.94753 19.8289 6.8 19.9521"
        stroke={iconColor}
        stroke-linecap="round"
      />
    </svg>
  );
};

const MinusIcon = ({ className, iconColor = "#112D4E", onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
      onClick={onClick}
    >
      <path
        d="M20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12Z"
        stroke="#3F72AF"
      />
      <path
        d="M16 12H8"
        stroke="#3F72AF"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const NotificationIcon = ({ className, iconColor = "#112D4E", onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      className={className}
      onClick={onClick}
    >
      <path
        d="M3.15837 10.3732C3.08489 11.7519 3.16936 13.2194 1.92213 14.1432C1.34164 14.5731 1 15.2479 1 15.9645C1 16.9502 1.7818 17.7889 2.8 17.7889H17.2C18.2182 17.7889 19 16.9502 19 15.9645C19 15.2479 18.6584 14.5731 18.0779 14.1432C16.8306 13.2194 16.9151 11.7519 16.8416 10.3732C16.6501 6.77966 13.6438 3.96289 10 3.96289C6.35617 3.96289 3.34988 6.77966 3.15837 10.3732Z"
        stroke={iconColor}
        stroke-width="1.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.5 2.11102C8.5 2.92915 9.17157 3.96271 10 3.96271C10.8284 3.96271 11.5 2.92915 11.5 2.11102C11.5 1.29289 10.8284 1 10 1C9.17157 1 8.5 1.29289 8.5 2.11102Z"
        stroke={iconColor}
        stroke-width="1.125"
      />
      <path
        className="bell__ring"
        d="M13.2656 18.2871C13.2656 19.9234 11.9225 21.2498 10.2656 21.2498C8.60877 21.2498 7.26562 19.9234 7.26562 18.2871"
        stroke={iconColor}
        stroke-width="1.125"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const ReplyIcon = ({ className, iconColor = "#112D4E", onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      className={className}
      onClick={onClick}
    >
      <path
        d="M14.6667 6.33329C14.6667 6.0057 14.6632 5.67811 14.6562 5.34956C14.6126 3.30587 14.5908 2.28402 13.8367 1.52707C13.0827 0.770113 12.0332 0.743744 9.93416 0.691005C8.6405 0.658501 7.35958 0.658499 6.06592 0.691001C3.96693 0.743735 2.91743 0.770102 2.16334 1.52706C1.40926 2.28401 1.38747 3.30586 1.34388 5.34955C1.32987 6.00667 1.32987 6.65992 1.34388 7.31704C1.38747 9.36073 1.40926 10.3826 2.16335 11.1395C2.91743 11.8965 3.96693 11.9229 6.06593 11.9756C6.71275 11.9918 7.3564 12 8.00004 12"
        stroke={iconColor}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1.33337 2.33331L5.94206 4.94973C7.62581 5.90562 8.37427 5.90562 10.058 4.94973L14.6667 2.33331"
        stroke={iconColor}
        stroke-linejoin="round"
      />
      <path
        d="M14.1667 13.3333C14.1667 13.6095 14.3905 13.8333 14.6667 13.8333C14.9428 13.8333 15.1667 13.6095 15.1667 13.3333H14.1667ZM11.3182 11.6919C11.5162 11.8843 11.8327 11.8798 12.0252 11.6818C12.2177 11.4838 12.2132 11.1673 12.0152 10.9748L11.3182 11.6919ZM12.0152 8.35854C12.2132 8.16607 12.2177 7.84952 12.0252 7.65151C11.8327 7.45349 11.5162 7.44899 11.3182 7.64146L12.0152 8.35854ZM10 10.1667H12V9.16667H10V10.1667ZM14.1667 12.3333V13.3333H15.1667V12.3333H14.1667ZM12 10.1667C13.1966 10.1667 14.1667 11.1367 14.1667 12.3333H15.1667C15.1667 10.5844 13.7489 9.16667 12 9.16667V10.1667ZM9.5 9.66667C9.5 9.83886 9.55935 9.98716 9.61694 10.0948C9.67666 10.2064 9.75397 10.3101 9.83236 10.4021C9.98877 10.5858 10.19 10.7695 10.3837 10.9336C10.5796 11.0996 10.7856 11.2603 10.9552 11.3935C11.1361 11.5357 11.2573 11.6327 11.3182 11.6919L12.0152 10.9748C11.9074 10.8701 11.736 10.7353 11.5729 10.6071C11.3984 10.47 11.2088 10.3221 11.0301 10.1707C10.8493 10.0175 10.6971 9.87516 10.5937 9.75374C10.5421 9.69324 10.5129 9.64969 10.4986 9.62295C10.4822 9.59225 10.5 9.61118 10.5 9.66667L9.5 9.66667ZM11.3182 7.64146C11.2573 7.70059 11.1361 7.79768 10.9552 7.9398C10.7856 8.07302 10.5796 8.23371 10.3837 8.39969C10.19 8.56383 9.98877 8.74754 9.83236 8.93119C9.75397 9.02322 9.67666 9.12695 9.61694 9.23855C9.55935 9.34618 9.5 9.49448 9.5 9.66667L10.5 9.66667C10.5 9.72215 10.4822 9.74108 10.4986 9.71038C10.5129 9.68365 10.5421 9.64009 10.5937 9.57959C10.6971 9.45818 10.8493 9.31585 11.0301 9.16263C11.2088 9.01126 11.3984 8.86329 11.5729 8.72622C11.736 8.59807 11.9074 8.46325 12.0152 8.35854L11.3182 7.64146Z"
        fill={iconColor}
      />
    </svg>
  );
};

const SearchIcon = ({ className, iconColor = "#112D4E", onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={className}
      onClick={onClick}
    >
      <path
        d="M11.6666 11.666L14.6666 14.666"
        stroke={iconColor}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.3333 7.33301C13.3333 4.0193 10.647 1.33301 7.33325 1.33301C4.01954 1.33301 1.33325 4.0193 1.33325 7.33301C1.33325 10.6467 4.01954 13.333 7.33325 13.333C10.647 13.333 13.3333 10.6467 13.3333 7.33301Z"
        stroke={iconColor}
        stroke-width="1.5"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const SentIcon = ({ className, iconColor = "#112D4E", onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
      onClick={onClick}
    >
      <path
        d="M21.5239 2.58203C19.2312 0.113014 1.98577 6.16126 2.00001 8.36948C2.01615 10.8735 8.73483 11.6439 10.5971 12.1664C11.7169 12.4806 12.0169 12.8026 12.2751 13.9769C13.4446 19.2952 14.0317 21.9405 15.3699 21.9996C17.5029 22.0939 23.7614 4.99158 21.5239 2.58203Z"
        stroke={iconColor}
        stroke-width="1.5"
      />
      <path
        d="M11.4746 12.5264L15.1588 8.84215"
        stroke={iconColor}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const SettingIcon = ({ className, iconColor = "#112D4E", onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      className={className}
      onClick={onClick}
    >
      <path
        d="M14.5 11C14.5 12.933 12.933 14.5 11 14.5C9.067 14.5 7.5 12.933 7.5 11C7.5 9.067 9.067 7.5 11 7.5C12.933 7.5 14.5 9.067 14.5 11Z"
        stroke={iconColor}
        stroke-width="1.5"
      />
      <path
        d="M20.011 13.0965C20.5329 12.9558 20.7939 12.8854 20.8969 12.7508C21 12.6163 21 12.3998 21 11.9669V10.0332C21 9.60029 21 9.38382 20.8969 9.24926C20.7938 9.11471 20.5329 9.04433 20.011 8.90358C18.0606 8.37759 16.8399 6.33851 17.3433 4.40087C17.4817 3.86799 17.5509 3.60156 17.4848 3.44529C17.4187 3.28902 17.2291 3.18134 16.8497 2.96596L15.125 1.98673C14.7528 1.77539 14.5667 1.66972 14.3997 1.69222C14.2326 1.71472 14.0442 1.90273 13.6672 2.27873C12.208 3.73448 9.79358 3.73442 8.33434 2.27864C7.95743 1.90263 7.76898 1.71463 7.60193 1.69212C7.43489 1.66962 7.24877 1.77529 6.87653 1.98663L5.15184 2.96587C4.77253 3.18123 4.58287 3.28891 4.51678 3.44515C4.45068 3.6014 4.51987 3.86787 4.65825 4.4008C5.16137 6.3385 3.93972 8.37763 1.98902 8.9036C1.46712 9.04432 1.20617 9.11468 1.10308 9.24924C1 9.3838 1 9.60028 1 10.0332V11.9669C1 12.3998 1 12.6163 1.10308 12.7508C1.20615 12.8854 1.46711 12.9558 1.98902 13.0965C3.9394 13.6225 5.16008 15.6616 4.65672 17.5992C4.51829 18.1321 4.44907 18.3985 4.51516 18.5548C4.58126 18.7111 4.77092 18.8188 5.15025 19.0341L6.87495 20.0134C7.24721 20.2247 7.43334 20.3304 7.6004 20.3079C7.76746 20.2854 7.95588 20.0973 8.33271 19.7213C9.79274 18.2644 12.2088 18.2643 13.6689 19.7212C14.0457 20.0973 14.2341 20.2853 14.4012 20.3078C14.5682 20.3303 14.7544 20.2246 15.1266 20.0133L16.8513 19.034C17.2307 18.8187 17.4204 18.711 17.4864 18.5547C17.5525 18.3984 17.4833 18.132 17.3448 17.5991C16.8412 15.6616 18.0609 13.6226 20.011 13.0965Z"
        stroke={iconColor}
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  );
};

const StarIcon = ({
  className,
  iconColor = "#112D4E",
  onClick,
  bgColor,
  pointer,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={` ${pointer ? "cursor-pointer" : ""} ${className}`}
      onClick={onClick}
    >
      <path
        d="M13.7276 3.44418L15.4874 6.99288C15.7274 7.48687 16.3673 7.9607 16.9073 8.05144L20.0969 8.58576C22.1367 8.92853 22.6167 10.4206 21.1468 11.8925L18.6671 14.3927C18.2471 14.8161 18.0172 15.6327 18.1471 16.2175L18.8571 19.3125C19.417 21.7623 18.1271 22.71 15.9774 21.4296L12.9877 19.6452C12.4478 19.3226 11.5579 19.3226 11.0079 19.6452L8.01827 21.4296C5.8785 22.71 4.57865 21.7522 5.13859 19.3125L5.84851 16.2175C5.97849 15.6327 5.74852 14.8161 5.32856 14.3927L2.84884 11.8925C1.389 10.4206 1.85895 8.92853 3.89872 8.58576L7.08837 8.05144C7.61831 7.9607 8.25824 7.48687 8.49821 6.99288L10.258 3.44418C11.2179 1.51861 12.7777 1.51861 13.7276 3.44418Z"
        stroke={iconColor}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill={bgColor}
        className={` ${pointer ? "cursor-pointer" : ""}`}
      />
    </svg>
  );
};

const PaymentCardIcon = ({ className, iconColor = "#112D4E", onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="18"
      viewBox="0 0 22 18"
      fill="none"
      className={className}
      onClick={onClick}
    >
      <path
        d="M1 9C1 5.46252 1 3.69377 2.0528 2.5129C2.22119 2.32403 2.40678 2.14935 2.60746 1.99087C3.86213 1 5.74142 1 9.5 1H12.5C16.2586 1 18.1379 1 19.3925 1.99087C19.5932 2.14935 19.7788 2.32403 19.9472 2.5129C21 3.69377 21 5.46252 21 9C21 12.5375 21 14.3062 19.9472 15.4871C19.7788 15.676 19.5932 15.8506 19.3925 16.0091C18.1379 17 16.2586 17 12.5 17H9.5C5.74142 17 3.86213 17 2.60746 16.0091C2.40678 15.8506 2.22119 15.676 2.0528 15.4871C1 14.3062 1 12.5375 1 9Z"
        stroke={iconColor}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9 13H10.5"
        stroke={iconColor}
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.5 13L17 13"
        stroke={iconColor}
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1 6H21"
        stroke={iconColor}
        stroke-width="1.5"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const BankIcon = ({
  className = "w-[22px] h-[22px]",
  iconColor = "#112D4E",
  onClick,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={24}
      height={24}
      color={"#000000"}
      fill={"none"}
      className={className}
      onClick={onClick}
    >
      <path
        d="M2 8.56907C2 7.37289 2.48238 6.63982 3.48063 6.08428L7.58987 3.79744C9.7431 2.59915 10.8197 2 12 2C13.1803 2 14.2569 2.59915 16.4101 3.79744L20.5194 6.08428C21.5176 6.63982 22 7.3729 22 8.56907C22 8.89343 22 9.05561 21.9646 9.18894C21.7785 9.88945 21.1437 10 20.5307 10H3.46928C2.85627 10 2.22152 9.88944 2.03542 9.18894C2 9.05561 2 8.89343 2 8.56907Z"
        stroke={iconColor}
        strokeWidth="1.5"
      />
      <path
        d="M11.9959 7H12.0049"
        stroke={iconColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M4 10V18.5M8 10V18.5" stroke={iconColor} strokeWidth="1.5" />
      <path d="M16 10V18.5M20 10V18.5" stroke={iconColor} strokeWidth="1.5" />
      <path
        d="M19 18.5H5C3.34315 18.5 2 19.8431 2 21.5C2 21.7761 2.22386 22 2.5 22H21.5C21.7761 22 22 21.7761 22 21.5C22 19.8431 20.6569 18.5 19 18.5Z"
        stroke={iconColor}
        strokeWidth="1.5"
      />
    </svg>
  );
};

const UserIcon = ({ className, iconColor = "#112D4E", onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={37}
      height={41}
      viewBox="0 0 37 41"
      fill="none"
      onClick={onClick}
      className={className}
    >
      <path
        d="M8.33295 27.028C5.68026 28.6075 -1.27494 31.8327 2.96124 35.8686C5.03058 37.84 7.3353 39.25 10.2329 39.25H26.7671C29.6647 39.25 31.9694 37.84 34.0388 35.8686C38.2749 31.8327 31.3197 28.6075 28.667 27.028C22.4465 23.324 14.5535 23.324 8.33295 27.028Z"
        stroke={iconColor}
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.9375 10.1875C26.9375 14.8474 23.1599 18.625 18.5 18.625C13.8401 18.625 10.0625 14.8474 10.0625 10.1875C10.0625 5.5276 13.8401 1.75 18.5 1.75C23.1599 1.75 26.9375 5.5276 26.9375 10.1875Z"
        stroke={iconColor}
        strokeWidth={3}
      />
    </svg>
  );
};
const RecycleBinIcon = ({ className, iconColor = "#112D4E", onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={24}
      height={24}
      color={iconColor}
      fill={"none"}
      onClick={onClick}
      className={className}
    >
      <path
        d="M20.25 5.5L19.75 11.5M5.25 5.5L5.85461 15.5368C6.00945 18.1073 6.08688 19.3925 6.72868 20.3167C7.046 20.7737 7.4548 21.1594 7.92905 21.4493C8.51127 21.8051 9.21343 21.945 10.25 22"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M11.75 15.5L12.8863 16.9657C13.458 14.8319 15.6514 13.5655 17.7852 14.1373C18.8775 14.43 19.7425 15.1475 20.25 16.0646M21.75 20.5L20.6137 19.0363C20.0419 21.1701 17.8486 22.4365 15.7147 21.8647C14.6478 21.5788 13.7977 20.8875 13.2859 20.001"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.75 5.5H21.75M16.8057 5.5L16.1231 4.09173C15.6696 3.15626 15.4428 2.68852 15.0517 2.39681C14.965 2.3321 14.8731 2.27454 14.777 2.2247C14.3439 2 13.8241 2 12.7845 2C11.7188 2 11.186 2 10.7457 2.23412C10.6481 2.28601 10.555 2.3459 10.4673 2.41317C10.0716 2.7167 9.85063 3.20155 9.40861 4.17126L8.80292 5.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

const RestoreIcon = ({ className, iconColor = "#112D4E", onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={24}
      height={24}
      color={iconColor}
      fill={"none"}
      onClick={onClick}
      className={className}
    >
      <path
        d="M4.47461 6.10018L5.31543 18.1768C5.40886 19.3365 6.28178 21.5536 8.51889 21.8022C10.756 22.0507 15.2503 21.9951 16.0699 21.9951C16.8895 21.9951 19.0128 21.4136 19.0128 19.0059C19.0128 16.5756 16.9833 15.9419 15.7077 15.9635H12.0554M12.0554 15.9635C12.0607 15.7494 12.1515 15.5372 12.3278 15.3828L14.487 13.4924M12.0554 15.9635C12.0497 16.1919 12.1412 16.4224 12.33 16.5864L14.487 18.4609M19.4701 5.82422L19.0023 13.4792"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 5.49561H21M16.0555 5.49561L15.3729 4.08911C14.9194 3.15481 14.6926 2.68766 14.3015 2.39631C14.2148 2.33168 14.1229 2.2742 14.0268 2.22442C13.5937 2 13.0739 2 12.0343 2C10.9686 2 10.4358 2 9.99549 2.23383C9.89791 2.28565 9.80479 2.34547 9.7171 2.41265C9.32145 2.7158 9.10044 3.20004 8.65842 4.16854L8.05273 5.49561"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const RefreshIcon = ({ className, iconColor = "#112D4E", onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={24}
      height={24}
      color={iconColor}
      fill={"none"}
      onClick={onClick}
      className={className}
    >
      <path
        d="M20.0092 2V5.13219C20.0092 5.42605 19.6418 5.55908 19.4537 5.33333C17.6226 3.2875 14.9617 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const IDCardIcon = ({ className, iconColor = "#112D4E", onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={24}
      height={24}
      className={className}
      color={iconColor}
      onClick={onClick}
      fill={"none"}
    >
      <path
        d="M14 3.5H10C6.22876 3.5 4.34315 3.5 3.17157 4.67157C2 5.84315 2 7.72876 2 11.5V12.5C2 16.2712 2 18.1569 3.17157 19.3284C4.34315 20.5 6.22876 20.5 10 20.5H14C17.7712 20.5 19.6569 20.5 20.8284 19.3284C22 18.1569 22 16.2712 22 12.5V11.5C22 7.72876 22 5.84315 20.8284 4.67157C19.6569 3.5 17.7712 3.5 14 3.5Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M5 16C6.03569 13.4189 9.89616 13.2491 11 16"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M9.75 9.75C9.75 10.7165 8.9665 11.5 8 11.5C7.0335 11.5 6.25 10.7165 6.25 9.75C6.25 8.7835 7.0335 8 8 8C8.9665 8 9.75 8.7835 9.75 9.75Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M14 8.5H19M14 12H19M14 15.5H16.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const AccountsIcon = ({ className, iconColor = "#ffffff", onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={24}
      height={24}
      onClick={onClick}
      className={className}
      color={iconColor}
      fill={"none"}
    >
      <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="1.5" />
      <path
        d="M7.5 17C9.8317 14.5578 14.1432 14.4428 16.5 17M14.4951 9.5C14.4951 10.8807 13.3742 12 11.9915 12C10.6089 12 9.48797 10.8807 9.48797 9.5C9.48797 8.11929 10.6089 7 11.9915 7C13.3742 7 14.4951 8.11929 14.4951 9.5Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

const ProductsIcon = ({ className, iconColor = "#ffffff", onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={24}
      height={24}
      color={iconColor}
      onClick={onClick}
      className={className}
      fill={"none"}
    >
      <path
        d="M4.5 10.2653V6H19.5V10.2653C19.5 13.4401 19.5 15.0275 18.5237 16.0137C17.5474 17 15.976 17 12.8333 17H11.1667C8.02397 17 6.45262 17 5.47631 16.0137C4.5 15.0275 4.5 13.4401 4.5 10.2653Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 6L5.22115 4.46154C5.78045 3.26838 6.06009 2.6718 6.62692 2.3359C7.19375 2 7.92084 2 9.375 2H14.625C16.0792 2 16.8062 2 17.3731 2.3359C17.9399 2.6718 18.2196 3.26838 18.7788 4.46154L19.5 6"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M10.5 9H13.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M12 19.5V22M12 19.5L7 19.5M12 19.5H17M7 19.5H4.5C3.11929 19.5 2 20.6193 2 22M7 19.5V22M17 19.5H19.5C20.8807 19.5 22 20.6193 22 22M17 19.5V22"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const EmailSettingsIcon = ({ className, iconColor = "#ffffff", onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={24}
      height={24}
      fill={"none"}
      color={iconColor}
      onClick={onClick}
      className={className}
    >
      <path
        d="M7 7.5L9.94202 9.23943C11.6572 10.2535 12.3428 10.2535 14.058 9.23943L17 7.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 19.5C11 19.5 10.0691 19.4878 9.09883 19.4634C5.95033 19.3843 4.37608 19.3448 3.24496 18.2094C2.11383 17.0739 2.08114 15.5412 2.01577 12.4756C1.99475 11.4899 1.99474 10.5101 2.01576 9.52438C2.08114 6.45885 2.11382 4.92608 3.24495 3.79065C4.37608 2.65521 5.95033 2.61566 9.09882 2.53656C11.0393 2.48781 12.9607 2.48781 14.9012 2.53657C18.0497 2.61568 19.6239 2.65523 20.7551 3.79066C21.8862 4.92609 21.9189 6.45886 21.9842 9.52439C21.9918 9.88124 21.9967 10.4995 21.9988 11"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 20.2143V21.5M18 20.2143C16.8432 20.2143 15.8241 19.6461 15.2263 18.7833M18 20.2143C19.1568 20.2143 20.1759 19.6461 20.7737 18.7833M18 13.7857C19.1569 13.7857 20.1761 14.354 20.7738 15.2169M18 13.7857C16.8431 13.7857 15.8239 14.354 15.2262 15.2169M18 13.7857V12.5M22 14.4286L20.7738 15.2169M14.0004 19.5714L15.2263 18.7833M14 14.4286L15.2262 15.2169M21.9996 19.5714L20.7737 18.7833M20.7738 15.2169C21.1273 15.7271 21.3333 16.3403 21.3333 17C21.3333 17.6597 21.1272 18.273 20.7737 18.7833M15.2262 15.2169C14.8727 15.7271 14.6667 16.3403 14.6667 17C14.6667 17.6597 14.8728 18.273 15.2263 18.7833"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

const ArrowIcon = ({ className, iconColor = "#112D4E", onClick }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color={iconColor} fill="none" className={className} onClick={onClick}>
      <path d="M18 9.00005C18 9.00005 13.5811 15 12 15C10.4188 15 6 9 6 9" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};

export {
  AddIcon,
  LockIcon,
  CreaditCardIcon,
  AddImageIcon,
  BrokenIcon,
  ClockIcon,
  CrossIcon,
  DeleteIcon,
  CloudIcon,
  DashboardIcon,
  DotsIcon,
  DownloadIcon,
  DraftIcon,
  EditIcon,
  ForwardIcon,
  HomeIcon,
  InboxIcon,
  ListIcon,
  LogoutIcon,
  MaximizeIcon,
  MinusIcon,
  NotificationIcon,
  ReplyIcon,
  SearchIcon,
  SentIcon,
  SettingIcon,
  StarIcon,
  PaymentCardIcon,
  BankIcon,
  UserIcon,
  RecycleBinIcon,
  RestoreIcon,
  RefreshIcon,
  AccountsIcon,
  ProductsIcon,
  EmailSettingsIcon,
  IDCardIcon,
  ArrowIcon
};
