import React from 'react'

const VideoIcon = ({ className }) => {
    return (
        <svg
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            width={78}
            height={81}
            viewBox="0 0 78 81"
            fill="none"
        >
            <path
                d="M58.639 26.925c3.402-2.137 7.864.058 8.247 4.058v19.293c0 4.17-4.176 7.05-8.074 5.565L42.668 45.439c-2.967-1.912-3.112-6.197-.283-8.306L58.64 26.925z"
                fill="#86BCFC"
            />
            <rect
                x={12}
                y={17} 
                width={41.0568}
                height={46.9533}
                rx={10.3068}
                fill="#112D4E"
            />
            <ellipse
                cx={36.31}
                cy={33.9254}
                rx={4.86199}
                ry={4.91372}
                fill="#86BCFC"
            />
        </svg>
    )
}

export default VideoIcon