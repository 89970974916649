import React, { useState } from "react";
import DZText from "../text/DZText";
import InputField from "../inputField/InputField";
import DZBtn from "../buttons/DZBtn";
import { useAddDriveFolderMutation } from "../../../redux/juriiDriveApis";
import { useSnackBarManager } from "../../../lib/customHooks/useSnackBarManager";
import { useDispatch } from "react-redux";
import { setCreateFolderSlug } from "../../../redux/CreateFolderReducer";
import useGetPathUrl from "../../../lib/useGetPathUrl";
import useEnterKeySubmit from "../../../lib/useEnterKeySubmit";
function CreateFolderModel({ cancel, rename, onSuccess }) {
  const dispatch = useDispatch();
  const [addFolder, { isLoading }] = useAddDriveFolderMutation();
  const [folderName, setFolderName] = useState();
  const { fnShowSnackBar } = useSnackBarManager();
  const { pathForFile } = useGetPathUrl();

  const createFolder = () => {
    if (!folderName) {
      fnShowSnackBar("Folder name is required", true);
      return;
    }
    addFolder({
      name: folderName,
      parent_folder: pathForFile || null,
    })
      .then((res) => {
        if (res?.data?.success) {
          fnShowSnackBar(res?.data?.message || "Create Folder successfully!");
          onSuccess();
          cancel();
        }
      })
      .catch((error) => {
        console.error("Error creating folder:", error);
      })
      .finally(() => {
        cancel();
        dispatch(setCreateFolderSlug(`${pathForFile}${"/" + folderName}`));
      });
  };

  const { handleKeyDown } = useEnterKeySubmit(createFolder);
  return (
    <>
      <span className="fixed inset-0 z-[9] cursor-arrow"></span>

      <div className="modal-shadow z-[10] bg-F9F7F7 flex flex-col min-w-[420px] items-center justify-center gap-[16px] p-[24px] fixed top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] rounded-[12px]">
        <DZText normal children={"Create Folder"} className={"text-112D4E"} />
        <InputField
          placeholder={"Name your folder"}
          onChange={(e) => setFolderName(e.target.value)}
          className={"w-full placeholder:text-3F72AF"}
          onKeyDown={handleKeyDown}
        />
        <div className="flex items-center gap-[14px]">
          <DZBtn
            children={isLoading ? "Loading..." : rename ? "Rename" : "Create"}
            onClick={createFolder}
            solid
            className={"text-white rounded-[10px] cursor-pointer"}
          />
          <DZBtn
            children={"Cancel"}
            onClick={cancel}
            className={
              " rounded-[10px] text-112D4E cursor-pointer bg-white shadow"
            }
          />
        </div>
      </div>
    </>
  );
}

export default CreateFolderModel;
