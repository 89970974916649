import { useState } from "react";
import { Config, EMAIL_TYPE } from "../../constants/Index";

export function useSearchManager() {
  const [searchInput, setSearchInput] = useState("");
  const [searchTxt, setSearchTxt] = useState("");
  const [searchLoader, setSearchLoader] = useState(false);

  const fnOnSearch = () => {
    if (searchInput) {
      setSearchLoader(true);
      setTimeout(() => {
        setSearchLoader(false);
        setSearchTxt(searchInput?.trim()?.toLowerCase());
      }, 1000);
    }
  };

  const fnOnSearchInputChange = (event) => {
    const searchValue = event.target.value;
    setSearchInput(searchValue);
    if (!searchValue) {
      setSearchTxt("");
    }
  };

  const fnOnEnter = (event) => {
    if (event.key === "Enter") {
      fnOnSearch();
    }
  };

  const fnFilterFilesAndFolders = (items) => {
    if (!Array.isArray(items)) return [];
    if (!searchTxt) return items;
    return items?.filter((item) =>
      item?.name?.toLowerCase()?.includes(searchTxt)
    );
  };

  const fnFilterLogins = (items) => {
    if (!Array.isArray(items)) return [];
    if (!searchTxt) return items;
    return items?.filter((item) => {
      const websiteName = Config.fnGetWebsiteName(item?.website);
      return websiteName?.toLowerCase()?.includes(searchTxt);
    });
  };

  const fnFilterPayments = (items) => {
    if (!Array.isArray(items)) return [];
    if (!searchTxt) return items;
    return items?.filter((item) =>
      item?.name?.toLowerCase()?.includes(searchTxt)
    );
  };

  const fnFilterEmails = (items, type) => {
    if (!Array.isArray(items)) return [];
    if (!searchTxt) return items;
    return items?.filter((item) => {
      const subjectTitle = item?.subject?.toLowerCase();
      const emailTitle =
        type == EMAIL_TYPE.inbox
          ? item?.from?.toLowerCase()
          : item?.to?.toLowerCase();

      return (
        emailTitle?.includes(searchTxt) || subjectTitle?.includes(searchTxt)
      );
    });
  };

  const fnFilterDrafts = (items) => {
    if (!Array.isArray(items)) return [];
    if (!searchTxt) return items;
    return items?.filter((item) => {
      const receiver = item?.receiver?.toLowerCase();
      const subject = item?.subject?.toLowerCase();
      const body = item?.body?.toLowerCase();

      return (
        receiver?.includes(searchTxt) ||
        subject?.includes(searchTxt) ||
        body?.includes(searchTxt)
      );
    });
  };

  return {
    searchInput,
    searchTxt,
    searchLoader,

    setSearchInput,
    setSearchTxt,
    setSearchLoader,

    fnOnSearch,
    fnOnSearchInputChange,
    fnOnEnter,
    fnFilterFilesAndFolders,
    fnFilterLogins,
    fnFilterPayments,
    fnFilterEmails,
    fnFilterDrafts,
  };
}
