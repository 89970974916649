import { useState } from "react";
import { useGetReceivedEmailListQuery, useGetSentEmailListQuery } from "../../redux/storeApis";
import { useGetDriveFoldersQuery } from "../../redux/juriiDriveApis";
import { useGetLoginsQuery, useGetPaymentsQuery } from "../../redux/passwordManagerApis";
import { useLocation } from "react-router-dom";
import { ROUTES } from "../../reactRoute/RouteConstants";
import { useDispatch } from "react-redux";
import { setIsLoading } from "../../redux/LoadingReducer";

export function useRefechApisManager() {

    const location = useLocation();
    const dispatch = useDispatch();

    const currentRoute = location?.pathname;

    const [isLoadingEmails, setIsLoadingEmails] = useState(false);

    // Email Apis

    const { refetch : refetchInboxMails} = useGetReceivedEmailListQuery();
    const { refetch : refetchSentMails} = useGetSentEmailListQuery();

    const fnOnRefetch = async() => {

        setIsLoadingEmails(true);
        dispatch(setIsLoading(true));

        if(currentRoute == ROUTES.inbox) { await refetchInboxMails(); }
        if(currentRoute == ROUTES.sent) { await refetchSentMails(); }

        setIsLoadingEmails(false);
        dispatch(setIsLoading(false));
        
    }

    // Drive apis

    // const { refetch : refetchDriveFilesFolders } = useGetDriveFoldersQuery();

    // Password manager apis

    // const { refetch : refetchLogins } = useGetLoginsQuery();
    // const { refetch : refetchPayments } = useGetPaymentsQuery();
    return {
        isLoadingEmails, 
        fnOnRefetch
    }
};