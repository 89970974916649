import React, { useState } from "react";

import ArrowDown from "../../../icons/arrowIcons/ArrowDown";

import DZText from "../text/DZText";
import Logout from "../../../pages/logout/Logout";
import NavbarEmailSwitcher from "./NavbarEmailSwitcher";
import { useUserManager } from "../../../lib/customHooks/useUserManager";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../reactRoute/RouteConstants";
import AddUpdateEmailSetting from "../modals/AddUpdateEmailSetting";
const ProfileDropdown = ({ openProfileDrop, profileModal }) => {
  const [openProductDrop, setOpenProductDrop] = useState(false);
  const { userEmail, userName } = useUserManager();
  const [emailSettingModal, setEmailSettingModal] = useState(false);
  return (
    <div
      className={` ${
        openProfileDrop ? "openProfileModal" : "closeProfileModal"
      } overflow-hidden flex rounded-[10px] p-[12px] gap-[16px] flex-col absolute top-[120%] right-0 bg-white min-w-[340px] shadow`}
    >
      {/* <AddUpdateEmailSetting
        emailSettingModal={emailSettingModal}
        setEmailSettingModal={setEmailSettingModal}
        cancel={() => setEmailSettingModal(false)}
      /> */}
      <Link to={ROUTES.juriiSettings.accounts} className="flex items-center justify-between cursor-pointer">
        <div className="flex gap-[2px] flex-col cursor-pointer">
          <DZText
            className="text-[14px] fw-500 text-[#112D4E] cursor-pointer"
            children={userName}
          />
          <DZText
            className="text-[12px] text-[#112D4E] cursor-pointer"
            children={userEmail}
          />
        </div>
        <img src="https://picsum.photos/40" className="rounded-full cursor-pointer" alt="" />
      </Link>
{/* 
      <NavbarEmailSwitcher />

      <div
        onClick={() => {
          setOpenProductDrop(!openProductDrop);
        }}
        className={` ${
          openProductDrop ? "animation-closeOpen" : "animation-openClose"
        }  flex flex-col bg-F9F7F7 p-[12px] gap-[12px] rounded-[10px] overflow-hidden `}
      >
        <div className="flex items-center justify-between cursor-pointer">
          <DZText
            className="text-[14px] text-[#112D4E] cursor-pointer"
            children={"Products"}
          />

          <ArrowDown className={"cursor-pointer"} />
        </div>

        <Link
          to={ROUTES.inbox}
          className="flex items-center gap-[12px] w-full cursor-pointer"
        >
          <img
            src="https://picsum.photos/40"
            className="rounded-full cursor-pointer"
            alt=""
          />
          <DZText
            className="text-[14px] fw-500 text-[#112D4E] cursor-pointer"
            children={"Jurii Mail"}
          />
        </Link>

        <Link
          to={ROUTES.drive}
          className="flex items-center gap-[12px] w-full cursor-pointer"
        >
          <img
            src="https://picsum.photos/40"
            className="rounded-full cursor-pointer"
            alt=""
          />
          <DZText
            className="text-[14px] fw-500 text-[#112D4E] cursor-pointer"
            children={"Jurii Drive"}
          />
        </Link>

        <Link
          to={ROUTES.passwordManager}
          className="flex items-center gap-[12px] w-full cursor-pointer"
        >
          <img
            src="https://picsum.photos/40"
            className="rounded-full cursor-pointer"
            alt="40"
          />
          <DZText
            className="text-[14px] fw-500 text-[#112D4E] cursor-pointer"
            children={"Jurii Password Manager"}
          />
        </Link>
      </div>

      <span
        onClick={() => {
          setEmailSettingModal(true);
        }}
        className="flex bg-F9F7F7 p-[12px] gap-[12px] rounded-[10px] overflow-hidden cursor-pointer"
      >
        <DZText
          className="text-[14px] text-[#112D4E] cursor-pointer"
          children={"Email Settings"}
        />
      </span> */}

      <div className="flex items-center gap-[6px] cursor-pointer group">
        <Logout />
      </div>
    </div>
  );
};

export default ProfileDropdown;
