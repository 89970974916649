import React, { useState } from "react";

const InputField = ({
  id,
  name,
  type,
  placeholder,
  value,
  onChange,
  onKeyDown,
  className = null,
  disabled = false,
  maxLength,
  icon,
  autoComplete
}) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className={`flex items-center gap-[10px] px-[20px] py-[14px]  rounded-[10px] bg-[#ffffff] w-full ${className}`}>
      {icon}
      <input
        className={`  ${
          disabled ? "!bg-white" : ""
        } placeholder:capitalize text-[#112D4E]   cursor-text outline-none w-full placeholder:text-[#3F72AF]`}
        id={id}
        name={name}
        type={showPassword ? "text" : type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        disabled={disabled}
        autoComplete={autoComplete}
        maxLength={maxLength}
      />
      {(type === "password" || name === "password") && (
        <i
          onClick={() => setShowPassword(!showPassword)}
          class={`ri-${showPassword ? "eye-off" : "eye"}-line `}
        ></i>
      )}
    </div>
  );
};

export default InputField;
