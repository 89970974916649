import { classNames } from "@react-pdf-viewer/core";
import { DraftIcon, InboxIcon, SentIcon, SettingIcon, StarIcon, LockIcon, DeleteIcon, HomeIcon, PaymentCardIcon, RecycleBinIcon, UserIcon, CreaditCardIcon, BankIcon, IDCardIcon, AccountsIcon, ProductsIcon, EmailSettingsIcon } from "../icons/generalIcons/Icons";

import { ROUTES } from "../reactRoute/RouteConstants";
const SideBarLinks = {
  juriiDriveLinksArray: [
    {
      name: "Home",
      icon: (iconColor) => {
        return <HomeIcon iconColor={iconColor} className={"lg:hidden"} />;
      },
      path: ROUTES.juriiDrive.drive,
      className: "text-112D4E relative max-lg:hidden",
    },
    {
      name: "Favourites",
      icon: (iconColor) => {
        return <StarIcon iconColor={iconColor} className={"w-[24px] h-[22px] lg:hidden"} />;
      },
      path: ROUTES.juriiDrive.favourites,
      className: "text-112D4E relative max-lg:hidden",
    },
    {
      name: "Trash",
      icon: (iconColor) => {
        return <RecycleBinIcon iconColor={iconColor} className={"w-[24px] h-[22px] lg:hidden"} />;
      },
      path: ROUTES.juriiDrive.trash,
      className: "text-112D4E relative max-lg:hidden",
    },
  ],
  juriiPasswordManagerLinksArray: [
    {
      name: "Logins",
      icon: (iconColor) => {
        return <LockIcon iconColor={iconColor} className={"w-[24px] h-[22px] lg:hidden"} />;
      },
      path: ROUTES.juriiPasswordManager.logins,
      className: "text-112D4E relative max-lg:hidden",
    },
    {
      name: "Payments",
      icon: (iconColor) => {
        return <PaymentCardIcon iconColor={iconColor} className={"w-[24px] h-[22px] lg:hidden"} />;
      },
      path: ROUTES.juriiPasswordManager.payments,
      className: "text-112D4E relative max-lg:hidden",
    },
    {
      name: "ID's",
      icon: (iconColor) => {
        return <IDCardIcon iconColor={iconColor} className={"w-[24px] h-[22px] lg:hidden"} />;
      },
      path: ROUTES.juriiPasswordManager.ids,
      className: "text-112D4E relative max-lg:hidden",
    },
    // {
    //   name: "Personal Info",
    //   icon: (iconColor) => {
    //     return <UserIcon iconColor={iconColor} className={"w-[24px] h-[22px] lg:hidden"} />;
    //   },
    //   path: ROUTES.juriiPasswordManager.personalInfo,
    //   className: "text-112D4E relative max-lg:hidden",
    // },
  ],
  juriiEmailLinksArray: [
    {
      name: "Inbox",
      icon: (iconColor) => {
        return <InboxIcon iconColor={iconColor} className={"w-[24px] h-[22px] lg:hidden"} />;
      },
      path: ROUTES.inbox,
      className: "text-112D4E relative max-lg:hidden",
    },
    {
      name: "Sent",
      icon: (iconColor) => {
        return <SentIcon iconColor={iconColor} className={"w-[24px] h-[22px] lg:hidden"} />;
      },
      path: ROUTES.sent,
      className: "text-112D4E relative max-lg:hidden",
    },
    {
      name: "Draft",
      icon: (iconColor) => {
        return <DraftIcon iconColor={iconColor} className={"w-[24px] h-[22px] lg:hidden"} />;
      },
      path: ROUTES.draft,
      className: "text-112D4E relative max-lg:hidden",
    },
  ],
  juriiSettingsLinksArray: [
    {
      name: "Accounts",
      className: "text-112D4E relative max-lg:hidden",
      path: ROUTES.juriiSettings.accounts,
      icon: (iconColor) => {
        return <AccountsIcon iconColor={iconColor} className={"w-[24px] h-[22px] lg:hidden"} />;
      },
    },
    // {
    //   name: "Products",
    //   className: "text-112D4E relative max-lg:hidden",
    //   path: ROUTES.juriiSettings.products,
    //   icon: (iconColor) => {
    //     return <ProductsIcon iconColor={iconColor} className={"w-[24px] h-[22px] lg:hidden"} />;
    //   },
    // },
    // {
    //   name: "Email Settings",
    //   className: "text-112D4E relative max-lg:hidden",
    //   path: ROUTES.juriiSettings.emailSettings,
    //   icon: (iconColor) => {
    //     return <EmailSettingsIcon iconColor={iconColor} className={"w-[24px] h-[22px] lg:hidden"} />;
    //   },
    // }
  ]
};

export default SideBarLinks;
