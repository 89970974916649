import React from 'react'
import { Tooltip } from '@mui/material';

import { StarIcon, ClockIcon } from '../../../icons/generalIcons/Icons';

import CheckInput from '../checkInput/CheckInput';
import DZText from '../text/DZText';

const MobileMailCard = ({ isStarred, active, toggleStarred, toggleActive, toggleSelected, selected }) => {

  return (

    <div className={` ${selected ? '!bg-[#86BCFC30]' : ''} flex flex-col items-center px-[16px] py-[12px] bg-F9F7F7 gap-[24px] w-full rounded-[10px] sm:hidden `}>

      <div className="flex items-center justify-between w-full">

        <div className="flex items-center gap-[12px]">

          <Tooltip arrow title='Click to star it (you can see it in the starred page)'>

            <span>
              <StarIcon
                iconColor='#3F72AF'
                pointer
                bgColor={`${isStarred ? '#3F72AF' : ''}`}
                onClick={toggleStarred}
              />
            </span>

          </Tooltip>

          <Tooltip arrow title='Click to select it'>

            <span onClick={toggleActive}>
              <CheckInput active={active} />
            </span>

          </Tooltip>

        </div>

        <div className="flex items-center gap-[8px]" onClick={toggleSelected} >

          <ClockIcon iconColor='#112D4E' />

          <DZText
            children={'2 hours ago'}
            small
            className={'text-nowrap text-112D4E cursor-text'}
          />

        </div>

      </div>

      <div className="flex flex-col gap-[4px] w-full" onClick={toggleSelected} >

        <DZText
          children={'XYZ Person Name'}
          small
          className={'text-112D4E cursor-text w-fit'}
        />

        <DZText
          children={'Login popup should be close on escape button'}
          small
          className={'text-112D4E cursor-text w-fit'}
        />

      </div>

    </div >
  )
}

export default MobileMailCard