import React, { useState } from "react";
import { Config } from "../../constants/Index";
import { setLocalStorage } from "../../components/localStorage";
import { setSelectedLoginUser } from "../../redux/UserReducer";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import DZBtn from "../../components/shared/buttons/DZBtn";
import DZText from "../../components/shared/text/DZText";
import InputField from "../../components/shared/inputField/InputField";
import { ROUTES } from "../../reactRoute/RouteConstants.jsx";
import { useVerifyEmailCodeMutation, useLoginUserMutation } from "../../redux/storeApis";
import PP2Service from "../../services/index.js";
import useAllAccountData from "../../lib/useAllAccountData.js";
import { setSelectedUserAccounts } from "../../redux/UserAccountsReducer.js";
import { useSnackBarManager } from "../../lib/customHooks/useSnackBarManager.js";

const VerificationCode = () => {

  const [verifyEmailCode, { isLoading : isLoadingVerifyEmail }] = useVerifyEmailCodeMutation();
  const [addLoginUser, { isLoadingUSer }] = useLoginUserMutation();
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state?.email || "";
  const password = location.state?.password || "";
  const name = location.state?.name || "";
  const dispatch = useDispatch();

  const { handleSelectedEmail } = useAllAccountData();
  const { fnShowSnackBar } = useSnackBarManager();

  const [data, setData] = useState({ email: email, verification_code: "", });
  const [loader, setLoader] = useState(false);

  const [loginData, setLoginData] = useState({
    email: email,
    password: password,
    name: name,
  });

  const handleInputChange = (e) => {

    const { name, value } = e.target;
    setData((prevState) => ({ ...prevState, [name]: value }));
  };

  const fnLogin = async (e) => {
    e.preventDefault();

    if (!data.email || !data.verification_code) {
      return;
    }

    try {
      // const response = await fetch(`${Config.serverApiUrl}verify-email-code`, {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   body: JSON.stringify({
      //     email: data.email,
      //     verification_code: data.code,
      //   }),
      // });
      setLoader(true);
      const emailResponse = await verifyEmailCode(data).unwrap();

      if (emailResponse?.success) {
        console.log('emailResponse', emailResponse)

        const fetchUserToken = emailResponse?.data?.access_token;
        const userId = emailResponse?.data?.userId;

        if (fetchUserToken) {
          setLocalStorage(Config.userToken, fetchUserToken);
          setLocalStorage("meId", userId);

          dispatch(setSelectedLoginUser(fetchUserToken));

          const fetchUserAccounts = await PP2Service.fetch({ url: "user-accounts", token: fetchUserToken, });
          const userAllAccounts = await fetchUserAccounts?.data?.accounts;

          if (userAllAccounts?.length > 0) {
            
            dispatch(setSelectedUserAccounts(userAllAccounts));
            let userAccount = userAllAccounts[0];
            
            setTimeout(() => {
              handleSelectedEmail(userAccount?.id, userAccount?.email, true);
              setLoader(false);
            }, 2000);

          } else {
            navigate(ROUTES.createAccount)
            setLoader(false);
          }

        } else {
          setLoader(false);
          console.error("Login failed: Access token not found in response.");
        }

        // return navigate(ROUTES.login);

        // const response = await addLoginUser(loginData).unwrap();
        // if (response.message === "successfully") {
        //   const fetchUserToken = response?.data?.access_token;
        //   if (fetchUserToken) {
        //     setLocalStorage(Config.userToken, fetchUserToken);
        //     setLocalStorage("meId", response?.data?.userId);

        //     dispatch(setSelectedLoginUser(fetchUserToken));

        //     // return navigate(`${ROUTES.confirmCode}`, {
        //     //   state: { email: data.email, password: data.password, name: data.name },
        //     // });

        //     // fnShowSnackBar("User successfully logged in!");
        //     return navigate(ROUTES.home);
        //   }
        // }

      } else {
        // console.error("Email verification failed:", responseData.message);
        console.error("Email verification failed");
      }
    } catch (error) {
      setLoader(false);
      console.error("Email verification failed:", error);
    }
  };

  return (
    <div className="flex items-center w-full h-full forms-back justify-between px-[6vw] py-[64px]">
      <div className="flex flex-col gap-[24px] max-w-[460px]">
        <DZText big className={"text-F9F7F7"} children={"Jurii"} />
        <DZText
          small
          className={"text-F9F7F7"}
          children={"Enter your six digit code"}
        />
      </div>
      <div className="flex flex-col gap-[32px] p-[32px] w-[540px] rounded-[24px] bg-[#FFF]">
        <DZText
          children={"Verification Code"}
          normal
          className={"text-112D4E"}
        />
        <div className="flex flex-col gap-[12px] w-full">
          <InputField
            placeholder={"Code"}
            className={"w-full shadow"}
            name="verification_code"
            value={data?.code}
            type={"text"}
            onChange={handleInputChange}
          />
        </div>
        <DZBtn
          onClick={fnLogin}
          children={ (isLoadingVerifyEmail || loader) ? "Verifying..." : "Confirm Code"}
          className={`rounded-[10px] ${(!data.email || !data.verification_code) && 'bg-[red]'} `}
          textStyle={"text-[#F7F9F9]"}
          solid
        />
      </div>
    </div>
  );
};

export default VerificationCode;
