import { Tooltip } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ArrowDown from "../../../icons/arrowIcons/ArrowDown";
import useAllAccountData from "../../../lib/useAllAccountData";
import useOutsideClick from "../../../lib/useOutsideClick";

function SidebarEmailSwitcher() {
  const emailRef = useRef();
  const { accountsData, selectedEmail, handleSelectedEmail, isLoadingAllAccounts, } = useAllAccountData();

  const isSingleAccount = accountsData?.length <= 1;

  const [showEmailSwitcher, setShowEmailSwitcher] = useState(false);
  useOutsideClick(emailRef, () => setShowEmailSwitcher(false));
  return (

    <>
      {
        isSingleAccount ? <div className="w-[34px] h-[34px] bg-86BCFC rounded-full max-lg:hidden overflow-hidden cursor-pointer">
          <img src="https://picsum.photos/200/200" className="object-cover w-full h-full cursor-pointer" alt="" />
        </div>
          : <Tooltip title="Email Switcher" arrow disableInteractive placement="bottom">
            <div
              ref={emailRef}
              className=" flex items-center gap-[10px] p-[4px] pr-[8px] shadow rounded-full cursor-pointer max-lg:hidden group relative"
            >
              <div
                onClick={() => setShowEmailSwitcher(!showEmailSwitcher)}
                className=" flex items-center gap-[10px] p-[4px]"
              >
                <div className="w-[34px] h-[34px] bg-86BCFC rounded-full overflow-hidden cursor-pointer">
                  <img
                    src="https://picsum.photos/200/200"
                    className="object-cover w-full h-full cursor-pointer"
                    alt=""
                  />
                </div>
                <span className="group-hover:translate-y-[4px] transition">
                  <ArrowDown />
                </span>
              </div>
              {showEmailSwitcher && (
                <div class="absolute right-0 top-[110%] flex w-[240px] max-h-[240px] overflow-y-scroll flex-col gap-[6px] rounded-[10px] p-[6px] shadow-md bg-white">
                  {isLoadingAllAccounts
                    ? "loading..."
                    : accountsData?.map((account, index) => (
                      <div
                        key={index}
                        onClick={() => {
                          handleSelectedEmail(account?.id, account?.email);
                          setShowEmailSwitcher(false);
                        }}
                        class={`flex items-center gap-[6px] rounded-[10px] p-[6px] overflow-hidden min-h-[52px] shadow-sm  cursor-pointer ${selectedEmail == account?.id ? "bg-DBE2EF" : "bg-F9F7F7"
                          }`}
                      >
                        <img
                          src="https://picsum.photos/id/237/200/200"
                          class="h-[40px] w-[40px] rounded-full cursor-pointer"
                          alt=""
                        />
                        <span class="text-112D4E cursor-pointer">
                          {account?.email}
                        </span>
                      </div>
                    ))}
                </div>
              )}
            </div>
          </Tooltip>
      }
    </>
  );
}

export default SidebarEmailSwitcher;
