import React, { useRef, useState } from "react";
import { AddIcon } from "../../../icons/generalIcons/Icons";
import DZText from "../text/DZText";
import {
  useAddDriveFileMutation,
  useAddDriveFolderMutation,
} from "../../../redux/juriiDriveApis";
import { useSnackBarManager } from "../../../lib/customHooks/useSnackBarManager";
import CreateFolderModel from "../modals/CreateFolderModel";
import { UploadFileFolderLoader } from "../../loader/Loaders";
import useGetPathUrl from "../../../lib/useGetPathUrl";
import useOutsideClick from "../../../lib/useOutsideClick";

const CreateDropDown = ({
  showCreatingFolder,
  setShowCreatingFolder,
  className,
  style,
  dropRef,
}) => {
  const [createFolderModel, setCreateFolderModel] = useState(false);
  const { pathForFile } = useGetPathUrl();
  const [addDriveFile, { isLoading: isLoadingFile }] = useAddDriveFileMutation();
  const [addDriveFolder] = useAddDriveFolderMutation();
  const { fnShowSnackBar } = useSnackBarManager();

  const handleFileInputChange = async (event) => {
    try {
      const files = event.target.files;
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append(`files[${i}]`, files[i]);
      }
      formData.append("parent_folder", pathForFile || "");
      await addDriveFile(formData).then((res) => {
        if (res?.data?.success) {
          fnShowSnackBar(res?.data?.message || "Files uploaded successfully!");
          setShowCreatingFolder(false);
        }
      });
    } catch (error) {
      console.error("Error uploading file(s):", error);
      fnShowSnackBar("There was an error uploading the file(s).");
    }
  };

  const buildFileArray = (files) => {
    return Array.from(files).map(file => ({
      file,
      webkitRelativePath: file.webkitRelativePath
    }));
  };

  const handleFolderInputChange = async (event) => {
    try {
      const files = event.target.files;
      const fileArray = buildFileArray(files);

      const formData = new FormData();
      fileArray.forEach((fileObj, index) => {
        formData.append(`files[${index}]`, fileObj.file);
        formData.append(`paths[${index}]`, fileObj.webkitRelativePath);
      });
      formData.append("parent_folder", pathForFile || "");
      console.log(formData, "formData")
      await addDriveFolder(formData).then((res) => {
        if (res?.data?.success) {
          fnShowSnackBar(res?.data?.message || "Folder uploaded successfully!");
          setShowCreatingFolder(false);
        }
      });
    } catch (error) {
      console.error("Error uploading folder:", error);
      fnShowSnackBar("There was an error uploading the folder.");
    }
  };

  return (
    <>
      {showCreatingFolder && (
        <div
          className={`${className} p-[12px] flex flex-col gap-[20px] rounded-[10px] bg-DBE2EF transition`}
          style={style}
          ref={dropRef}
        >
          <div className="flex flex-col gap-[10px]">
            <label className="flex gap-[4px] items-center cursor-pointer relative overflow-hidden">
              <AddIcon className="cursor-pointer" />
              <DZText children="Upload File" className="cursor-pointer" />
              <input
                type="file"
                className="absolute inset-0 opacity-0 cursor-pointer -translate-x-[110px] w-[300px]"
                onChange={handleFileInputChange}
                multiple
              />
            </label>
            <label className="flex gap-[4px] items-center cursor-pointer relative overflow-hidden">
              <AddIcon className="cursor-pointer" />
              <DZText children="Upload Folder" className="cursor-pointer" />
              <input
                type="file"
                className="absolute inset-0 opacity-0 cursor-pointer -translate-x-[110px] w-[300px]"
                onChange={handleFolderInputChange}
                webkitdirectory="true"
                directory="true"
              />
            </label>
          </div>
          <hr className="border-[#112D4E]" />
          <div
            onClick={() => {
              setCreateFolderModel(true);
              console.log("on click create your folder modal");
            }}
            className="flex gap-[4px] items-center cursor-pointer"
          >
            <AddIcon className="cursor-pointer" />
            <DZText children="Create Folder" className="cursor-pointer" />
          </div>

          {createFolderModel && (
            <CreateFolderModel
              setShowCreatingFolder={setShowCreatingFolder}
              cancel={() => setCreateFolderModel(false)}
              onSuccess={() => setShowCreatingFolder(false)}
            />
          )}
        </div>
      )}

      {isLoadingFile && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-[2]">
          <UploadFileFolderLoader />
        </div>
      )}
    </>
  );
};

export default CreateDropDown;
