import React, { useState } from "react";

import DZText from "../text/DZText";
import DZBtn from "../buttons/DZBtn";

import {
  StarIcon,
  ReplyIcon,
  ForwardIcon,
  CrossIcon,
  ClockIcon,
} from "../../../icons/generalIcons/Icons";

import { Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";
import useComposeModel from "../../../lib/customHooks/useComposeModel";
import EmailConfig from "../../emailConfig/EmailConfig";

const MailDetail = ({ receiver, subject, body, onClose, date }) => {
  const [isStarred, setIsStarred] = useState(false);
  const dispatch = useDispatch();
  const {
    handleOpenComposeModel,
  } = useComposeModel();
  const toggleStarred = () => {
    setIsStarred(!isStarred);
  };
  const handleReplay = () => {
    handleOpenComposeModel([receiver])
  };
  const emailText = "Client Configuration settings for “hello@danzeetech.dk”.\r\n\r\n\r\nMail Client Manual Settings\r\n---------------------------\r\n\r\nSecure SSL/TLS Settings (Recommended)\r\n\r\nUsername:\r\n\r\nhello@danzeetech.dk\r\n\r\nPassword:\r\n\r\nUse the email account’s password.\r\n\r\nIncoming Server:\r\n\r\nmail.danzeetech.dk\r\n\r\n  * IMAP Port: 993\r\n\r\n  * POP3 Port: 995\r\n\r\nOutgoing Server:\r\n\r\nmail.danzeetech.dk\r\n\r\n  * SMTP Port: 465\r\n\r\nIMAP, POP3, and SMTP require authentication.\r\n\r\nA .mobileconfig file for use with iOS for iPhone/iPad/iPod and MacOS® Mail.app® for Mountain Lion (10.8+) is attached to this message.\r\n\r\nThis notice is the result of a request made by a computer with the IP address of “77.214.132.87” through the “cpanel” service on the server.\r\n\r\nThe remote computer’s location appears to be: Denmark (DK).\r\n\r\nThe remote computer’s IP address is assigned to the provider: “Cybercity A/S VoIP users Telenor Denmark”\r\n\r\nThe provider supplied the following remarks about the IP address allocation: “+-------------------------------- | NB! In case of abuse, please | | contact abuse@telenor.dk | +--------------------------------”\r\n\r\nThe remote computer’s network link type appears to be: “Ethernet or modem”.\r\n\r\nThe remote computer’s operating system appears to be: “Mac OS X”.\r\n\r\nThe system generated this notice on Thursday, May 23, 2024 at 11:01:38 AM UTC.\r\n\r\nDo not reply to this automated message.\r\n\r\ncP\r\n\r\nCopyright© 2024 cPanel, L.L.C.";
  return (
    <div className="flex flex-col align-start gap-[32px] p-[24px] rounded-[10px] bg-F9F7F7 h-full w-full overflow-y-scroll max-lg:fixed max-lg:left-0 max-lg:bottom-0 max-lg:z-10 max-lg:max-h-[88svh] max-lg:shadow-[0_35px_60px_1005px_#0A264760] max-lg:rounded-bl-[0] max-lg:rounded-br-[0]">
      <div className="flex items-center h-fit w-full  justify-between gap-3 max-sm:flex-col max-sm:items-start">
        <div className="flex items-center gap-[20px] max-sm:w-full">
          <img
            src="https://picsum.photos/300"
            className="rounded-full max-w-[56px] max-h-[56px]"
            alt=""
          />
          <DZText
            className="text-112D4E text-[16px] truncate max-w-[170px] min-w-[140px]"
            children={receiver}
          />

          <span onClick={onClose} className="w-full flex items-end justify-end sm:hidden cursor-pointer">
            <CrossIcon />
          </span>
        </div>
        <div className="flex items-center gap-[20px]">
          <DZBtn
            onClick={() => handleReplay()}
            toolTipText={"Click to Reply"}
            simpleIcon
            className={"cursor-pointer shadow"}
            children={"Reply"}
            btnIcon={
              <ReplyIcon
                className={"cursor-pointer w-[20px] h-[20px]"}
                iconColor="#112D4E"
              />
            }
            textStyle={"cursor-pointer text-112D4E"}
          />
        </div>
        <span
          onClick={onClose}
          className="w-full flex items-end cursor-pointer justify-end max-sm:hidden lg:hidden"
        >
          <CrossIcon />
        </span>
      </div>
      <div className="flex flex-col gap-[24px]">
        <div className="flex flex-col gap-[4px]">
          <DZText className={"text-112D4E"} children={"Sender"} />
          <div
            children={receiver || " "}
            className={
              " bg-[#FFF] cursor-arrow  disabled:bg-white placeholder:capitalize text-3F72AF px-[20px] py-[14px] cursor-text outline-none rounded-[10px] placeholder:text-[#3F72AF]"
            }
          />
        </div>
        <div className="flex flex-col gap-[4px]">
          <DZText
            type={"email"}
            className={"text-112D4E"}
            children={"Subject"}
          />
          <div
            children={subject}
            className={
              "bg-[#FFF] cursor-arrow  disabled:bg-white placeholder:capitalize text-3F72AF px-[20px] py-[14px] cursor-text outline-none rounded-[10px] placeholder:text-[#3F72AF]"
            }
          />
        </div>
        <div className="flex flex-col gap-[4px]">
          <DZText
            className={"text-112D4E disabled:bg-white"}
            children={"Message"}
          />
          <div
            className="bg-[#FFF] lg:mb-[80px] break-words placeholder:capitalize text-3F72AF cursor-text cursor-arrow sentMail__detailTextArea text-3F72AF px-[20px] min-h-[400px] py-[14px] outline-none rounded-[10px] placeholder:text-[#3F72AF]"
            children={body}
          />
          {/* <EmailConfig emailText={body} /> */}
        </div>
      </div>
    </div>
  );
};
export default MailDetail;
