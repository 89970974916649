import React from 'react'

const MailIcon = ({className}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14" fill="none" className={className}>
            <path d="M1.5 2.49997L6.68477 5.4377C8.5962 6.52073 9.4038 6.52073 11.3152 5.4377L16.5 2.49997" stroke="#112D4E" stroke-width="1.2" stroke-linejoin="round" />
            <path d="M1.51182 8.10669C1.56086 10.4058 1.58537 11.5554 2.43372 12.407C3.28206 13.2586 4.46275 13.2882 6.82412 13.3476C8.27948 13.3841 9.72052 13.3841 11.1759 13.3476C13.5373 13.2882 14.7179 13.2586 15.5663 12.407C16.4146 11.5554 16.4391 10.4058 16.4882 8.10669C16.5039 7.36742 16.5039 6.63253 16.4882 5.89327C16.4391 3.59411 16.4146 2.44454 15.5663 1.59297C14.7179 0.741393 13.5373 0.711727 11.1759 0.652396C9.72052 0.615829 8.27948 0.615827 6.82412 0.652391C4.46275 0.711717 3.28206 0.74138 2.43371 1.59295C1.58537 2.44453 1.56085 3.5941 1.51182 5.89325C1.49606 6.63252 1.49606 7.36742 1.51182 8.10669Z" stroke="#112D4E" stroke-width="1.2" stroke-linejoin="round" />
        </svg>
    )
}

export default MailIcon;