import { createSlice } from "@reduxjs/toolkit";
const initialState = [];
export const breadCrumbSlice = createSlice({
  name: "BreadCrumbReducer",
  initialState,
  reducers: {
    addBreadCrumb: (state, action) => {
      state.push(action.payload);
    },
    removeBreadCrumb: (state, action) => {
      const index = action.payload;
      if (index >= 0 && index < state.length) {
        state.splice(index + 1);
      }
    },
    resetBreadcrumbs: (state) => {
      return initialState;
    },
  },
});
export const { addBreadCrumb, removeBreadCrumb, resetBreadcrumbs } =
  breadCrumbSlice.actions;

export const selectBreadCrumb = (state) => state.BreadCrumbReducer.breadCrumb;

export default breadCrumbSlice.reducer;
